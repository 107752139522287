import { observer } from 'mobx-react-lite'

import { Page } from '../layout/pages'
import { Vertiport, VertiportData, VertiportSchema } from '../core/vertiport'
import { GridCol, GridView } from '../grid/gridTypes'
import { State } from '../state/state'
import { VertiportsPageParams, VertiportsPageUI } from './vertiportPage'
import { GridUI } from '../grid/gridUI'

export type VertiportsListParams = {
    className?: string
} & GridView<VertiportData>

export const VertiportsListPage = (): Page<VertiportsListParams> => ({
    id: 'vertiportsList' as const,
    icon: 'Vertiport' as const,
    path: '/vertiports',
    label: 'Vertiports',
    Component: VertiportsListPageUI,
})

const syncPane = (view: GridView<Vertiport>): VertiportsPageParams | null =>
    view.focusId ? { embedded: true, vertiportId: view.focusId } : null

export const VertiportsListPageUI = observer((p: VertiportsListParams) => {
    return (
        <GridUI<Vertiport, VertiportsPageParams>
            schema={VertiportSchema}
            add2={(st) => st.goToeditMapPage({})}
            rows={(st: State) => st.simu.Vertiport.rows}
            cols={vertiportsListCols}
            view={p}
            syncURL={(view, st) => st.goTovertiportsListPage({ ...p, ...view })}
            syncPane={syncPane}
            PaneUI={VertiportsPageUI}
            title="Vertiports"
        />
    )
})

const vertiportsListCols = (): GridCol<Vertiport>[] => [
    { key: 'name', name: 'Name', width: 200 },
    { key: 'city', name: 'City', width: 200 },
    { key: 'pluscodeShort', name: 'Plus Code', width: 200 },
    { key: 'pluscode', name: 'Plus Code (full)', width: 200 },
    // { key: 'lat', name: 'Latitude' },
    // { key: 'lon', name: 'Longitude' },
]
