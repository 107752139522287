import Logout from '@material-ui/icons/Logout'
import Home from '@material-ui/icons/Home'
import Add from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Mail from '@material-ui/icons/Mail'
import Carte from '@material-ui/icons/TravelExplore'
import Mission from '@material-ui/icons/Task'
import StoreGoods from '@material-ui/icons/StorageRounded'
import Vertiport from '@material-ui/icons/CenterFocusWeak'
// import Vertiport from '@material-ui/icons/CenterFocusWeakRounded'
import Logs from '@material-ui/icons/FormatListNumbered'
import Save from '@material-ui/icons/Save'
import Search from '@material-ui/icons/Search'
import Close from '@material-ui/icons/Close'
import Person from '@material-ui/icons/Person'
import Battery from '@material-ui/icons/BatteryStd'
import VerticalSplit from '@material-ui/icons/VerticalSplit'
import HorizontalSplit from '@material-ui/icons/HorizontalSplit'
import Euro from '@material-ui/icons/Euro'
import Fullscreen from '@material-ui/icons/Fullscreen'
import Agent from '@material-ui/icons/HeadsetMic'
import BatteryHealth from '@material-ui/icons/LocalHospital'
import Flight from '@material-ui/icons/Flight'
import Notifications from '@material-ui/icons/Notifications'
import DarkMode from '@material-ui/icons/InvertColors'
import { ReactComponent as Drone } from '../assets/drone.svg'
import Invoice from '@material-ui/icons/Description'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Actions from '@material-ui/icons/Receipt'
import Edit from '@material-ui/icons/Edit'
import Success from '@material-ui/icons/Check'
import Delete from '@material-ui/icons/Delete'
import Settings from '@material-ui/icons/Settings'
import Dashboard from '@material-ui/icons/Dashboard'
import Menu from '@material-ui/icons/Menu'
import Schedule from '@material-ui/icons/Schedule'
import BatteryAlert from '@material-ui/icons/BatteryAlertRounded'
import BatteryCharging from '@material-ui/icons/BatteryCharging30Rounded'
import BatteryFull from '@material-ui/icons/BatteryChargingFullRounded'
import BatteryUnknown from '@material-ui/icons/BatteryUnknownRounded'
import DownloadParcel from '@material-ui/icons/SystemUpdateAltRounded'
import TakeOff from '@material-ui/icons/PublishRounded'
import Landing from '@material-ui/icons/GetAppRounded'
import Parking from '@material-ui/icons/LocalParkingRounded'
import Charging from '@material-ui/icons/OfflineBoltRounded'
import Repair from '@material-ui/icons/BuildRounded'
import ContactPilot from '@material-ui/icons/ContactPhoneRounded'
import ChargingStation from '@material-ui/icons/EvStationRounded'
import EventApproved from '@material-ui/icons/EventAvailableTwoTone'
import EventDenied from '@material-ui/icons/EventBusyTwoTone'
import EventDetails from '@material-ui/icons/EventNoteTwoTone'
import EnventPending from '@material-ui/icons/CalendarTodayTwoTone'
import ClipBoardCopy from '@material-ui/icons/ContentCopy'
import SaveSimulation from '@material-ui/icons/Save'
import CleanSensors from '@material-ui/icons/Sensors'
import HappeningNow from '@material-ui/icons/PlayCircle'
import Icons from '@material-ui/icons/ColorLens'
import HumanIntervention from '@material-ui/icons/Person'
import AirCorridor from '@material-ui/icons/Timeline'
import Select from '@material-ui/icons/SelectAll'
import EditLocation from '@material-ui/icons/EditLocation'
import Sync from '@material-ui/icons/Sync'
import FileUpload from '@material-ui/icons/FileUpload'
import FileDownload from '@material-ui/icons/FileDownload'
import Map from '@material-ui/icons/Map'
import Mouse from '@material-ui/icons/Mouse'
import Pilot from '@material-ui/icons/RecordVoiceOver'

import Play from '@material-ui/icons/PlayArrow'
import Pause from '@material-ui/icons/Pause'
import Restore from '@material-ui/icons/Restore'
import Update from '@material-ui/icons/Update'

// list of icons available here https://fonts.google.com/icons
// replace spaces with _
export const iconsComp = {
    // CORE
    Agent,
    Drone,
    Vertiport,
    Mission,
    Carte,
    Logs,
    Db: Save,
    Invoice,
    Actions: Actions,
    Area: Carte,
    Operator: AccountCircle,

    // ACTION
    Logout,
    Add,
    Remove,
    Search,
    Close,
    VerticalSplit,
    HorizontalSplit,
    FullScreen: Fullscreen,
    DarkMode,
    AirCorridor: AirCorridor,

    Play,
    Pause,
    EditLocation,

    // PAGE
    Dashboard,
    Home,
    User: AccountCircle,
    Person,
    Settings,

    // MISC
    Mail,
    Euro,
    Flight,
    Notifications,
    ArrowRight: KeyboardArrowRight,
    ArrowDown: KeyboardArrowDown,
    ArrowUp: KeyboardArrowUp,
    Edit,
    Icons,
    ClipBoardCopy,
    SaveSimulation,
    HappeningNow,
    HumanIntervention,
    Select,
    Sync,
    FileUpload,
    FileDownload,
    Mouse,
    Map,
    Pilot,

    // Success: CheckCircle,
    Success: Success,
    Delete: Delete,
    Menu: Menu,
    Clock: Schedule,
    Scheduled: Schedule,
    ContactPilot: ContactPilot,
    ChargingStation: ChargingStation,

    // BOOKING
    EventApproved,
    EventDenied,
    EventDetails,
    EnventPending,

    // SERVICES
    CleanSensors,
    DownloadParcel: DownloadParcel,
    UploadParcel: DownloadParcel,
    StoreGoods,
    TakeOff,
    Landing,
    Parking,
    Repair,

    // BATTERY
    BatteryHealth: BatteryHealth,
    Charging: Charging,
    Battery: Battery,
    BatteryAlert: BatteryAlert,
    BatteryCharging: BatteryCharging,
    BatteryFull: BatteryFull,
    BatteryUnknown: BatteryUnknown,

    Restore,
    Update,
}

export type AllowedIcons = keyof typeof iconsComp

export const defaultColor: { [k in AllowedIcons]?: string } = {
    Drone: '#29618E',
    Mission: '#FA9500',
    Vertiport: '#45854d',
    Actions: '#B86E00',
    Pilot: '#71A8D6',
    Person: '#71A8D6',
    Operator: '#1C415F',
    User: '#1C415F',
    Agent: '#50A55A',
    Invoice: '#962e27',
    Euro: '#992e2e',
    Db: '#5D6E83',
    Logs: '#5D6E83',
    Carte: '#046b01',
    Area: '#046b01',
    Success: '#07a802',
    Delete: '#000000',

    BatteryAlert: '#FF2442',
    BatteryCharging: '#FFB830',
    BatteryFull: '#07a802',
    BatteryUnknown: '#FF2442',
    Charging: '#3F72AF',
    //
    Repair: '#e9262c',
    ChargingStation: '#3F72AF',

    EventApproved: 'green',
    EventDenied: 'red',
    EventDetails: 'gray',
    EnventPending: 'black',

    DownloadParcel: '#873897',
    UploadParcel: '#873897',
    StoreGoods: '#5d2169',

    Landing: '#e8a341',
    TakeOff: '#e8a341',

    Parking: 'green',
    EditLocation: '#63c36d',

    AirCorridor: '#6cb6fc',
    Scheduled: '#b9b9b9',
    HappeningNow: '#af6ae0',
}

export const icons: { [k in AllowedIcons]: JSX.Element } = {} as any
Object.entries(iconsComp).forEach(([iconName, IconComp]) => {
    const name: AllowedIcons = iconName as any
    // const extraStyle = iconName ===
    icons[name] = (
        <IconComp className={`icon-${iconName}`} style={{ color: defaultColor[name] }} />
    )
})
