import type { LatLngTuple } from '../../core/point'
import type { AddMissionPageParams } from '../../pages/addMissionPage'
import { Observer, observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { addMissionForm2CSS } from '../draftStyle'
import { CarteUI } from '../../carte/carteUI'
import { useSt } from '../../state/context'
import { DraftState } from '../draftState'
import { draftCtx, useDraft } from '../draftContext'

import { DraftValidationUI } from './draftValidationUI'
import { DraftOperatorUI } from './draftOperatorUI'
import { DraftTimelineUI } from './draftTimeline'
import { DraftStartUI } from './draftStartUI'
import { MapPanelUI } from '../../carte/panelCSS'
import { SimpleVertiportTooltip } from '../../carte/pinVertiport'
import { Alert } from '@material-ui/core'
import { arrowPattern, FancyLineUI } from '../../carte/fancyLines'
// import { TP } from './draftTimePicker'
// import { DraftHeaderUI } from './draftHeaderUI'
// import { DraftAddStepUI } from './draftAddStepUI'

export const DraftMissionPanelUI = observer(function DraftMissionPanelUI_() {
    const draft = useDraft()
    return (
        <MapPanelUI>
            <h2>New Mission:</h2>
            <DraftOperatorUI />
            <h3 className="ac-picker" tw="mt-8" style={{ alignSelf: 'baseline' }}>
                Planning
            </h3>
            <DraftStartUI />
            <DraftValidationUI />
            <div style={{ marginTop: '1rem', paddingTop: '1rem' }}>
                <DraftTimelineUI mission={draft.fakeMission} />
                {/* <DraftAddStepUI /> */}
            </div>
            {draft.steps.length === 0 ? (
                <Alert severity="info">
                    Select Vertiports you want to stop by on the map.
                </Alert>
            ) : null}
        </MapPanelUI>
    )
})
export const AddMissionForm3UI = observer(function AddMissionForm2UI_(
    pp: AddMissionPageParams,
) {
    const st = useSt()
    const p = useMemo(
        () => ({ ...st.loc, ...pp }),
        // eslint-disable-next-line
        [],
    )
    const draft = useMemo(() => new DraftState(st, p), [st, p])
    const center: LatLngTuple = useMemo(
        () => (p.lat && p.lon ? [p.lat, p.lon] : st.simu.center),
        // eslint-disable-next-line
        [],
    )
    return (
        <draftCtx.Provider value={draft}>
            <div className="grow row relative" css={addMissionForm2CSS}>
                <DraftMissionPanelUI />
                <CarteUI
                    center={center}
                    zoom={p.zoom}
                    className="grow"
                    style={{ boxShadow: '0 0 10px -4px #7d7d7d' }}
                    vertiportTooltip={SimpleVertiportTooltip}
                    vertiportClick={(v) => {
                        console.log('yolo')
                        draft.addStep(v)
                    }}
                >
                    <Observer>
                        {() => {
                            return (
                                <FancyLineUI
                                    key={JSON.stringify(draft.positions)}
                                    patterns={arrowPattern}
                                    positions={draft.positions}
                                />
                            )
                        }}
                    </Observer>
                    {/* <Observer>
                        {() => {
                            return (
                                <RL.Polyline
                                    // style={{ color: 'red', weight: 10 }}
                                    key={JSON.stringify(draft.positions)}
                                    // patterns={arrowPattern}
                                    positions={draft.positions}
                                />
                            )
                        }}
                    </Observer> */}
                    {/* <Observer>
                        {() => {
                            const t = draft.temporaryTrace
                            if (t == null) return null
                            return (
                                <RL.GeoJSON
                                    key={`a-${t.features[0].geometry.coordinates.length}`}
                                    style={{ color: 'red', weight: 10 }}
                                    interactive={false}
                                    data={t}
                                />
                            )
                        }}
                    </Observer> */}
                </CarteUI>
            </div>
        </draftCtx.Provider>
    )
})
