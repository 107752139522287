/** range(10, 4) => [10, 11, 12, 13] */
export function range(
    start: number /** first item of the result */,
    length: number /** NOT included in the result */,
): number[] {
    return new Array(length).fill(0).map((_, ix) => start + ix)
}

export const randomRange = (minIncl: number, maxIncl: number) => {
    return minIncl + Math.floor(Math.random() * (maxIncl - minIncl + 1))
}
export const randomRangeFloat = (minIncl: number, maxIncl: number) => {
    return minIncl + Math.random() * (maxIncl - minIncl + 1)
}

export const rnd = (ratio: number): boolean => Math.random() < ratio
