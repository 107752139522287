import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import Slider from '@material-ui/core/Slider'
import { css } from '@material-ui/styled-engine'

import { defaultColor, icons } from '../components/iconUI'
import { IndicatorUI } from '../components/colorIndicator'
import { range } from '../utils/range'

export const IconsPage = (): Page => ({
    id: 'icons' as const,
    icon: 'Icons' as const,
    label: 'Icons',
    path: '/icons',
    Component: IconsPageUI,
})

export const IconsPageUI = observer(() => {
    const allKeys = Object.keys(icons)
    const A = Math.round((allKeys.length * 1) / 3)
    const B = Math.round((allKeys.length * 2) / 3)
    const keysA = allKeys.slice(0, A)
    const keysB = allKeys.slice(A, B)
    const keysC = allKeys.slice(B)

    return (
        <div tw="px-4 py-8">
            <h1 tw="mb-2">Icons and Colors</h1>
            <div className="row">
                <IconsTable keys={keysA} />
                <IconsTable keys={keysB} />
                <IconsTable keys={keysC} />
            </div>
            <PercentageOccupancyUI />
            <SlotOccupancyUI />
        </div>
    )
})

export const PercentageOccupancyUI = observer(function PercentageOccupancyUI_(p: {}) {
    const [val1, setVal1] = useState(() => 0)
    return (
        <div>
            <h2>Slot Ocupancy</h2>
            <div tw="width[200px]">
                <Slider
                    min={0}
                    step={0.01}
                    max={1}
                    aria-label="Volume"
                    value={val1}
                    onChange={(_, v: any) => setVal1(v)}
                />
                <IndicatorUI val={val1} />
            </div>
            <div className="row">
                {range(0, 11).map((i) => (
                    <IndicatorUI val={i / 10} />
                ))}
            </div>
        </div>
    )
})

export const SlotOccupancyUI = observer(function SlotOccupancyUI_(p: {}) {
    const [val1, setVal1] = useState(() => 0)
    const max = 7
    return (
        <div>
            <h2>Slot Ocupancy</h2>
            <div tw="width[200px]">
                <Slider
                    min={0}
                    step={1}
                    max={max}
                    aria-label="Volume"
                    value={val1}
                    onChange={(_, v: any) => setVal1(v)}
                />
                <IndicatorUI max={max} val={val1} />
            </div>
            <div className="row">
                {range(0, 8).map((i) => (
                    <IndicatorUI val={i} max={max} />
                ))}
            </div>
        </div>
    )
})

const IconsTable = (p: { keys: string[] }) => (
    <table css={tableCss} cellSpacing={0} style={{ marginRight: '1rem' }}>
        <thead>
            <tr>
                <th>Name</th>
                <th>Icon</th>
                <th>Color</th>
            </tr>
        </thead>
        <tbody>
            {p.keys.map((iconName) => {
                // @ts-ignore
                const bgCol = defaultColor[iconName]
                return (
                    <tr key={iconName}>
                        <td>{iconName}</td>
                        {/* <td>{createElement(iconsComp[iconName])}</td> */}
                        {/* @ts-ignore */}
                        <td>{icons[iconName]}</td>
                        {/* @ts-ignore */}
                        <td>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '1rem',
                                    height: '1rem',
                                    backgroundColor: bgCol,
                                }}
                            ></div>
                            {bgCol}
                        </td>
                    </tr>
                )
            })}
        </tbody>
    </table>
)
const tableCss = css`
    border: 1px solid #bebebe;
    & th {
        padding: 0.3rem;
        text-align: left;
        background: #e4e4e4;
    }
    & tr:nth-child(even) {
        background: #e6e6e6;
    }
    & td {
        padding: 0 0.3rem;
    }

    /* borders */
    /* --border-color: #c3c3c3; */
    border-top: 1px solid var(#c3c3c3);
    border-left: 1px solid var(#c3c3c3);
    & .tr {
        border-bottom: 1px solid var(#c3c3c3);
    }
    & .td {
        border-right: 1px solid var(#c3c3c3);
    }
`
