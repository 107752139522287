import type { Operator } from '../core/operator'
import type { Page } from '../layout/pages'
import type { Pilot } from '../core/pilot'

import { Button, Stack } from '@material-ui/core'
import { observer } from 'mobx-react-lite'

import { useSt } from '../state/context'
import { RID } from '../core/schema'
import { InvoiceUI2 } from '../components/invoice2'
import { icons } from '../components/iconUI'
import { AddMissionBtnUI } from '../components/addMissionBtn'
import { Fragment } from 'react'
import { PropertiesUI } from '../components/propertiesUI'

export type OperatorPageParams = {
    operatorId: RID | string
}

export const OperatorPage = (): Page<OperatorPageParams> => ({
    id: 'operator' as const,
    icon: 'User' as const,
    nav: false,
    path: '/operators/:operatorId',
    label: 'Operators',
    Component: OperatorPageFullUI,
})

export const OperatorPageFullUI = observer(function OperatorPageFullUI_(
    p: OperatorPageParams,
) {
    return (
        <div style={{ padding: '1rem', overflow: 'auto' }} className="grow">
            <OperatorPageUI {...p} />
        </div>
    )
})

export const OperatorPageUI = observer((p: OperatorPageParams) => {
    const st = useSt()
    const operatorId: number =
        typeof p.operatorId === 'string' //
            ? parseInt(p.operatorId)
            : p.operatorId
    const operator = st.simu.getOperator(operatorId)
    return (
        <div className="sidePanel">
            {/* INFORMATIONS */}
            <OperatorSummaryUI title operator={operator} />
            <Button variant="contained" onClick={operator.downloadReport}>
                Download Report
            </Button>

            {/* Drones */}
            <section>
                <h3>Drones</h3>
                <Stack>
                    {operator?.drones.map((drone) => (
                        <div
                            key={drone.id}
                            className="stack-line"
                            onClick={() =>
                                st.goTodronesListPage({
                                    focusId: drone.id,
                                })
                            }
                        >
                            <div className="row grow line2">
                                {icons.Drone}
                                <p tw="ml-2">{drone.name}</p>
                                <p className="id">#{drone.id}</p>
                            </div>
                        </div>
                    ))}
                </Stack>
            </section>

            {/* ============ Missions ============ */}
            <section>
                <h3>
                    Missions <AddMissionBtnUI operatorId={operator.id} />
                </h3>
                <Stack>
                    {operator.drones
                        .flatMap((d) => d.missions)
                        .map((mission) => (
                            <div
                                key={mission.id}
                                className="stack-line"
                                onClick={() =>
                                    st.goTomissionListPage({
                                        focusId: mission.id,
                                    })
                                }
                            >
                                <div className="row grow line2">
                                    {icons.Mission}
                                    <p tw="ml-2">{mission.name}</p>
                                    <p className="id">#{mission.id}</p>
                                </div>
                            </div>
                        ))}
                </Stack>
            </section>

            {/* ============ Pilots ============ */}
            <section>
                <h3>Pilots</h3>
                <Stack>
                    {operator?.pilots.map((pilot: Pilot) => (
                        <div
                            key={pilot.id}
                            className="stack-line"
                            onClick={() => st.goTopilotsListPage({ focusId: pilot.id })}
                        >
                            <div className="row grow line2">
                                {icons.Person}
                                <p tw="ml-2">{pilot.name}</p>
                                <p className="id">#{pilot.id}</p>
                            </div>
                        </div>
                    ))}
                </Stack>
            </section>

            {/* ============ Invoices ============  */}
            <section>
                <h3>Invoices</h3>
                <Stack>
                    {st.simu.clock.possibleMonths.map((fullMonth) => {
                        return (
                            <div
                                key={fullMonth}
                                className="stack-line"
                                onClick={() =>
                                    st.addPopup(
                                        `Invoice for ${operator.name}-${fullMonth}`,
                                        () => {
                                            return (
                                                <InvoiceUI2
                                                    month={fullMonth}
                                                    operatorId={operator.id}
                                                />
                                            )
                                        },
                                    )
                                }
                            >
                                <div className="row grow line2">
                                    {icons.Invoice}
                                    <p tw="ml-2">Invoice for {fullMonth}</p>
                                </div>
                            </div>
                        )
                    })}
                </Stack>
            </section>
        </div>
    )
})

export const OperatorSummaryUI = (p: {
    //
    operator: Operator
    title?: boolean
}) => {
    const operator = p.operator
    const st = useSt()
    return (
        <Fragment>
            {p.title ? (
                <h2 className="line2">
                    {icons.Operator} {operator.name}
                </h2>
            ) : (
                <h3
                    className="line2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => st.goTooperatorsListPage({ focusId: operator.id })}
                >
                    {icons.Operator} Operator: {operator.name}
                </h3>
            )}
            <PropertiesUI
                items={[
                    ['Phone', operator.phone],
                    ['tradeName', operator.tradeName],
                    ['SIRET', operator.siret],
                    ['Formation', operator.formation],
                    ['Address', operator.address],
                ]}
            />
        </Fragment>
    )
}
