export type Tagged<T, Tag extends string> = T & { __TAG__?: Tag }

export type Lat = Tagged<number, 'Lat'>
export type Lng = Tagged<number, 'Lon'>

export type LngLatTuple = [Lng, Lat]
export type LatLngTuple = [Lat, Lng]
// was: export type { LatLngTuple } from 'leaflet'

// export const london: LatLngTuple = [51.505, -0.09]

export type AreaInfos = {
    lat: number
    lon: number
    nbVertiport: number
    radius: number
}
export const Paris: AreaInfos = {
    lat: 48.8566,
    lon: 2.3522,
    nbVertiport: 50,
    radius: 0.1,
} // TODO: fix

export const Rungis: AreaInfos = {
    lat: 48.732491262255415,
    lon: 2.363562024489624,
    nbVertiport: 4,
    radius: 0.03,
}

export const Pontoise: AreaInfos = {
    lat: 49.10200476042985,
    lon: 2.0295858832171665,
    nbVertiport: 4,
    radius: 0.03,
}

export const areaInfos: { [key: string]: AreaInfos } = {
    Paris,
    Rungis,
    Pontoise,
}
