// correct way, but too slow for typescript
// | type Digit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0'
// | type Year = `${Digit}${Digit}${Digit}${Digit}`
// | type Month = `${Digit}${Digit}`
// | export type FullMonth = `${Year}-${Month}`

// export type FullMonth = `${number}-${number}`
export type FullMonth = string

/** return stuff like
 * "2021-03"
 */
export const renderFullMonth = (timestamp: number): FullMonth => {
    const date = new Date(timestamp)
    const monthNum = date.getMonth() + 1
    const monthStr = monthNum < 10 ? '0' + monthNum : monthNum
    const year = date.getFullYear()
    return `${year}-${monthStr}`
}

/**
 * parse fullMonth string into year and month number
 * ("2020-10") => {year:2020, month:10}
 */
export const parseFullMonth = (fullMonth: string): { year: number; month: number } => {
    const [year, month] = fullMonth.split('-')
    return {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
    }
}

export const getYearAndMonth = (timestamp: number): { year: number; month: number } => {
    const date = new Date(timestamp)
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return { year, month }
}

/** list all month where we may have some activity
 * ['2021-03', '2021-04', '2021-05', '2021-06']
 */
export const timestampRangeToFullMonths = (
    //
    fromTz: number,
    toTz: number,
): FullMonth[] => {
    const from = getYearAndMonth(fromTz)
    const to = getYearAndMonth(toTz)
    const fromIx = from.year * 12 + from.month - 1
    const toIx = to.year * 12 + to.month - 1
    let out: FullMonth[] = []
    for (let k = fromIx; k < toIx; k++) {
        let y = Math.floor(k / 12)
        let m = k % 12
        const mStr = m < 10 ? '0' + m : m
        out.push(`${y}-${mStr}`)
    }
    return out
}
