import { Box, Button, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'
import LogoSource from '../assets/logo.png'

export const LoginPageUI = observer(function LoginPageUI_() {
    const st = useSt()
    const auth = st.auth
    return (
        <div tw="w-full">
            <div tw="display[flex] w-full justify-center items-center h-full flex-direction[column]">
                <div tw="max-width[8rem] mt-6">
                    <img src={LogoSource} alt="logo" tw="w-full h-auto" />
                </div>
                <Box
                    sx={{
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    tw="lg:width[33%] width[100%]"
                >
                    <Typography
                        variant="h4"
                        tw="mt-6 text-center font-extrabold font-size[2rem]"
                    >
                        Sign in
                    </Typography>
                    <Typography tw="mt-2 text-center color[#6e84a3]">
                        Log in to your account
                    </Typography>
                    <div tw="py-8 px-4 sm:px-10">
                        <form
                            onSubmit={(ev) => {
                                ev.preventDefault()
                                ev.stopPropagation()
                                auth.login()
                            }}
                            className="col"
                            tw="w-full mt-8"
                        >
                            <TextField
                                tw="mb-2 color[white]"
                                label="Username"
                                variant="standard"
                                value={auth.username}
                                onChange={(ev) => (auth.username = ev.target.value)}
                            />

                            <TextField
                                label="Password"
                                type="password"
                                variant="standard"
                                value={auth.password}
                                onChange={(ev) => (auth.password = ev.target.value)}
                            />

                            {auth.loginError && (
                                <div className="RED">{auth.loginError}</div>
                            )}
                            <Button onClick={auth.login} variant="contained" tw="mt-8">
                                LOGIN!
                            </Button>
                            {/* <Button
                                style={{ fontSize: '.7em' }}
                                onClick={() => {
                                    auth.username = 'admin'
                                    auth.password = 'admin'
                                    auth.login()
                                }}
                            >
                                (bypass auth 🐱‍👤)
                            </Button> */}
                        </form>
                    </div>
                </Box>
            </div>
        </div>
    )
})
