import { observer } from 'mobx-react-lite'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { TP } from './draftTimePicker'
import tw, { css } from 'twin.macro'

const startCSS = css`
    & .MuiInput-root {
        ${tw`mx-2`}
    }
`
export const DraftStartUI = observer(function DraftStartTimeUI_(p: {}) {
    return (
        <div css={startCSS} className="line2">
            Drone starts
            <Select
                labelId="demo-simple-select-label"
                variant="standard"
                id="demo-simple-select"
                value={'parked'}
                label="Age"
                onChange={() => {}}
            >
                <MenuItem value={'parked'}>Parked</MenuItem>
                {/* <MenuItem value={'fly-to'}>Flying to</MenuItem> */}
                {/* <MenuItem value={'take-off'}>Take off</MenuItem> */}
            </Select>
            at <TP />
        </div>
    )
})
