import type { Simulation, Table } from '../state/simulation'
import * as yup from 'yup'
import { observable } from 'mobx'

export type RID = number
// let nextRID = 0
// export const getRID = () => nextRID++

export const s = {
    object: yup.object,
    // required
    bool: yup.boolean().required(),
    str: yup.string().required(),
    num: yup.number().required(),
    arr: <T extends yup.AnySchema>(schema: T) => yup.array(schema).required(),
    any: yup.mixed(),
    litteral: <T extends string>(t: T) =>
        yup.string().oneOf([t]).required() as yup.StringSchema<T>,
    // opt
    str_: yup.string(),
    num_: yup.number(),
    arr_: <T extends yup.AnySchema>(schema: T) => yup.array(schema),

    nums: yup.array(yup.number().required()).required(),

    // IDS
    _ID: yup.number().required(),
    _IDS: yup.array(yup.number().required()).required(),
}

export class Model<T extends yup.AnyObjectSchema> {
    id!: RID
    observability: any = {}
    constructor(
        //
        public simulation: Simulation,
        public schema: T,
        data: yup.Asserts<T>,
        public $Name: string,
    ) {
        const isValid = schema.validateSync(data)
        if (!isValid) {
            console.log('fields expected:', schema.fields)
            console.log('got', data)
            throw new Error('invalid object')
        }

        Object.keys(data).forEach((d) => (this.observability[d] = observable))
        Object.assign(this, data)

        // index self in table
        const table: Table<this> = (simulation as any)[$Name]
        table.byId.set(this.id, this)
        table.rows.push(this)
    }
    toJSON = (): yup.Asserts<T> => {
        const self: { [key: string]: any } = this
        const json: { [key: string]: any } = {}
        const dataKeys = Object.keys(this.schema.fields)
        dataKeys.forEach((k: any) => (json[k] = self[k]))
        return json
    }

    UNSAFE_Delete = () => {
        const table: Table<this> = (this.simulation as any)[this.$Name]
        table.byId.delete(this.id)
        const ix = table.rows.indexOf(this)
        if (ix == null) throw new Error('IMPOSSIBLE')
        table.rows.splice(ix, 1)
    }

    $updateFields = (data: yup.Asserts<T>) => {
        Object.assign(this, data)
    }
}

export const groupableFields = (schema: yup.AnyObjectSchema): string[] =>
    Object.keys(schema.fields)
