import type { ActionKind } from '../core/action'
import { defaultColor, icons, iconsComp } from '../components/iconUI'

export const addableServices: {
    activity: ActionKind['type']
    icon: React.ReactNode
    label: string
    width?: string
    iconComp: React.FC<any>
    readonly?: boolean
    color?: string
}[] = [
    {
        activity: 'land',
        icon: icons.Landing,
        color: defaultColor.Landing,
        iconComp: iconsComp.Landing,
        label: 'Land',
    },
    {
        activity: 'takeoff',
        icon: icons.TakeOff,
        color: defaultColor.TakeOff,
        iconComp: iconsComp.TakeOff,
        label: 'Takeoff',
    },
    //
    {
        activity: 'load',
        icon: icons.UploadParcel,
        color: defaultColor.UploadParcel,
        iconComp: iconsComp.UploadParcel,
        label: 'Load',
    },
    {
        activity: 'unload',
        icon: icons.DownloadParcel,
        color: defaultColor.DownloadParcel,
        iconComp: iconsComp.DownloadParcel,
        label: 'Unload',
    },
    {
        activity: 'storeGoods',
        icon: icons.StoreGoods,
        color: defaultColor.StoreGoods,
        iconComp: iconsComp.StoreGoods,
        label: 'Store',
    },
    //
    {
        activity: 'rechargeBattery',
        icon: icons.Charging,
        color: defaultColor.Charging,
        iconComp: iconsComp.Charging,
        label: 'Recharge',
    },
    {
        activity: 'cleanSensor',
        icon: icons.CleanSensors,
        color: defaultColor.CleanSensors,
        iconComp: iconsComp.CleanSensors,
        label: 'Clean',
    },
    {
        activity: 'repair',
        icon: icons.Repair,
        color: defaultColor.Repair,
        iconComp: iconsComp.Repair,
        label: 'Repair',
    },
    //
    {
        activity: 'park',
        icon: icons.Parking,
        color: defaultColor.Parking,
        iconComp: iconsComp.Parking,
        label: 'Park',
    },
    {
        activity: 'queue',
        icon: icons.Clock,
        color: defaultColor.Clock,
        iconComp: iconsComp.Clock,
        label: 'Queue',
        readonly: true,
    },
]
