import type { LatLngTuple } from '../core/point'
import type { Page } from '../layout/pages'
import type { RID } from '../core/schema'
import type { Vertiport } from '../core/vertiport'
import type { State } from '../state/state'

import { observer } from 'mobx-react-lite'
import { makeAutoObservable, runInAction } from 'mobx'
import { useCallback, useMemo } from 'react'

import * as RL from 'react-leaflet'
import * as L from 'leaflet'

import { PanelEditGraphUI } from '../carte/panelEditGraph'
import { CarteUI, CarteZone } from '../carte/carteUI'
import { useSt } from '../state/context'
import { SimpleVertiportTooltip } from '../carte/pinVertiport'
import { Corridor } from '../core/corridor'

export type EditMapPageParams = {
    lat?: number
    lon?: number
    zone?: string // key
    zoom?: number
    vertiportId?: RID
}

export const EditMapPage = (): Page<EditMapPageParams> => ({
    id: 'editMap' as const,
    icon: 'Edit' as const,
    label: 'Edit Map',
    path: '/editMap',
    Component: EditMapPageUI,
})

export class EditMapState {
    constructor(
        public st: State,
        p: {
            vertiportId?: RID
            corridorId?: RID
        } = {},
    ) {
        // hydrage state from URL
        if (p.vertiportId) {
            this.vertiport = st.simu.Vertiport.get(p.vertiportId)
        } else if (p.corridorId) {
            this.corridor = st.simu.Corridor.get(p.corridorId)
        }
        // make observalbe
        makeAutoObservable(this)
    }

    vertiport: Vertiport | null = null
    selectVertiport = (vertiport: Vertiport) => {
        this.vertiport = vertiport
        this.corridor = null
    }

    corridor: Corridor | null = null
    selectCorridor = (corridor: Corridor) => {
        this.corridor = corridor
        this.vertiport = null
    }
}

export const EditMapPageUI = observer((pp: EditMapPageParams) => {
    const st = useSt()
    const p = useMemo(
        () => ({ ...st.loc, ...pp }),
        // eslint-disable-next-line
        [],
    )
    const ems = useMemo(
        () => new EditMapState(st, { vertiportId: p.vertiportId }),
        // eslint-disable-next-line
        [],
    )
    const center: LatLngTuple = useMemo(
        () => (p.lat && p.lon ? [p.lat, p.lon] : st.simu.center),
        // eslint-disable-next-line
        [],
    )
    const onZoneChange = useCallback((zone: CarteZone) => {
        // console.log(zone)
        const loc: EditMapPageParams = { ...p, ...zone }
        st.loc = loc
        st.goToeditMapPage(loc)
        // eslint-disable-next-line
    }, [])
    // const mousePos = useRef(() => {})
    const mouseCoord = useMemo(
        () => ({ lat: center[0], lon: center[1] }),
        // eslint-disable-next-line
        [],
    )
    const onKeyDown = useCallback((ev: L.LeafletKeyboardEvent) => {
        const oe = ev.originalEvent
        const key = oe.key
        if (key === 'v') {
            const v = st.simu.addVertiportAt(mouseCoord.lat, mouseCoord.lon)
            ems.selectVertiport(v)
            return L.DomEvent.stop(ev)
        }
        if (ems.vertiport && (key === 'x' || key === 'V')) {
            const from = ems.vertiport
            const v = st.simu.addVertiportAt(mouseCoord.lat, mouseCoord.lon)
            ems.selectVertiport(v)
            st.simu.addCorridor(from, v)
            return L.DomEvent.stop(ev)
        }
        if (key === 'w' && ems.corridor) {
            // console.log(ev)
            // console.log(ev.target.unproject)
            // console.log(ev.target.unproject(ev.target._pixelOrigin), ev.originalEvent)
            ems.corridor.addWaypoint(mouseCoord)
            return L.DomEvent.stop(ev)
        }
        if (key === 'Backspace' || key === 'Delete') {
            const v = ems.vertiport
            if (v) {
                v.delete()
                return L.DomEvent.stop(ev)
            }
            const c = ems.corridor
            if (c) {
                c.Delete()
                return L.DomEvent.stop(ev)
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="HomePage grow row" tw="relative">
            <PanelEditGraphUI ems={ems} />
            <CarteUI
                zoom={p.zoom}
                preferGGHybrid
                onZoom={onZoneChange}
                onPan={onZoneChange}
                className="grow"
                center={center}
                onKeyDown={onKeyDown}
                onMouseMove={(ev) => {
                    // console.log(ev)
                    mouseCoord.lat = ev.latlng.lat
                    mouseCoord.lon = ev.latlng.lng
                }}
                onClick={(pos, ev) => {
                    runInAction(() => {
                        const ctrl = ev.originalEvent.ctrlKey || ev.originalEvent.metaKey
                        // ADD (need ctrl)
                        if (ctrl) {
                            const v = st.simu.addVertiportAt(pos.lat, pos.lng)

                            // CONNECT (need shift & from)
                            const from = ems.vertiport
                            const shift = ev.originalEvent.shiftKey
                            if (from && ctrl && shift && v !== from) {
                                st.simu.addCorridor(from, v)
                                // from.connectionIds.push(v.id)
                                // v.connectionIds.push(from.id)
                            }

                            ems.selectVertiport(v)
                        }
                    })
                }}
                corridorColor={(c): string | undefined => {
                    if (ems.corridor === c) return 'red'
                }}
                corridorClick={(c, ev) => {
                    const ctrl = ev.originalEvent.ctrlKey || ev.originalEvent.metaKey
                    const alt = ev.originalEvent.altKey

                    if (alt) c.Delete()
                    else ems.selectCorridor(c)
                    if (ctrl) console.log(ev)
                }}
                vertiportDrag={(v, latLng) => {
                    v.updatePos(latLng[1], latLng[0])
                    ems.selectVertiport(v)
                }}
                vertiportClick={(v, ev) => {
                    const from = ems.vertiport
                    const alt = ev.originalEvent.altKey
                    const mod =
                        ev.originalEvent.shiftKey ||
                        ev.originalEvent.ctrlKey ||
                        ev.originalEvent.metaKey

                    if (from && mod && v !== from) v.connectTo(from)
                    if (alt && !mod) v.delete()
                    else {
                        ems.selectVertiport(v)
                        st.selectedVertiport = v
                    }
                }}
                vertiportTooltip={SimpleVertiportTooltip}
                vertiportIcon={(v: Vertiport) =>
                    //
                    v.deleted ? 'deleted' : ems.vertiport === v ? 'yellow' : 'gray'
                }
            >
                {ems.vertiport && (
                    <RL.Pane
                        name="selected-vertiport-highlight"
                        key={ems.vertiport.id}
                        style={{ zIndex: 200 }}
                    >
                        <RL.CircleMarker
                            center={ems.vertiport.pos}
                            pathOptions={{ color: 'blue' }}
                            radius={100}
                            stroke={false}
                        />
                    </RL.Pane>
                )}
            </CarteUI>
        </div>
    )
})
