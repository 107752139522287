import { observer } from 'mobx-react-lite'
import { Page } from '../layout/pages'

import { RID } from '../core/schema'
import { AddMissionForm3UI } from '../add-mission/ui/draftUIFull'

export type AddMissionPageParams = {
    droneId?: RID
    operatorId?: RID
    vertiportId?: RID
    lat?: number
    lon?: number
    zoom?: number
}

export const AddMissionPage = (): Page<AddMissionPageParams> => ({
    id: 'addMission' as const,
    icon: 'Mission' as const,
    label: 'Add Mission',
    path: '/addMission',
    Component: AddMissionPageUI,
})

export const AddMissionPageUI = observer((p: AddMissionPageParams) => {
    return <AddMissionForm3UI {...p} />
})

// TODO: move the UI-full code here
