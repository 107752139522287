import { Typography } from '@material-ui/core'
import { FunctionComponent } from 'react'
import { css } from 'twin.macro'
import type { Drone } from '../core/drone'
import { icons } from './iconUI'

type BatteryIndicatorParams = {
    drone: Drone
    minRemaining?: boolean
}

export const BatteryIndicator: FunctionComponent<BatteryIndicatorParams> = ({
    drone,
    minRemaining,
}) => (
    <div tw="flex items-center">
        <span
            css={[
                css`
                    svg {
                        width: 1.5rem;
                        height: 1.5rem;
                        color: ${drone.battery > 50 ? 'green' : 'red'};
                        transform: rotate(90deg);
                    }
                `,
            ]}
        >
            {icons.Battery}
        </span>
        <Typography variant="body1" tw="font-semibold ml-2">
            {drone.battery}%
        </Typography>
        {minRemaining && (
            <Typography variant="body2" tw="color[#888888] ml-2">
                {drone.minutesRemaining} minutes remaining
            </Typography>
        )}
    </div>
)
