import type { RID } from '../core/schema'
import type { Page } from '../layout/pages'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'

export type PilotPageParams = {
    pilotId: RID | string
    embedded?: boolean
}

export const PilotPage = (): Page<PilotPageParams> => ({
    id: 'pilot' as const,
    nav: false,
    icon: 'Person' as const,
    path: '/pilots/:pilotId',
    label: 'Pilots',
    Component: PilotPageUI,
})

export const PilotPageUI = observer((p: PilotPageParams) => {
    const st = useSt()
    const pilotId: RID =
        typeof p.pilotId === 'string' //
            ? parseInt(p.pilotId)
            : p.pilotId

    const pilot = st.simu.getPilot(pilotId)
    return (
        // https://source.unsplash.com/
        <div className="propertyGroup">
            <h2>{pilot.name}</h2>
            {pilot.photo && (
                <img
                    src={pilot.photo}
                    style={{ width: '200px', float: 'right' }}
                    alt="pilot"
                />
            )}

            {/* <div>
                <span className="propertyName">id</span>
                <span>{pilot.id}</span>
            </div> */}
            <div>
                <span className="propertyName">Phone</span>
                <span>{pilot.phone}</span>
            </div>
            <div>
                <span className="propertyName">Certification</span>
                <span>{pilot.certification}</span>
            </div>
            <div>
                <span className="propertyName">Operator</span>
                <span>{pilot.operatorName}</span>
            </div>
        </div>
    )
})

// <Container
//     tw="flex-basis[50%]"
//     css={[
//         tw`shadow-none`,
//         st.spaceOnScreen && tw`box-shadow[-1px 1px 6px rgba(0, 0, 0, 0.1)]`,
//     ]}
// >
//     <div tw="my-8">
//     </div>
// </Container>

/*
{p.embedded ? (
    <div tw="flex items-center">
        <Button
            variant="outlined"
            onClick={(e) => {
                e.preventDefault()
                st.goTopilotsListPage({})
            }}
        >
            {icons.Close}
        </Button>
        <OrientationToggle />
    </div>
) : (
    <div tw="flex items-center">
        <Breadcrumbs aria-label="breadcrumb">
            <Link
                color="inherit"
                href="/drones"
                onClick={(e) => {
                    e.preventDefault()
                    st.goTopilotsListPage({})
                }}
            >
                List pilots
            </Link>
            <Typography color="textPrimary" tw="font-bold">
                {pilot?.name}
            </Typography>
        </Breadcrumbs>
        <OrientationToggle />
    </div>
)}
*/
