import { css } from '@material-ui/styled-engine'

export const addMissionForm2CSS = css`
    * {
        box-sizing: border-box;
    }
    /* & .side-panel {
        width: 480px;
    } */
    & .RVTimeTextField input {
        text-align: center;
    }
    & .calculated-time {
        padding: 0.3rem;
        text-align: center;
        color: gray;
        width: 4rem;
        font-size: 0.9em;
    }
    & .serv-small > svg {
        height: 1em;
    }
    & .service-label,
    & .serv-small > svg,
    & .serv-small {
        width: 1.5rem;
    }
    & .serv-small:hover {
        cursor: pointer;
        background-color: #e7edff;
    }
    & .add-serv-btn {
        cursor: pointer;
        color: lightgray;
    }
    /* & .service-label.service-takeoff,
    & .service-label.service-land {
        padding-left: 1rem;
        width: 4rem;
    } */
    & .service-label {
        /* width: 2rem; */
        text-align: center;
    }
    & .service-label-text {
        transform-origin: 0 0;
        transform: rotate(-90deg);
        white-space: nowrap;
    }
    & .add-serv-btn:hover {
        color: gray;
    }
    & .ac-picker {
        width: 200px;
    }
`
