import type { Asserts } from 'yup'
import type { Simulation } from '../state/simulation'
import type { LatLngTuple } from 'leaflet'
import type { LngLatTuple } from './point'
import type { Vertiport } from './vertiport'

import { computed, makeObservable } from 'mobx'

import { Paris, Pontoise, Rungis } from './point'
import { Model, RID, s } from './schema'
import { choose } from '../fakes/utils'
import { turf } from '../state/turf'

export const AreaSchema = s.object({
    id: s._ID,
    name: s.str,
    lat: s.num,
    lon: s.num,
    zoom: s.num,
    vertiportIds: s._IDS, // 🔄 denormalized data
})

export type AreaData = Asserts<typeof AreaSchema>
export interface Area extends AreaData {}
export class Area extends Model<typeof AreaSchema> {
    constructor(simulation: Simulation, data: AreaData) {
        super(simulation, AreaSchema, data, 'Area')
        makeObservable(this, { ...this.observability, vertiports: computed })
    }
    get vertiports(){return this.simulation.Vertiport.getMany(this.vertiportIds)} //prettier-ignore
    get center(): LatLngTuple { return [this.lat, this.lon] } // prettier-ignore

    distanceTo = (pos: LngLatTuple) => {
        return turf.distance(
            //
            turf.point([this.lon, this.lat]),
            turf.point(pos),
        )
    }
    chooseVertiport = (): Vertiport => choose(this.vertiports)
}

export const mkDefaultAreaData = (
    //
    id1: RID,
    id2: RID,
    id3: RID,
): AreaData[] => [
    {
        id: id1,
        name: 'Paris',
        lat: Paris.lat,
        lon: Paris.lon,
        zoom: 13,
        vertiportIds: [],
    },
    {
        id: id2,
        name: 'Pontoise',
        lat: Pontoise.lat,
        lon: Pontoise.lon,
        zoom: 13,
        vertiportIds: [],
    },
    {
        id: id3,
        name: 'Rungis',
        lat: Rungis.lat,
        lon: Rungis.lon,
        zoom: 13,
        vertiportIds: [],
    },
]
