import { createContext, useContext } from 'react'
import type { State } from './state'

export const stContext = createContext<State | null>(null)

export const useSt = (): State => {
    const val = useContext(stContext)
    if (val == null) throw new Error('forgot to add ctx provider ?')
    return val
}
