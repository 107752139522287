import type { State } from '../state/state'
import type { Page } from '../layout/pages'
import type { GridCol, GridView } from '../grid/gridTypes'
import type { Action, ActionData } from '../core/action'

import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { renderTime } from '../utils/renderTime'
import { ActionSchema } from '../core/action'
import { GridUI } from '../grid/gridUI'
import { MissionTimelineUI } from '../components/missionTimelineUI'
import { RID } from '../core/schema'
import { useSt } from '../state/context'
import { InvoiceUI2 } from '../components/invoice2'
import Button from '@material-ui/core/Button'

export type ActionsListPageParams = {
    className?: string
} & GridView<ActionData>

export const ActionsListPage = (): Page<ActionsListPageParams> => ({
    id: 'actionsList' as const,
    icon: 'Actions' as const,
    label: 'Actions',
    path: '/actions',
    Component: (p) => {
        return (
            <div className="grow col">
                <ActionsListPageUI {...p} />
            </div>
        )
    },
})

type PP = { actionId: RID }
export const ActionsListPageUI = observer((p: ActionsListPageParams) => {
    return (
        <GridUI<Action, PP>
            rows={(st: State) => st.simu.Action.rows}
            cols={actionListCols}
            schema={ActionSchema}
            view={p}
            syncURL={(view, st) => st.goToactionsListPage({ ...p, ...view })}
            syncPane={(a) => (a.focusId ? { actionId: a.focusId } : null)}
            PaneUI={ActionPaneUI}
            title="Actions"
        />
    )
})
export const ActionPaneUI = observer(function ActionPaneUI_(p: { actionId: RID }) {
    const st = useSt()
    const action = st.simu.Action.get(p.actionId)
    return (
        <div className="info-page">
            <h2>Action</h2>
            <Button onClick={() => st.goTomissionListPage({ focusId: action.missionId })}>
                Go To Mission
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    st.addPopup(`Invoice for action #${action.id}`, () => {
                        return (
                            <InvoiceUI2
                                month={action.month}
                                operatorId={action.operatorId}
                            />
                        )
                    })
                }}
            >
                See invoice
            </Button>
            <section>
                <h4>Mission</h4>
                <MissionTimelineUI mission={action.mission} />
            </section>
        </div>
    )
})

const sum = (arr: number[]): number => arr.reduce((prev, curr) => prev + curr, 0)
const actionListCols = (st: State): GridCol<Action>[] => [
    {
        key: 'at',
        name: 'At',
        width: 150,
        formatter: (r) => <Fragment>{renderTime(r.row.at)}</Fragment>,
    },
    {
        key: 'activity',
        name: 'Services',
        width: 200,
        formatter: (m) => (
            <div className="line2">
                {m.row.icon} {m.row.activity}
            </div>
        ),
    },
    { key: 'infos', name: 'Infos', width: 100 },
    {
        key: 'vertiport',
        name: 'Vertiport',
        width: 120,
        formatter: (m) => <Fragment>{m.row.vertiport.name}</Fragment>,
    },
    {
        key: 'drone',
        name: 'Drone',
        width: 120,
        formatter: (m) => <Fragment>{m.row.mission.drone.name}</Fragment>,
    },
    { key: 'operatorName', name: 'Operator', width: 120 },
    {
        key: 'missionId',
        name: 'Mission',
        width: 120,
        formatter: (m) => <Fragment>{m.row.mission.name}</Fragment>,
    },
    {
        key: 'price',
        name: 'Price',
        width: 100,
        formatter: (p) => <Fragment>{p.row.priceFormatted}</Fragment>,
        groupFormatter: (a) => (
            <Fragment>{sum(a.childRows.map((item) => item.price))}€</Fragment>
        ),
    },
]
