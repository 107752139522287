import { createContext } from 'react'
import { useContext } from 'react'
import type { DraftState } from './draftState'

export const draftCtx = createContext<DraftState | null>(null)

export const useDraft = (): DraftState => {
    const draft = useContext(draftCtx)
    if (draft == null) throw new Error('missing draft context')
    return draft
}
