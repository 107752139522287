export const fakeFrenchNames = [
    'Yves Figuier',
    'Thaddée Rodin',
    'Olivier Clérisseau',
    'David Charrier',
    'Porthos Thibodeaux',
    'Nathanaël Moineau',
    'Gérald Aveline',
    'Daniel Calvet',
    'Jean Chaney',
    'Florian Carbonneau',
]
let ix = 0
export const getFakeFrenchName = (): string =>
    fakeFrenchNames[ix++ % fakeFrenchNames.length]
