export function urlStringify(obj: unknown): string {
    if (typeof obj !== 'object' || obj === null || obj instanceof Array) return value(obj)
    return `{${Object.entries(obj)
        .filter(
            ([_k, v]) =>
                typeof v !== 'undefined' && //
                typeof v !== 'function',
        )
        .map(([k, v]) => `"${k}":${value(v)}`)}}`
}

function value(val: unknown): string {
    if (typeof val === 'string') {
        // return '"' + val.replace(/\\/g, '\\\\').replace('"', '\\"') + '"'
        return encodeURI(JSON.stringify(val))
    } else if (typeof val === 'number' || typeof val === 'boolean') {
        return '' + val
    } else if (typeof val === 'function') {
        return 'null'
    } else if (typeof val === 'object') {
        if (val instanceof Date) return '"' + val.toISOString() + '"'
        if (val instanceof Array) return '[' + val.map(value).join(',') + ']'
        if (val === null) return 'null'
        return urlStringify(val)
    }
    throw new Error(`urlStringify: invalid type: [${typeof val}]`)
}
