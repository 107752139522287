import { observer } from 'mobx-react-lite'
import * as L from 'react-leaflet'

import { arcGISGray, mapbox } from './carteLayers'
import { VertiPortPinUI } from './pinVertiport'
import { LatLngTuple } from '../core/point'
import { useSt } from '../state/context'
import { themeMgr } from '../theme'

export const SimpleCarteUI = observer(function SimpleCarteUI_(p: {
    className?: string
    center?: LatLngTuple
    zoom?: number
}) {
    const st = useSt()
    const simu = st.simu
    const center = p.center || simu.center
    const isDark = themeMgr.isDark()
    return (
        <L.MapContainer
            className={`${p.className} CarteUI`}
            center={center}
            zoom={p.zoom || 13}
            scrollWheelZoom={true}
            zoomControl={false}
        >
            <L.ZoomControl position="bottomright" />
            {isDark ? arcGISGray : mapbox}

            {simu.Vertiport.rows.map((vp) => (
                <VertiPortPinUI vertiport={vp} key={vp.id} />
            ))}
        </L.MapContainer>
    )
})
