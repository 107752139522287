import type { State } from '../state/state'
import type { DragEvent } from 'react'

type DE = DragEvent<HTMLDivElement>

const handleDragEnter = (ev: DE) => {
    ev.preventDefault()
    ev.stopPropagation()
}
const handleDragLeave = (ev: DE) => {
    ev.preventDefault()
    ev.stopPropagation()
}
const handleDragOver = (ev: DE) => {
    ev.preventDefault()
    ev.stopPropagation()
}
const handleDrop = (ev: DE, then?: (ev: DE) => void) => {
    ev.preventDefault()
    ev.stopPropagation()
    then?.(ev)
}

export const dropParams = (st: State) => {
    const processFile = async (file: File) => {
        const x = await file.text()
        st.loadSimulation(JSON.parse(x))
    }
    const then = (ev: DE) => {
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind !== 'file') continue
                const file = ev.dataTransfer.items[i].getAsFile()
                if (file == null) continue
                console.log('... file[' + i + '].name = ' + file.name)
                return processFile(file)
            }
        } else {
            // OR.. Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                const file = ev.dataTransfer.files[i]
                console.log('... file[' + i + '].name = ' + file.name)
                return processFile(file)
            }
        }
    }
    return {
        onDrop: (ev: DE) => handleDrop(ev, then),
        onDragOver: handleDragOver,
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
    }
}
