import pinGreen from './pin-green.svg'
import pinOrange from './pin-orange.svg'
import pinRed from './pin-red.svg'
import pinBlue from './pin-blue.svg'
import pinDrone from './drone.svg'
import pinDroneActive from './drone-active.svg'
import pinVertiport from './vertiport.png'

export const assets = {
    pinGreen,
    pinOrange,
    pinRed,
    pinBlue,
    pinDrone,
    pinDroneActive,
    pinVertiport,
}
