import type { Vertiport } from '../core/vertiport'
import type { Page } from '../layout/pages'
import type { RID } from '../core/schema'

import Paper from '@material-ui/core/Paper'
import { css } from '@material-ui/styled-engine'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { VertiportStatusUI } from '../components/vertiportStatusUI'
import { SimpleCarteUI } from '../carte/simpleCarteUI'
import { icons } from '../components/iconUI'
import { QRCodeUI } from '../carte/qrcode'
import { useSt } from '../state/context'
import {
    VertiportActivityUI,
    VertiportDronesInStorageUI,
} from '../components/vertiportWidgets'
import { IndicatorUI } from '../components/colorIndicator'

export type VertiportsPageParams = {
    vertiportId: RID
    embedded?: boolean
    skipCarte?: boolean
}

export const VertiportsPage = (): Page<VertiportsPageParams> => ({
    id: 'vertiport' as const,
    icon: 'Vertiport' as const,
    nav: false,
    path: '/vertiport/:vertiportId',
    label: 'Vertiport',
    Component: VertiportsPageUI,
})

// export const VertiportPageFullUI = observer(function VertiportPageFullUI_(
//     p: VertiportsPageParams,
// ) {
//     return (
//         <div style={{ padding: '1rem' }}>
//             <VertiportsPageUI {...p} />
//         </div>
//     )
// })

export const VertiportsPageUI = observer((p: VertiportsPageParams) => {
    const st = useSt()
    const vertiport = st.simu.Vertiport.get(p.vertiportId)
    return (
        <div
            className="info-page"
            css={css`
                h3 {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            `}
        >
            <h2 className="line2" style={{ alignItems: 'start' }}>
                {/*  */}
                {icons.Vertiport} {vertiport?.name}
                <QRCodeUI
                    style={{ width: '80px', marginLeft: 'auto' }}
                    encodedString={vertiport.name}
                />
            </h2>
            <div className="row wrap">
                <Paper className="propertyPaper">
                    <MeteoUI />
                </Paper>
                <Paper className="propertyPaper">
                    <LocationUI vertiport={vertiport} />
                </Paper>
                {p.skipCarte ? null : (
                    <SimpleCarteUI
                        center={vertiport?.pos}
                        tw="width[9rem]  height[9rem]"
                    />
                )}
            </div>

            <div className="row wrap">
                <Paper className="propertyPaper">
                    <h3>Operational Status</h3>
                    <OperationalStatusSummaryUI vertiport={vertiport} />
                </Paper>
                <Paper className="propertyPaper">
                    <h3>Cargo</h3>
                    <div className="propertyGroup">
                        <div className="line">
                            <span className="propertyName">On premises # of Parcels</span>
                            <span>25</span>
                        </div>
                        <div className="line">
                            <span className="propertyName">Parcels Arriving</span>
                            <span>7</span>
                        </div>
                        <div className="line">
                            <span className="propertyName">Parcels Departing</span>
                            <span>12</span>
                        </div>
                        <div className="line">
                            <span className="propertyName">Free Docks</span>
                            <span>7</span>
                        </div>
                    </div>
                </Paper>
            </div>

            <section>
                <VertiportStatusUI vertiport={vertiport} />
            </section>

            {/* <AddMissionBtnUI label="Add Mission" vertiportId={vertiport.id} /> */}

            <h3>Daily Activity</h3>
            <VertiportActivityUI vertiport={vertiport} />

            <h3>Drone in Storage</h3>
            <VertiportDronesInStorageUI vertiport={vertiport} />

            {/* Agents */}
            <div tw="my-4">
                <h3 tw="mb-2">Agents</h3>
                {vertiport?.agentIds.map((id) => {
                    const agent = st.simu.Agent.get(id)
                    return (
                        <div
                            key={id}
                            className="stack-line"
                            onClick={() =>
                                st.goToagentsListPage({
                                    focusId: agent.id,
                                })
                            }
                        >
                            <div className="row grow line2">
                                {icons.Agent}
                                <p tw="ml-2">{agent.name}</p>
                                <p tw="ml-2">{agent.phone}</p>
                                <p className="id">#{agent.id}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

const MeteoUI = () => (
    <Fragment>
        <h3>Méteo</h3>
        <div className="propertyGroup">
            <div className="line">
                <span className="propertyName">Wind Dir</span>
                <span>183°</span>
            </div>
            <div className="line">
                <span className="propertyName">Wind Speed</span>
                <span>3m/s</span>
            </div>
            <div className="line">
                <span className="propertyName">Rain</span>
                <span>0mm</span>
            </div>
            <div className="line">
                <span className="propertyName">Temp</span>
                <span>17℃</span>
            </div>
        </div>
    </Fragment>
)
const LocationUI = observer(function (p: { vertiport: Vertiport }) {
    const { vertiport } = p
    return (
        <Fragment>
            <h3>Location: {vertiport.city}</h3>
            <div className="propertyGroup">
                <div className="line">
                    <span>{vertiport.pluscode}</span>
                </div>
                <div className="line">
                    <span className="propertyName">Lat</span>
                    <span>{vertiport.lat.toFixed(5)}</span>
                </div>
                <div className="line">
                    <span className="propertyName">Lon</span>
                    <span>{vertiport.lon.toFixed(5)}</span>
                </div>
                <div className="line">
                    <span className="propertyName">Ground</span>
                    <span>{123}m</span>
                </div>
                <div className="line">
                    <span className="propertyName">landing</span>
                    <span>{183}m</span>
                </div>
            </div>
        </Fragment>
    )
})

export const OperationalStatusSummaryUI = observer(
    function OperationalStatusSummaryUI_(p: { vertiport: Vertiport }) {
        const v = p.vertiport
        const vs = v.status
        return (
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>now</th>
                        <th>max</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Landing Slots</td>
                        <td>
                            <IndicatorUI val={vs.curLanding} max={v.nbLandingBay} />
                        </td>
                        <td>
                            <IndicatorUI val={vs.maxSimultLanding} max={v.nbLandingBay} />
                        </td>
                    </tr>
                </tbody>
                <tr>
                    <td>Charging Slots</td>
                    <td>
                        <IndicatorUI val={vs.curChargings} max={v.nbChargingBay} />
                    </td>
                    <td>
                        <IndicatorUI val={vs.maxSimultChargings} max={v.nbChargingBay} />
                    </td>
                </tr>
                <tr>
                    <td>Parking Slots</td>
                    <td>
                        <IndicatorUI val={vs.curParkings} max={v.nbParkingBay} />
                    </td>
                    <td>
                        <IndicatorUI val={vs.maxSimultParkings} max={v.nbParkingBay} />
                    </td>
                </tr>
                <tr>
                    <td>Repair Slots</td>
                    <td>
                        <IndicatorUI val={vs.curRepairs} max={v.nbRepairBay} />
                    </td>
                    <td>
                        <IndicatorUI val={vs.maxSimultRepairs} max={v.nbRepairBay} />
                    </td>
                </tr>
            </table>
        )
    },
)

export const OperationalStatusSummary2UI = observer(
    function OperationalStatusSummary2UI_(p: { vertiport: Vertiport }) {
        const v = p.vertiport
        const vs = v.status
        return (
            <div>
                <IndicatorUI
                    label={icons.Landing}
                    val={vs.maxSimultLanding}
                    max={v.nbLandingBay}
                />
                <IndicatorUI
                    label={icons.Charging}
                    val={vs.maxSimultChargings}
                    max={v.nbChargingBay}
                />
                <IndicatorUI
                    label={icons.Parking}
                    val={vs.maxSimultParkings}
                    max={v.nbParkingBay}
                />
                <IndicatorUI
                    label={icons.Repair}
                    val={vs.maxSimultRepairs}
                    max={v.nbRepairBay}
                />
            </div>
        )
    },
)
