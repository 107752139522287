import type { Simulation } from '../state/simulation'
import type { Asserts } from 'yup'
import type { Drone } from './drone'
import type { Pilot } from './pilot'
import type { Mission } from './mission'
import { Model, s } from './schema'
import { computed, makeObservable } from 'mobx'

export const OperatorSchema = s.object({
    id: s._ID,
    name: s.str,
    phone: s.str,
    tradeName: s.str,
    siret: s.str,
    formation: s.str,
    address: s.str,
    droneIds: s._IDS, // 🔄 denormalized data
    pilotIds: s._IDS, // 🔄 denormalized data
    fake: s.bool,
    uasRegistration: s.str,
})

export type OperatorData = Asserts<typeof OperatorSchema>

export interface Operator extends OperatorData {}
export class Operator extends Model<typeof OperatorSchema> {
    constructor(simulation: Simulation, data: OperatorData) {
        super(simulation, OperatorSchema, data, 'Operator')
        makeObservable(this, {
            ...this.observability,
            droneCount: computed,
            drones: computed,
            pilots: computed,
            missions: computed,
        })
    }
    get droneCount() { return this.droneIds.length } // prettier-ignore
    get drones(): Drone[] { return this.simulation.Drone.getMany(this.droneIds) } // prettier-ignore
    get pilots(): Pilot[] { return this.simulation.Pilot.getMany(this.pilotIds) } // prettier-ignore

    get missions(): Mission[] {
        // this is bad, missions should directly be attached to operator
        return this.drones.flatMap((d) => d.missions)
    }
    downloadReport = async () => {
        const ExcellentExport = await (await import('excellentexport')).default
        ExcellentExport.convert(
            {
                format: 'xlsx',
                filename: `operator-export-${this.name}`,
                openAsDownload: true,
            },
            [
                {
                    name: 'ALL',
                    from: {
                        array: [
                            [
                                'Service ID',
                                'Activity Name',
                                'Sercvice Start At',
                                'Month',
                                'Operator ID',
                                'Operator name',
                                'Operator Phone',
                                'Operator TradeName',
                                'Operator Siret',
                                'Operator Formation',
                                'Operator Address',
                                'Mission ID',
                                'Mission Name',
                                'Mission Type',
                                'Mission Start At',
                                'Mission End At',
                                'Drone ID',
                                'Drone name',
                                'Pilot ID',
                                'Pilot name',
                                'Pilot Phone',
                                'Pilot Certification',
                                'Duration (ms)',
                                'Duration text',
                                'Service infos',
                                'Service Price',
                                'Service Price text',
                            ],
                            ...this.missions.flatMap((m) =>
                                m.actions.map((a) => [
                                    a.id,
                                    a.activity,
                                    new Date(a.at),
                                    a.month,
                                    this.id,
                                    this.name,
                                    this.phone,
                                    this.tradeName,
                                    this.siret,
                                    this.formation,
                                    this.address,
                                    m.id,
                                    m.name,
                                    m.type,
                                    new Date(m.startAt),
                                    new Date(m.endAt),
                                    m.drone.id,
                                    m.drone.name,
                                    m.pilotId,
                                    m.pilot.name,
                                    m.pilot.phone,
                                    m.pilot.certification,
                                    a.durationMS,
                                    a.durationFormatted,
                                    a.infos,
                                    a.price,
                                    a.priceFormatted,
                                ]),
                            ),
                        ],
                    },
                },

                {
                    name: 'Missions',
                    from: {
                        array: [
                            [
                                'Mission ID',
                                'Mission Name',
                                'Mission Type',
                                'Month',
                                'Start At',
                                'End At',
                                'Drone ID',
                                'Drone name',
                                'Pilot ID',
                                'Pilot name',
                                'Pilot Phone',
                                'Pilot Certification',
                            ],
                            ...this.missions.flatMap((m) =>
                                m.actions.map((a) => [
                                    m.id,
                                    m.name,
                                    m.type,
                                    a.month,
                                    m.startAt,
                                    m.endAt,
                                    m.endAt,
                                    m.drone.id,
                                    m.drone.name,
                                    m.pilotId,
                                    m.pilot.name,
                                    m.pilot.phone,
                                    m.pilot.certification,
                                ]),
                            ),
                        ],
                    },
                },
            ],
        )
    }
}

// const listPilots: Pilot[] = []
// for (let d of this.drones) {
//     listPilots.push(this.simulation.Pilot.get(d.pilotId))
// }
// return listPilots
