import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import * as L from 'leaflet'
import 'leaflet-polylinedecorator'

type Patterns = L.PolylineDecoratorOptions['patterns']

// const color = 'red'
const color = '#9c2b2b'

export const FancyLineUI = (p: {
    patterns?: Patterns
    positions: L.LatLngExpression[]
}) => {
    // const polyRef = useRef<any>()
    // const map = useMap()
    const map = useMap()
    useEffect(() => {
        // const { patterns } = p
        const patterns = p.patterns ?? arrowPattern
        const polyline = new L.Polyline(p.positions, { color, weight: 5 }).addTo(map)
        const decorator = L.polylineDecorator(polyline, { patterns }).addTo(map)
        return () => {
            decorator.remove()
            polyline.remove()
        }
    }, [map, p])
    return <div />
}

// const returnNothing = (x: any) => {}

export const arrowPattern: Patterns = [
    {
        // offset: '0%',
        repeat: 15,
        // @ts-ignore
        lineOffset: 10,
        symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: true,
            pathOptions: { color, fill: true, fillOpacity: 1 },
            // pathOptions: { stroke: true, color, weight: 5, fill: true, fillColor: color },
        }),
    },
]

// const marker = L.Symbol.Marker()
