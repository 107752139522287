import { AddMissionBtnUI } from './addMissionBtn'
import { Vertiport } from '../core/vertiport'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'
import DataGrid from 'react-data-grid'
import { themeMgr } from '../theme'
import { cls } from '../utils/cls'

export const VertiportDronesInStorageUI = observer(function VertiportActivityUI_(p: {
    vertiport: Vertiport
}) {
    const v = p.vertiport
    const st = useSt()
    return (
        <DataGrid
            style={{ maxHeight: '10rem' }}
            className={cls(themeMgr.isDark() ? 'rdg-dark' : 'rdg-light')}
            rows={v.dailyActivity}
            onRowClick={(r) => st.goTodronesListPage({ focusId: r.droneId })}
            columns={[
                {
                    key: 'drone',
                    name: 'drone',
                    width: 80,
                    formatter: (r) => <button>{r.row.droneName}</button>,
                },
                { key: 'battery', name: 'Battery', width: 80 },
                {
                    key: 'action',
                    name: '',
                    formatter: (r) => (
                        <AddMissionBtnUI
                            vertiportId={v.id}
                            droneId={r.row.droneId}
                            operatorId={r.row.drone.operatorId}
                            label="Add Mission"
                        />
                    ),
                },
            ]}
        />
    )
})

export const VertiportActivityUI = observer(function VertiportActivityUI_(p: {
    vertiport: Vertiport
}) {
    const v = p.vertiport
    const st = useSt()
    return (
        <DataGrid
            style={{ maxHeight: '10rem' }}
            className={cls(themeMgr.isDark() ? 'rdg-dark' : 'rdg-light')}
            rows={v.dailyActivity}
            onRowClick={(r) => st.goTodronesListPage({ focusId: r.droneId })}
            columns={[
                { key: 'atTime', name: 'At' },
                {
                    key: 'drone',
                    name: 'Incoming drone',
                    formatter: (r) => <button>{r.row.droneName}</button>,
                },
                { key: 'activity', name: 'service' },
                // {
                //     key: 'action',
                //     name: '',
                //     formatter: () => <AddMissionBtnUI label="Add Mission" />,
                // },
            ]}
        />
    )
})
