import type { GridState } from './gridState'
import type { GridCol } from './gridTypes'

import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { TextField } from '@material-ui/core'

export const HeaderUI = observer(function HeaderUI_(p: {
    grid: GridState<any, any>
    col: GridCol<any>
}) {
    const grid = p.grid
    const key = p.col.key
    const filters = grid.filters
    const filter: string | undefined = filters[key]
    // console.log('re-rendering', filter)
    return (
        <div className="col" tw="h-full justify-center">
            <p
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    runInAction(() => {
                        grid.sortBy = key
                        grid.sortDir = grid.sortDir === 'ASC' ? 'DESC' : 'ASC'
                    })
                    grid.syncView()
                }}
                tw="line-height[normal] mb-2"
            >
                {p.col.name}
            </p>
            <TextField
                variant="standard"
                value={filter}
                placeholder={'Filter...'}
                onChange={(e) => {
                    // console.log(filters[key], 'e')
                    const newFilter = e.target.value
                    if (newFilter) {
                        grid.filters[key] = newFilter
                    } else delete filters[key]
                    grid.syncView()
                }}
            />
        </div>
    )
})
