import type { Timestamp } from '../state/clock'

export const renderTime = (at: Timestamp) => {
    const d = new Date(at)
    let str = d.toISOString()
    return `${str.slice(0, 10)} ${str.slice(11, 16)}`
    // return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
}

/** return the 'HH:MM' element coresponding to given timestamp */
export const renderHour = (at: Timestamp) => {
    const d = new Date(at)
    const hour = d.getHours().toString().padStart(2, '0')
    const minute = d.getMinutes().toString().padStart(2, '0')
    return `${hour}:${minute}`
}

export const renderDuration = (durationMS: number): string => {
    if (durationMS < 1000) return `${durationMS.toFixed(2)} ms`
    const s = Math.floor(durationMS / 1000)
    if (s < 60) return `${s} second${s > 1 ? 's' : ''}`
    const min = Math.floor(s / 60)
    if (min < 60) return `${min}min${min > 1 ? 's' : ''} ${s % 60}s`
    const hour = Math.floor(min / 60)
    if (hour < 24) {
        const minRem = `${min % 60}`
        const minText = minRem.length === 2 ? minRem : `0${minRem}`
        return `${hour}h${minText}`
    }
    return `${durationMS} ms`
}

// const Ms = 1
// const Sec = 1000 // ms
// const Min = this.dSec * 60
// const Hour = this.dMin * 60
// const Day = this.dHour * 24
// const Month = this.dDay * 30
