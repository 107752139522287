import type { CSSProperties, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

export const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
]

export type RGB = [red: number, green: number, blue: number]
export const getColorForPercentage = function (pct: number) {
    let i: number
    for (i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break
        }
    }
    const lower = percentColors[i - 1]
    const upper = percentColors[i]
    const range = upper.pct - lower.pct
    const rangePct = (pct - lower.pct) / range
    const pctLower = 1 - rangePct
    const pctUpper = rangePct
    const color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    }
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'
    // or output as hex if preferred
}

export const IndicatorUI = observer(function IndicatorUI_(p: {
    val: number
    max?: number
    style?: CSSProperties
    label?: ReactNode
}) {
    const max = p.max ?? 1
    const val = p.max ? p.val / p.max : p.val
    const isNA = p.max === 0
    // const size = '2.5rem'
    const css: CSSProperties = {
        padding: '.1rem .3rem',
        // borderRadius: size,
        // lineHeight: size,
        // height: size,
        // width: size,
        textAlign: 'center',
        color: 'black',
        fontSize: '1rem',
        backgroundColor: isNA ? undefined : getColorForPercentage(1 - val),
        border: '1 px solid black',
    }

    let style: CSSProperties = p.style ? { ...css, ...p.style } : css
    const indic = (
        <div style={style} className="IndicatorUI">
            {isNA //
                ? 'N.A.'
                : p.max
                ? `${p.val}/${max}`
                : `${Math.round(p.val * 100)}%`}
        </div>
    )
    if (p.label) {
        return (
            <div className="line">
                {p.label}: {indic}
            </div>
        )
    } else return indic
})
