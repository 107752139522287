import { observer, useLocalObservable } from 'mobx-react-lite'
import { css } from 'twin.macro'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/core/Alert'
import Menu from '@material-ui/core/Menu'

import { useSt } from '../state/context'

import { icons, iconsComp } from '../components/iconUI'
import { NotificationsUI } from './notifications'
import { drawerWidth } from './utils'
import { themeMgr } from '../theme'
import { CartePanelTimeUI } from '../carte/panelTime'
import { ActionMenu } from '../components/actionsMenu'

export const TopBar = observer(function TopBar_() {
    const st = useSt()
    const stateNav: { [name: string]: HTMLElement | null } = useLocalObservable(() => ({
        profile: null,
        notif: null,
    }))

    // const isDark = themeMgr.isDark()
    // const background = isDark ? '#1f1f1f' : '#F9F9F9'

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: `${drawerWidth}px`,
                boxShadow: 'none',
                // borderBottom: '1px solid #EFEFEF',
            }}
        >
            <Toolbar
            // sx={{ bgcolor: background }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => (st.menuOpen = true)}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    {icons.Menu}
                </IconButton>
                {!st.isSmallScreen && (
                    <Button
                        variant="contained"
                        onClick={() => st.goToaddMissionPage({})}
                        size="small"
                    >
                        <iconsComp.Add style={{ color: 'inherit' }} />
                        Mission
                    </Button>
                )}
                <div className="grow"></div>
                <CartePanelTimeUI />
                {/* <IconButton
                        id="simple-button"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => st.goToaddMissionPage({})}
                    >
                        <iconsComp.Add style={{ color: 'inherit' }} />
                    </IconButton> */}
                {/* <IconButton
                    id="simple-button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => st.goToeditMapPage({})}
                    >
                    <iconsComp.Edit style={{ color: 'inherit' }} />
                </IconButton> */}
                {/* <AreaSelector /> */}
                {/* <form tw="width[30%]">
                    <Input placeholder="Search..." size="small" tw="w-full" />
                </form> */}
                <ActionMenu>{icons.SaveSimulation}</ActionMenu>
                <NotificationsUI />
                <IconButton color="inherit" onClick={() => themeMgr.toggle()}>
                    {icons.DarkMode}
                </IconButton>
                <IconButton
                    id="simple-button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(event) => (stateNav.profile = event.currentTarget)}
                >
                    <iconsComp.User style={{ color: 'inherit' }} />
                </IconButton>
                <Menu
                    keepMounted
                    open={Boolean(stateNav.profile)}
                    id="simple-menu"
                    anchorEl={stateNav.profile}
                    onClose={() => (stateNav.profile = null)}
                >
                    <MenuItem onClick={() => st.auth.logout()}>
                        Logout{' '}
                        <span
                            tw="ml-2"
                            css={[
                                css`
                                    svg {
                                        width: 1rem;
                                        height: 1rem;
                                    }
                                `,
                            ]}
                        >
                            {icons.Logout}
                        </span>
                    </MenuItem>
                </Menu>
            </Toolbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={st.storage.notif}
                onClose={() => (st.storage.notif = false)}
                autoHideDuration={5000}
            >
                <Alert severity="success">{st.storage.message}</Alert>
            </Snackbar>
        </AppBar>
    )
})

// const UserInfoUI = observer(function UserInfoUI_(p: {}) {
// const st = useSt()
// return <span tw="pl-2">{st.auth.username}</span>
// })

// export const NavBarUI = () => (
//     <nav className="navbar row" css={NavBarCss}>
//         <ul>
//             {pages.map((p) => {
//                 return (
//                     <li key={p.name}>
//                         <NavLink activeClassName="active" exact={p.exact} to={p.path}>
//                             {p.name}
//                         </NavLink>
//                     </li>
//                 )
//             })}
//             <li>
//                 <div>
//                     {icons.user} {st.auth.username}
//                 </div>
//                 <div tw="cursor-pointer" onClick={st.auth.logout}>
//                     {icons.logout} logout
//                 </div>
//             </li>
//         </ul>
//     </nav>
// )
