import { observer } from 'mobx-react-lite'
import type { ReactNode } from 'react'

export const PropertiesUI = observer((p: { items: [string, ReactNode][] }) => (
    <div className="propertyGroup">
        {p.items.map(([label, content]) => (
            <div key={label}>
                <span className="propertyName">{label}</span>
                <span>{content}</span>
            </div>
        ))}
    </div>
))
