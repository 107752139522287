import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'

import {
    Mission,
    MissionData,
    MissionSchema,
    missionStatusHue,
    missionTypeHue,
} from '../core/mission'
import { MissionPageParams, MissionPageUI } from './missionPage'
import { GridCol, GridView } from '../grid/gridTypes'
import { GridUI } from '../grid/gridUI'
import { State } from '../state/state'
import { useSt } from '../state/context'
import { TagUI } from '../components/tagUI'

export type MissionsListParams = {
    className?: string
} & GridView<MissionData>

export const MissionListPage = (): Page<MissionsListParams> => ({
    id: 'missionList' as const,
    label: 'Missions',
    icon: 'Mission' as const,
    path: '/missions',
    Component: MissionListPageUI,
})

const syncPane = (view: GridView<Mission>): MissionPageParams | null =>
    view.focusId ? { embedded: true, missionId: view.focusId } : null

export const MissionListPageUI = observer((p: MissionsListParams) => {
    const st = useSt()
    return (
        <div className="grow col">
            <GridUI<Mission, MissionPageParams>
                schema={MissionSchema}
                rows={(st: State) => st.simu.Mission.rows}
                add2={() => st.goToaddMissionPage({})}
                cols={missionsListCols}
                view={p}
                syncURL={(view, st) => st.goTomissionListPage({ ...p, ...view })}
                syncPane={syncPane}
                PaneUI={MissionPageUI}
                title="Missions"
            ></GridUI>
        </div>
    )
})

const missionsListCols = (): GridCol<Mission>[] => [
    { key: 'id', name: 'ID', width: 40 },
    { key: 'startAtStr', name: 'Start' },
    { key: 'operatorName', name: 'Operator' },
    {
        key: 'type',
        name: 'Type',
        width: 120,
        formatter: (t) => <TagUI hue={missionTypeHue} tags={t.row.type} />,
    },
    {
        key: 'status',
        name: 'Status',
        width: 120,
        formatter: (t) => <TagUI hue={missionStatusHue} tags={t.row.status} />,
    },
    // { key: 'name', name: 'Name' },
]
