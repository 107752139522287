import type { LatLng, LeafletMouseEvent } from 'leaflet'
import type { CSSProperties } from 'react'
import type { Vertiport } from '../core/vertiport'
import type { LatLngTuple } from '../core/point'
import { VertiPin } from '../assets/pin-vertiport'

import MarkerClusterGroup from 'react-leaflet-markercluster'
import { useMemo, Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import * as RL from 'react-leaflet'
import * as L from 'leaflet'

import { useSt } from '../state/context'
import { Drone } from '../core/drone'

import { CarteLayersCtrlUI } from './carteLayers'
import { VertiPortPinUI } from './pinVertiport'
import { DronePinUI } from './pinDrone'
import { AreaPinUI } from './pinArea'
import { iconsComp } from '../components/iconUI'
import { Corridor } from '../core/corridor'
import { CorridorsUI } from './corridorUI'

// https://react-leaflet.js.org/docs/example-layers-control/
// https://react-leaflet.js.org/docs/example-other-layers/
// https://grid.plus.codes/

// https://ec.europa.eu/transport/modes/air/aos/aos_public.html

const getZoneFromEv = (ev: L.LeafletEvent): CarteZone => {
    const chagedPos = ev.target.getCenter()
    const zoom = ev.target._zoom
    const zone: CarteZone = {
        lat: chagedPos.lat,
        lon: chagedPos.lng,
        zoom,
    }
    return zone
}
export const CarteEvents = (p: {
    onClick?: CarteProps['onClick']
    onMouseMove?: CarteProps['onMouseMove']
    onZoom?: CarteProps['onZoom']
    onPan?: CarteProps['onPan']
    onKeyDown?: CarteProps['onKeyDown']
}) => {
    const handlers = useMemo(() => {
        let handlers_: L.LeafletEventHandlerFnMap = {}
        if (p.onClick) handlers_.click = (ev) => p.onClick!(ev.latlng, ev)
        if (p.onZoom) handlers_.zoom = (ev) => p.onZoom!(getZoneFromEv(ev), ev)
        if (p.onPan) handlers_.dragend = (ev) => p.onPan!(getZoneFromEv(ev), ev)
        if (p.onKeyDown) handlers_.keydown = (ev: L.LeafletKeyboardEvent) => p.onKeyDown!(ev) // prettier-ignore
        if (p.onMouseMove) handlers_.mousemove = (ev: L.LeafletMouseEvent) => p.onMouseMove!(ev) // prettier-ignore
        return handlers_
    }, [p])
    // zoomlevelschange: (ev) => console.log('zoomlevelschange', ev),
    RL.useMapEvents(handlers)
    return null
}
export type CarteProps = {
    //
    className?: string
    center?: LatLngTuple
    zoom?: number
    preferGGHybrid?: boolean

    syncWithURL?: (lat: number, lon: number, zoom: number) => void

    editBtn?: boolean

    // Area
    areas?: boolean

    // Drones
    corridorClick?: (corridor: Corridor, ev: LeafletMouseEvent) => void
    corridorColor?: (corridor: Corridor) => string | undefined

    // Drones
    drones?: boolean
    cluster?: boolean

    // Vertiport
    vertiportTooltip?: boolean | React.FC<{ vertiport: Vertiport }>
    vertiportDrag?: (vertiport: Vertiport, latLng: LatLngTuple) => void
    vertiportClick?: (v: Vertiport, ev: LeafletMouseEvent) => void
    vertiportIcon?: (v: Vertiport) => VertiPin

    onClick?: (v: LatLng, ev: LeafletMouseEvent) => void
    onZoom?: (zone: CarteZone, ev: L.LeafletEvent) => void
    onPan?: (zone: CarteZone, ev: L.LeafletEvent) => void
    onKeyDown?: (ev: L.LeafletKeyboardEvent) => void
    onMouseMove?: (ev: L.LeafletMouseEvent) => void

    style?: CSSProperties
    children?: React.ReactNode
}

export type CarteZone = {
    lat: number
    lon: number
    zoom: number
}
export const CarteUI = observer(function CarteUI_(p: CarteProps) {
    const st = useSt()
    const simu = st.simu
    const center = p.center || simu.center
    // console.log('x', st.simu.Vertiport.rows.length)
    return (
        <RL.MapContainer
            style={{ ...p.style, position: 'relative' }}
            className={`${p.className} CarteUI`}
            center={center}
            zoom={p.zoom || 13}
            scrollWheelZoom={true}
            zoomControl={false}
        >
            <CarteEvents
                //
                onZoom={p.onZoom}
                onPan={p.onPan}
                onClick={p.onClick}
                onKeyDown={p.onKeyDown}
                onMouseMove={p.onMouseMove}
            />
            <RL.ZoomControl position="bottomright" />
            <RL.ScaleControl imperial={false} position="bottomright" />
            {p.editBtn && (
                <div
                    className="leaflet-top leaflet-right"
                    tw="z-index[800] top[3rem] right[0] "
                >
                    <div
                        className="leaflet-control-layers leaflet-control"
                        style={{ width: '44px', height: '44px' }}
                        onClick={() => st.goToeditMapPage({})}
                        tw="cursor-pointer flex items-center"
                    >
                        <iconsComp.Edit style={{ margin: 'auto' }} />
                    </div>
                </div>
            )}
            <CorridorsUI
                corridorColor={p.corridorColor}
                corridorClick={p.corridorClick}
            />
            {p.areas && (
                <RL.LayerGroup>
                    {simu.Area.rows.map((area) => (
                        <AreaPinUI area={area} key={area.id} />
                    ))}
                </RL.LayerGroup>
            )}
            {p.drones && <DroneUnderFocusUI />}
            <CarteLayersCtrlUI preferGGHybrid={p.preferGGHybrid} />
            <VertiportsUI {...p} />
            {p.drones && p.cluster ? (
                //  @ts-ignore
                <MarkerClusterGroup key={Math.random()}>
                    {simu.flyingDrones.map((drone) => (
                        <DronePinUI drone={drone} key={drone.id} />
                    ))}
                </MarkerClusterGroup>
            ) : p.drones ? (
                <RL.LayerGroup>
                    {simu.flyingDrones.map((drone) => (
                        <DronePinUI drone={drone} key={drone.id} />
                    ))}
                </RL.LayerGroup>
            ) : null}
            {p.children}

            {/* <DroneTraceUI key={Math.random()} selected={st.selectedDrone} /> */}
        </RL.MapContainer>
    )
})

export const DroneUnderFocusUI = () => {
    const st = useSt()
    const d = st.selectedDrone
    if (d == null) return null
    const center = d.currentLatLng
    if (center == null) return null
    return (
        <RL.CircleMarker
            key={d.id}
            center={center}
            pathOptions={{ color: 'blue' }}
            radius={100}
            stroke={false}
        />
    )
}
export const VertiportsUI = observer(function VertiportsUI_(
    p: Pick<
        CarteProps,
        | 'vertiportClick' //
        | 'vertiportDrag'
        | 'vertiportTooltip'
        | 'vertiportIcon'
    >,
) {
    const st = useSt()
    const simu = st.simu
    const V = simu.Vertiport
    return (
        <RL.LayerGroup>
            {V.rows.map((vp) => (
                <VertiPortPinUI
                    key={`${vp.id}`}
                    vertiportDrag={p.vertiportDrag}
                    vertiportIcon={p.vertiportIcon}
                    tooltip={p.vertiportTooltip}
                    onClick={p.vertiportClick}
                    vertiport={vp}
                />
            ))}
        </RL.LayerGroup>
    )
})

export const DroneTraceUI = observer((p: { selected: Drone | Vertiport | null }) => {
    const s = p.selected
    if (s == null) return null
    if (!(s instanceof Drone)) return null
    const currMission = s.currentMission
    if (currMission == null) return null
    return (
        <RL.GeoJSON
            style={{ color: 'red' }}
            interactive={false}
            data={currMission.trace}
        />
    )
})

export const DroneTraceSplittedUI = observer(function DroneTraceSplittedUI_(p: {
    now: number
    selected: Drone | null
}) {
    const s = p.selected
    if (s == null) return null
    const currMission = s.currentMission
    if (currMission == null) return null
    const { past, future } = currMission.traceSplitted
    return (
        <Fragment>
            <RL.GeoJSON
                key={`${p.now}-${s.id}-A`}
                style={{ color: 'red', weight: 10 }}
                interactive={false}
                data={past}
            />
            <RL.GeoJSON
                key={`${p.now}-${s.id}-B`}
                style={{ color: 'blue', dashArray: '12', weight: 7 }}
                interactive={false}
                data={future}
            />
        </Fragment>
    )
})

// export const FromToUI = observer((p: { selected: Drone | Vertiport | null }) => {
//     const s = p.selected
//     if (s == null) return null
//     if (!(s instanceof Drone)) return null
//     // const currMission = s.currentState.mission
//     // console.log({ from: s.currentState.fromPos, to: s.currentState.toPos })
//     return (
//         <Fragment>
//             {/* <L.GeoJSON interactive={false} data={currMission.trace} /> */}
//             <RL.Marker position={s.currentState.fromPos} icon={pinFrom}></RL.Marker>
//             <RL.Marker position={s.currentState.toPos} icon={pinTo}></RL.Marker>
//         </Fragment>
//     )
// })

//     {/* <TileLayer
//     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// /> */}
//     {/* find mapbox tile name in url from preview buttons from */}
//     {/* https://www.mapbox.
//     com/maps/light */}
//     {/* https://api.mapbox.com/styles/v1/mapbox/light-v10.html?title=true&access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA#12.24/51.50492/-0.11402 */}
