import { observer } from 'mobx-react-lite'
import { useSt } from '../../state/context'
import { useDraft } from '../draftContext'
import { AC } from './draftAC'

export const DraftOperatorUI = observer(function DraftOperatorUI_(p: {}) {
    const st = useSt()
    const simu = st.simu
    const D = simu.Drone
    const O = simu.Operator
    const P = simu.Pilot
    const draft = useDraft()

    return (
        <div className="col">
            <AC
                getName={(a) => a.name}
                // variant="filled"
                label="Operator"
                rows={O.rows}
                remove={() => {
                    draft.operator = null
                    draft.drone = null
                    draft.pilot = null
                }}
                getVal={() => draft.operator}
                setVal={(rid) => (draft.operator = O.get(rid))}
            />
            <AC
                getName={(a) => a.name}
                // variant="filled"
                label="Drone"
                rows={draft.operator ? draft.operator.drones : D.rows}
                getVal={() => draft.drone}
                remove={() => {
                    draft.drone = null
                    draft.pilot = null
                }}
                setVal={(rid) => {
                    const drone = D.get(rid)
                    draft.drone = drone
                    draft.operator = drone.operator
                    draft.pilot = drone.operator.pilots[0]
                    // draft.pilot = drone.pilot
                }}
            />
            <AC
                getName={(a) => a.name}
                // variant="filled"
                label="Pilot"
                rows={draft.operator ? draft.operator.pilots : P.rows}
                getVal={() => draft.pilot}
                remove={() => (draft.pilot = null)}
                setVal={(rid) => {
                    const pilot = P.get(rid)
                    draft.pilot = pilot
                }}
            />
            {/* {draft.pilot && <div>Pilot: {draft.pilot?.name}</div>} */}
        </div>
    )
})
