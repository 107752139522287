import type { MouseEvent } from 'react'

import { useCallback, Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'

import { MenuPageEntry, MenuGroupEntry, makeMenu } from './menuDefinitions'
import { icons, iconsComp } from '../components/iconUI'
import { useSt } from '../state/context'

export const AppNavUI = observer(function PageListUI_() {
    const st = useSt()
    const menu = makeMenu(st)
    return (
        <List dense>
            {menu.map((s, ix) => {
                if (s.type === 'leaf') return <MenuPageUI key={ix} {...s} />
                return <MenuGroupUI key={ix} {...s} />
            })}
        </List>
    )
})

const MenuGroupUI = <T extends any>(g: MenuGroupEntry<T>) => {
    const [open, setOpen] = useState(false)
    const hist = useHistory()
    const st = useSt()
    const goToPage = (p: string) => hist.push(p)
    const handleCaretClick = useCallback(
        (ev: MouseEvent<HTMLSpanElement>) => {
            ev.stopPropagation()
            ev.preventDefault()
            setOpen(!open)
        },
        [open],
    )
    const handlePageClick = useCallback(
        () => goToPage(st.renderURL(g.page, g.props())),
        // eslint-disable-next-line
        [],
    )
    return (
        <Fragment>
            <ListItem button onClick={handlePageClick}>
                <span onClick={handleCaretClick}>
                    {open ? ( //
                        <iconsComp.ArrowDown fontSize="small" />
                    ) : (
                        <iconsComp.ArrowRight fontSize="small" />
                    )}
                </span>
                <ListItemIcon className="menu-icon">{g.icon}</ListItemIcon>
                <ListItemText primary={g.label} />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {g.content.map((s, ix) =>
                        s.type === 'leaf' ? (
                            <MenuPageUI key={ix} {...s} />
                        ) : (
                            <MenuGroupUI key={ix} {...s} />
                        ),
                    )}
                </List>
            </Collapse>
        </Fragment>
    )
}

const MenuPageUI = <T extends any>(i: MenuPageEntry<T>) => {
    const hist = useHistory()
    const st = useSt()
    const goToPage = (p: string) => hist.push(p)
    // const action = i.action
    return (
        <ListItem
            button
            tw="padding-left[40px]"
            selected={st.observableURL === i.page.path}
            onClick={() => {
                if (i.action) i.action()
                goToPage(st.renderURL(i.page, i.props()))
            }}
        >
            <ListItemIcon className="menu-icon">{icons[i.page.icon]}</ListItemIcon>
            <ListItemText primary={i.label || i.page.label} />
            {/* {action && (
                <span
                    onClick={(ev) => {
                        ev.stopPropagation()
                        ev.preventDefault()
                        action()
                    }}
                >
                    <iconsComp.Add fontSize="small" />
                </span>
            )} */}
        </ListItem>
    )
}

// {/* <ListItem>
//     <AreaSelector />
// </ListItem> */}
// {/*  */}
// {/* {pages
//     .filter((p) => p.nav ?? true)
//     .map((p) => {
//         return (
//             <ListItem
//                 button
//                 selected={st.observableURL === p.path}
//                 key={p.id}
//                 onClick={() => goToPage(p.path)}
//             >
//                 <ListItemIcon>{icons[p.icon]}</ListItemIcon>
//                 <ListItemText primary={p.label} />
//             </ListItem>
//         )
//     })} */}
