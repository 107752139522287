import { Box, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { observer } from 'mobx-react-lite'
import { CSSProperties, Fragment } from 'react'
import { useSt } from '../state/context'

const style: CSSProperties = {
    position: 'absolute',
    top: '1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: 350,
    maxHeight: '80vh',
    overflow: 'auto',
    maxWidth: '98vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // @ts-ignore
    boxShadow: 24,
    p: 4,
}

export const PopupUI = observer(function PopupUI_(p: {}) {
    const st = useSt()

    if (st.popups.length === 0) return null

    return (
        <Fragment>
            {st.popups.map((popup, ix) => (
                <Modal
                    key={ix}
                    open={true}
                    onClose={() => st.popups.splice(ix, 1)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{ marginTop: `${ix / 10}em` }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {popup.title}
                        </Typography>
                        {typeof popup.Comp === 'string' ? (
                            <div>{popup.Comp}</div>
                        ) : (
                            <popup.Comp close={() => st.popups.splice(ix, 1)} />
                        )}
                    </Box>
                </Modal>
            ))}
        </Fragment>
    )
})
