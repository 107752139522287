import type * as T from '@turf/helpers'
import type { Asserts } from 'yup'
import type { Simulation } from '../state/simulation'
import type { Lat, Lng, LngLatTuple } from './point'
import type { RID } from './schema'
import type { Timestamp } from '../state/clock'

import { action, computed, makeObservable, observable } from 'mobx'

import { Model, s } from './schema'
import { turf } from '../state/turf'

export const CorridorSchema = s.object({
    id: s._ID,
    vertiportId1: s._ID,
    vertiportId2: s._ID,
    maxSpeed: s.num,
    waypointsLats: s.nums,
    waypointsLons: s.nums,
    waypointsSpeeds: s.nums,
})

export type CorridorData = Asserts<typeof CorridorSchema>
export interface Corridor extends CorridorData {}
export class Corridor extends Model<typeof CorridorSchema> {
    constructor(simulation: Simulation, data: CorridorData) {
        super(simulation, CorridorSchema, data, 'Corridor')
        makeObservable(this, {
            ...this.observability,
            vertiport1: computed,
            vertiport2: computed,
            asGeoLine: computed,
            asGeoPoly: computed,
            // duration: observable,
            distance: observable,
            udpateDistanceAndDuration: action,
            addWaypoint: action,
            moveWaypoint: action,
        })
        this.udpateDistanceAndDuration()
    }

    get positions(): L.LatLngExpression[] {
        const positions: L.LatLngExpression[] = [
            [this.vertiport1.lat, this.vertiport1.lon],
            ...this.waypointsLats.map(
                (l, ix) => [l, this.waypointsLons[ix]] as L.LatLngExpression,
            ),
            [this.vertiport2.lat, this.vertiport2.lon],
        ]
        return positions
    }
    // get center(): LatLngTuple { return [this.lat, this.lon] } // prettier-ignore

    addWaypoint = (atPos?: { lat: Lat; lon: Lng }) => {
        let atLat: Lat, atLon: Lng
        if (atPos) {
            atLat = atPos.lat
            atLon = atPos.lon
        } else {
            const nb = this.waypointsLats.length
            const v1 = this.vertiport1
            const lat1 = nb > 0 ? this.waypointsLats[nb - 1] : v1.lat
            const lon1 = nb > 0 ? this.waypointsLons[nb - 1] : v1.lon
            const v2 = this.vertiport2
            atLat = (lat1 + v2.lat) / 2
            atLon = (lon1 + v2.lon) / 2
        }
        this.waypointsLats.push(atLat)
        this.waypointsLons.push(atLon)
        this.waypointsSpeeds.push(this.maxSpeed)

        // console.log('a')
        this.udpateDistanceAndDuration()
        // console.log('b')
    }
    moveWaypoint = () => {
        //
    }

    /** returns the position along the corridor  */
    posAt = (
        /** a number between 0 and 1 indicating the percentage of the corridor already travelled */
        fraction: number,
    ): LngLatTuple => {
        return [0, 0] as any
    }
    get vertiport1() {
        return this.simulation.Vertiport.get(this.vertiportId1)
    }
    get vertiport2() {
        return this.simulation.Vertiport.get(this.vertiportId2)
    }

    // duration: number = ...
    distance: number = 0

    // geoHandlers = () => ({
    //     click: (ev: L.LeafletMouseEvent) => {
    //         if (p.corridorClick == null) return
    //         L.DomEvent.stop(ev)
    //         const props: CorridorGeoProps = ev.sourceTarget.feature.properties
    //         const corridor = simu.Corridor.get(props.id)
    //         p.corridorClick(corridor, ev)
    //     },
    // })

    udpateDistanceAndDuration = () => {
        const v1 = this.vertiport1
        const v2 = this.vertiport2
        let at: LngLatTuple = [v1.lon, v1.lat]
        let dist = 0
        // let duration = ...
        for (let i = 0; i < this.waypointsLats.length; i++) {
            const lat = this.waypointsLats[i]
            const lon = this.waypointsLons[i]
            const next: LngLatTuple = [lon, lat]
            dist += turf.distance(at, next, { units: 'meters' })
            // duration += ...
            at = next
        }
        let end: LngLatTuple = [v2.lon, v2.lat]
        dist += turf.distance(at, end, { units: 'meters' })

        //
        // this.duration = ....
        this.distance = dist

        return dist
    }

    // | GeoJSONLineString<CorridorGeoProps>
    // if (nbWaypoints === 0) return lineStringFeature(v1.lonLatTuple, v2.lonLatTuple, p)
    get asGeoLine(): T.Feature<T.LineString, CorridorGeoProps> {
        const v1 = this.vertiport1
        const v2 = this.vertiport2
        const v1Id = v1.id
        const v2Id = v2.id
        const updatedAt = Date.now()
        const p: CorridorGeoProps = { v1Id, v2Id, id: this.id, updatedAt }
        const points: T.Position[] = [
            v1.lonLatTuple,
            ...this.waypointsLats.map((lat, ix) => [this.waypointsLons[ix], lat]),
            v2.lonLatTuple,
        ]
        const line = turf.lineString(points as T.Position[], p)
        return line
    }

    get asGeoPoly(): T.Feature<T.Polygon, CorridorGeoProps> {
        const v1 = this.vertiport1
        const v2 = this.vertiport2
        const v1Id = v1.id
        const v2Id = v2.id
        const updatedAt = Date.now()
        const line = this.asGeoLine
        type Cori = T.Feature<T.Polygon, CorridorGeoProps>
        const shape = turf.buffer(line, 10, { units: 'meters' }) as Cori
        const props: CorridorGeoProps = { v1Id, v2Id, id: this.id, updatedAt }
        shape.properties = props
        return shape
    }

    /**
     * Those Delete methods are responsible for
     *  - wiping caches (see ._IDs)
     *  - cascade deleting stuff
     * crashes if related vertiport do not exist
     * */
    Delete = () => {
        const rm = (cid: RID) => cid !== this.id
        const v1 = this.vertiport1
        const v2 = this.vertiport2
        v1.corridorIds = v1.corridorIds.filter(rm)
        v2.corridorIds = v2.corridorIds.filter(rm)
        this.UNSAFE_Delete()
    }
}

export type CorridorGeoProps = {
    updatedAt: Timestamp
    v1Id: RID
    v2Id: RID
    id: RID
}
