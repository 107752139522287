import { action, makeObservable, observable } from 'mobx'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { Theme } from '@emotion/react'
import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

type RVTheme = 'light' | 'dark'
class ThemeMgr {
    /** DO NOT CHANGE THIS MANUALLY */
    currentTheme: RVTheme = 'light'
    isDark = (): boolean => this.currentTheme === 'dark'
    isLight = (): boolean => this.currentTheme === 'light'

    toggledAt: number = Date.now()

    // CHANGE THEME
    setDark = (): void => {
        this.currentTheme = 'dark'
        this.toggledAt = Date.now()
        this.theme = this.mkTheme('dark')
    }

    setLight = () => {
        this.toggledAt = Date.now()
        this.currentTheme = 'light'
        this.theme = this.mkTheme('light')
    }
    /** alternate dark and light theme */
    toggle = (): void => {
        this.toggledAt = Date.now()
        this.currentTheme =
            this.currentTheme === 'dark' //
                ? 'light'
                : 'dark'
        this.theme = this.mkTheme(this.currentTheme)
    }

    // INTERNAL STUFF -----------
    private theme: Theme
    Provider = observer((p: { children: ReactNode }) => (
        <ThemeProvider theme={this.theme}>{p.children}</ThemeProvider>
    ))

    private localStorageKey = 'preferedTheme'
    constructor() {
        const mode = localStorage.getItem(this.localStorageKey)
        if (mode === 'dark') this.currentTheme = 'dark'
        else if (mode === 'light') this.currentTheme = 'light'
        makeObservable(this, {
            // @ts-ignore
            theme: observable,
            currentTheme: observable,
            setDark: action,
            setLight: action,
            toggle: action,
        })
        this.theme = this.mkTheme(this.currentTheme)
    }

    private mkTheme = (mode: RVTheme): Theme => {
        localStorage.setItem(this.localStorageKey, mode)
        return createTheme({
            // transitions: {
            //     // So we have `transition: none;` everywhere
            //     create: () => 'none',
            // },
            typography: { button: { textTransform: 'none' } },
            palette: {
                mode: mode,
                primary: { main: '#4DA557' },
                secondary: { main: '#3984C3' },
                background: { default: '#424242' },
            },
            components: {
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            color: mode === 'light' ? '#121212' : '#FFFFFF',
                            background: mode === 'light' ? '#FFFFFF' : '#121212',
                            minHeight: '3.5rem !important',
                        },
                    },
                },
            },
            // components: {
            //     MuiChip: {
            //         styleOverrides: {
            //             root: {
            //                 fontWeight: 500,
            //                 fontSize: '.75rem',
            //             },
            //             label: { padding: '2px 10px' },
            //         },
            //     },
            //     MuiAlert: {
            //         styleOverrides: {
            //             root: {
            //                 borderRadius: '.375rem',
            //                 fontWeight: 500,
            //             },
            //         },
            //     },
            //     MuiToolbar: {
            //         styleOverrides: {
            //             root: { minHeight: '55px !important' },
            //         },
            //     },
            //     MuiToggleButton: {
            //         styleOverrides: {
            //             root: { paddingTop: '5px', paddingBottom: '5px' },
            //         },
            //     },
            // },
        })
    }
}
export const themeMgr = new ThemeMgr()
