import { icons } from '../components/iconUI'
import { State } from '../state/state'
import { Page, pageIndex } from './pages'

const pi = pageIndex
export type MenuPageEntry<T> = {
    type: 'leaf'
    page: Page<T>
    props: () => T
    label?: string
    action?: () => void
}
export type MenuGroupEntry<T> = {
    type: 'node'
    page: Page<T>
    props: () => T
    content: MenuEntry<any>[]
    label: string
    icon: JSX.Element
}
export type MenuEntry<T = any> = MenuPageEntry<T> | MenuGroupEntry<T>

export const makeMenu = (st: State): MenuEntry[] => [
    // addGroup(
    //     'Map',
    //     icons.Carte,
    //     st.simu.Area.rows.map((a) =>
    //         addPage(pi.home, () => ({ lat: a.lat, lon: a.lon }), a.name),
    //     ),
    // ),
    addPage(pi.dashboard),
    addGroup(pi.home, () => st.loc, 'Map', icons.Carte, [
        ...st.simu.Area.rows.map((area) =>
            addPage(
                pi.home,
                () => ({ lat: area.lat, lon: area.lon, key: area.name }),
                area.name,
                () => (st.simu.activeArea = area),
            ),
        ),
        addPage(pi.editMap, () => st.loc, 'Edit'),
    ]),
    // addPage(pi.agentsList),
    addPage(pi.operatorsList),
    addPage(
        pi.missionList,
        () => ({}),
        'Missions',
        () => st.goToaddMissionPage({}),
    ),
    addGroup(pi.vertiportsList, () => ({}), 'Vertiports', icons.Vertiport, [
        addPage(pi.agentsList),
    ]),

    // DRONES
    // addPage(pi.dronesList),
    addGroup(
        pi.dronesList,
        () => ({ at: 'B', groupBy: ['operatorName'] }),
        'Drones',
        icons.Drone,
        [
            addPage(pi.dronesList, () => ({ at: 'A' }), 'All'),
            addPage(pi.dronesList, () => ({ at: 'C', onlyFlying: true }), 'Flying'),
            // addPage(pi.pilotsList),
        ],
    ),
    addPage(pi.pilotsList),
    addPage(
        pi.actionsList,
        () => ({ sortBy: 'at', sortDir: 'DESC' as const }),
        'Log Actions',
    ),
    // addPage(pi.addMission),
    addPage(
        pi.invoices,
        () => ({
            groupBy: ['month', 'operatorName'],
            groups: st.simu.clock.possibleMonths,
        }),
        'Invoices',
    ),
    // addGroup('Invoices', icons.Invoice, [
    //     addPage(pi.invoices, () => ({ groupBy: ['month', 'operatorName'] }), 'Paid'),
    //     addPage(pi.invoices, () => ({ groupBy: ['month', 'operatorName'] }), 'Pending'),
    // ]),
    // addGroup('Profits', icons.Euro, [
    //     addPage(pi.profits, () => ({}), 'By Month'),
    //     addPage(pi.profits, () => ({ groupBy: ['operatorName'] }), 'By Operator'),
    //     addPage(pi.profits, () => ({ groupBy: ['vertiportId'] }), 'By Vertiport'),
    //     addPage(pi.profits, () => ({ groupBy: ['activity'] }), 'By Activity'),
    //     addPage(pi.profits, () => ({}), 'This Month'),
    //     addPage(pi.profits, () => ({}), 'Last Month'),
    // ]),
    addPage(pi.profits, undefined, 'Financial dashboard'),
    addGroup(pi.pricingList, () => ({}), 'Settings', icons.Settings, [
        // addPage(pi.profits, () => ({}), 'By Month'),
        // addPage(pi.profits, () => ({ groupBy: ['operatorName'] }), 'By Operator'),
        // addPage(pi.profits, () => ({ groupBy: ['vertiportId'] }), 'By Vertiport'),
        // addPage(pi.logs),
        addPage(pi.admin),
        addPage(pi.editMap),
        addPage(pi.icons),
        addPage(pi.pricingList),
    ]),
]
const addPage = <T extends any>(
    page: Page<T>,
    props?: () => T,
    label?: string,
    action?: () => void,
): MenuPageEntry<T> => {
    return {
        type: 'leaf',
        page,
        props: props || ((() => {}) as any),
        label,
        action,
    }
}

const addGroup = <T extends any>(
    page: Page<T>,
    props: () => T,
    label: string,
    icon: JSX.Element,
    subs: MenuEntry<any>[] = [],
): MenuGroupEntry<T> => {
    return {
        type: 'node',
        content: subs,
        label,
        icon,
        page,
        props,
    }
}
