import type {
    LatLng,
    LatLngTuple,
    LeafletEventHandlerFnMap,
    LeafletMouseEvent,
    Marker,
} from 'leaflet'
import type { Icon } from 'leaflet'

import { VertiPin, vertiPins } from '../assets/pin-vertiport'

import * as R from 'react'
import { observer } from 'mobx-react-lite'
import * as L from 'react-leaflet'

import { OperationalStatusSummary2UI } from '../pages/vertiportPage'
import { Vertiport } from '../core/vertiport'
import { useSt } from '../state/context'
import { runInAction } from 'mobx'

export const VertiPortPinUI = observer(function VertiPortPinUI_(p: {
    vertiport: Vertiport
    vertiportIcon?: (v: Vertiport) => VertiPin
    vertiportDrag?: (vertiport: Vertiport, latLng: LatLngTuple) => void
    tooltip?: boolean | React.FC<{ vertiport: Vertiport }>
    onClick?: (v: Vertiport, ev: LeafletMouseEvent) => void
}) {
    // console.log(p.tooltip)
    const v = p.vertiport
    const st = useSt()
    const [position, setPosition] = R.useState([v.lat, v.lon] as LatLngTuple | LatLng)
    const markerRef: R.Ref<Marker<any>> = R.useRef(null)
    const pin: Icon = p.vertiportIcon //
        ? vertiPins[p.vertiportIcon(v)]
        : vertiPins.gray

    const eventHandlers = R.useMemo(
        (): LeafletEventHandlerFnMap => ({
            dragend() {
                const marker = markerRef.current
                if (marker == null) return
                const newPos = marker.getLatLng()
                setPosition(newPos)
                if (p.vertiportDrag) {
                    const latLng = [newPos.lat, newPos.lng] as LatLngTuple
                    p.vertiportDrag(v, latLng)
                }
            },
            click(_ev) {
                runInAction(() => {
                    if (p.onClick) return p.onClick(v, _ev)
                    st.selectVertiport(v)
                })
            },
        }),
        [],
    )

    // const position: LatLngTuple = [v.lat, v.lon]
    const TooltipProp = p.tooltip

    const tooltip =
        TooltipProp === true ? (
            <L.Tooltip>
                <h3>{v.name}</h3>
                <OperationalStatusSummary2UI vertiport={v} />
                {/* <QRCodeUI encodedString={v.name} /> */}
            </L.Tooltip>
        ) : typeof TooltipProp === 'function' ? (
            <L.Tooltip>
                <TooltipProp vertiport={v} />
            </L.Tooltip>
        ) : null
    // console.log('x', tooltip, typeof TooltipProp, p.tooltip)
    return (
        <L.Marker
            draggable={Boolean(p.vertiportDrag)}
            ref={markerRef}
            eventHandlers={eventHandlers}
            position={position}
            icon={pin}
        >
            {tooltip}
            {/* <L.Popup>{v.name}</L.Popup> */}
        </L.Marker>
    )
})

// const DOld=[92,112]
// const D = [69, 144]
// export const pinVertiport = new Icon({
//     iconUrl: vertiImg.base,
//     // popupAnchor: [D[0] / 2, 0],
//     iconAnchor: [D[0] / 4, D[1] / 2],
//     iconSize: [D[0] / 2, D[1] / 2],
//     className: 'marker',
// })

export const SimpleVertiportTooltip = (p: { vertiport: Vertiport }) => (
    <h3>{p.vertiport.name}</h3>
)
