import type { Drone } from '../core/drone'
import type { FC } from 'react'
import type { Vertiport } from '../core/vertiport'
import type { Corridor } from '../core/corridor'

import { createBrowserHistory } from 'history'
import { makeAutoObservable } from 'mobx'

// import { PDFBuidler } from '../pdf'
import { AuthManager } from './auth'
import {
    newEmptySimulationData,
    SCHEMA_VERSION_CONST,
    Simulation,
    SimulationData,
} from './simulation'

import dfpdata from '../import/dataset-small/data.json'

import { NavigationUtils, Page, pages } from '../layout/pages'
import { randomInt } from '../fakes/fakePhoneNumber'
import { ImportedData } from '../import/types'
import { RID } from '../core/schema'
import { urlStringify } from '../utils/uri'
import { nanoid } from 'nanoid'
import { areaInfos } from '../core/point'
import { turf } from './turf'
import { rnd } from '../utils/range'
import { choose } from '../fakes/utils'
import { HomePageProps } from '../pages/homePage'
import { Operator } from '../core/operator'

export interface State extends NavigationUtils {}
export class State {
    // routing stuff
    history = createBrowserHistory()
    observableURL: string = ''

    popups: { title: string; Comp: string | FC<{ close: () => void }> }[] = []
    addPopup = (title: string, Comp: FC<{ close: () => void }> | string) => {
        this.popups.push({ title, Comp })
    }

    // pdf = new PDFBuidler(this)
    simu: Simulation
    loc: HomePageProps = {}

    selectedDrone: Drone | null = null
    selectedCorridor: Corridor | null = null
    selectedVertiport: Vertiport | null = null
    selectDrone = (d: Drone) => {
        this.selectedVertiport = null
        this.selectedDrone = d
        this.selectedCorridor = null
    }
    selectCorridor = (c: Corridor) => {
        this.selectedVertiport = null
        this.selectedDrone = null
        this.selectedCorridor = c
    }
    selectVertiport = (v: Vertiport) => {
        this.selectedVertiport = v
        this.selectedDrone = null
        this.selectedCorridor = null
    }

    spaceOnScreen: boolean = true
    windowWidth: number = window.innerWidth
    menuOpen: boolean = false

    chronologicalTimeline: boolean = false

    get isSmallScreen(): boolean {
        return this.windowWidth < 768
    }

    wipeOperator = (o: Operator) => {
        for (let p of o.pilots) p.UNSAFE_Delete()
        for (let d of o.drones) {
            for (let m of d.missions) {
                for (let a of m.actions) a.UNSAFE_Delete()
                m.UNSAFE_Delete()
            }
            d.UNSAFE_Delete()
        }
        o.UNSAFE_Delete()
    }
    getPilgrim = (): Operator | null => {
        const S = this.simu
        const O = S.Operator
        const uasRegistration = 'FRAyyix2ypez4kzm'
        const pilgrim = O.rows.find((o) => o.uasRegistration === uasRegistration)
        return pilgrim || null
    }

    pretendNoPilgrim = () => {
        const pilgrim = this.getPilgrim()
        if (pilgrim == null) return
        this.wipeOperator(pilgrim)
    }

    addPilgrim = (): Operator => {
        const S = this.simu
        const O = S.Operator
        const uasRegistration = 'FRAyyix2ypez4kzm'
        const existing = O.rows.find((o) => o.uasRegistration === uasRegistration)
        if (existing) {
            this.addPopup('error', 'already exists')
            console.log('already exists')
            return existing
        }

        const operatorId = S.getRID()

        // PILOTS
        const pilotIds: RID[] = []
        const name1 = 'Elie de Riols de Fonclare'
        const phone1 = '+33674953554'
        pilotIds.push(
            S.addPilot({
                name: name1,
                operatorId,
                phone: phone1,
                certification: 'Awesome',
            }).id,
        )
        const name2 = 'Hugo Chapuis-Kériven'
        const phone2 = '+33783418182'
        pilotIds.push(
            S.addPilot({
                name: name2,
                operatorId,
                phone: phone2,
                certification: 'Awesome',
            }).id,
        )

        // DRONES
        const droneIds: RID[] = []
        const brand = 'PILGRIM TECHNOLOGY'
        const model = '2BM81 - STARFURY'
        droneIds.push(S.addDrone({ operatorId, name: 'ED3132', brand, model }).id)
        droneIds.push(S.addDrone({ operatorId, name: 'ED2548', brand, model }).id)

        return S.addOperator({
            id: operatorId,
            pilotIds,
            droneIds,
            address: '92120 MONTROUGE',
            siret: '74998713700058',
            name: 'Pilgrim',
            tradeName: 'Pilgrim Technology',
            uasRegistration,
        })
    }

    clearMissions = () => {
        const simu = this.simu
        // delete all actions
        simu.Action.clear()
        // delete all missions
        simu.Mission.clear()
        // delete all missions back-references stored in drones
        simu.Drone.rows.forEach((drone) =>
            drone.missionIds.splice(0, drone.missionIds.length),
        )
        // delete all vertiports marked for deletion
        const deletedVs = simu.Vertiport.removeWhere((i) => i.deleted)
        const deletedVIds = deletedVs.map((v) => v.id)
        const A = simu.Agent
        for (let dv of deletedVs) {
            // delete agents that belong to removed vertiport
            for (let aid of dv.agentIds) A.get(aid).UNSAFE_Delete()
        }
        // delete cached vertiports in Areas
        for (let a of simu.Area.rows)
            a.vertiportIds = a.vertiportIds.filter((x) => !deletedVIds.includes(x))

        const deletedCorridorIds: RID[] = []
        for (let c of simu.Corridor.rows)
            if (
                deletedVIds.includes(c.vertiportId1) ||
                deletedVIds.includes(c.vertiportId2)
            ) {
                deletedCorridorIds.push(c.id)
                c.UNSAFE_Delete()
            }

        // delete remaining connections from remaining vertiports to deleted vertiport
        for (let v of simu.Vertiport.rows)
            v.corridorIds = v.corridorIds.filter((x) => !deletedCorridorIds.includes(x))
    }

    regenMissions = () => {
        // clear missions (see above)
        this.clearMissions()
        const simu = this.simu

        // 1. reset clock and time window
        const clock = simu.clock
        const now = Date.now()
        clock.centerTimeAround(now)

        // 2. pick area
        const area =
            simu.Area.rows.find((r) => r.name === 'Paris') ?? //
            simu.Area.choose()

        // 3. reset distribution hack
        simu.__GENCACHE.clear()

        // 4. generate a bunch of missions
        for (let op of simu.Operator.rows) {
            if (!op.fake) continue
            const nbMission = randomInt(10)
            for (let i = 0; i < nbMission; i++) {
                const drone = choose(op.drones)
                const pilotId = choose(op.pilotIds)
                const mission = this.simu.addRandomMission(area, drone.id, pilotId)
                drone.missionIds.push(mission.id)
            }
        }
    }

    //
    constructor() {
        console.log('starting')
        let data = this.loadSimulationData()
        let isNewSimulation = false
        if (data == null) {
            data = newEmptySimulationData()
            isNewSimulation = true
        }
        this.simu = new Simulation(this, data)
        makeAutoObservable(this)
        this.history.listen((ev) => (this.observableURL = ev.pathname + ev.search))

        if (isNewSimulation) {
            // console.log('isNEWSimulation')
            // add some vertiports in each area
            for (let area of this.simu.Area.rows) {
                const infos = areaInfos[area.name]
                if (infos == null) throw new Error('missing area setup infos')
                for (let i = 0; i < infos.nbVertiport; i++) {
                    this.simu.addVertiportNear(area, infos)
                }
            }

            // add "couloir aériens"
            const vs = this.simu.Vertiport.rows
            const vLen = vs.length
            // const links = new Map<Vertiport, Vertiport[]>()
            // const setLink = (v1: Vertiport, v2: Vertiport) => {
            //     let s1 = links.get(v1)
            //     if (s1 == null) {
            //         s1 = []
            //         links.set(v1, s1)
            //     }
            //     let s2 = links.get(v2)
            //     if (s2 == null) {
            //         s2 = []
            //         links.set(v1, s2)
            //     }
            //     s1.push(v2)
            //     s2.push(v1)
            // }
            for (let v1_ix = 0; v1_ix < vLen; v1_ix++) {
                const v1 = vs[v1_ix]
                const V_near: { dist: number; v2: Vertiport }[] = []
                const V_far: { dist: number; v2: Vertiport }[] = []
                for (let v2_ix = v1_ix + 1; v2_ix < vLen; v2_ix++) {
                    const v2 = vs[v2_ix]
                    const dist = turf.distance(
                        turf.point([v1.lon, v1.lat]),
                        turf.point([v2.lon, v2.lat]),
                        { units: 'meters' },
                    )
                    if (dist < 3000) V_near.push({ dist, v2: v2 })
                    else V_far.push({ dist, v2: v2 })
                }
                let closest = V_near.sort((a, b) => a.dist - b.dist).slice(0, 3)
                // RV connections => connectionIds
                if (closest.length + v1.corridorIds.length === 0) {
                    closest = V_far.sort((a, b) => a.dist - b.dist).slice(0, 1)
                }
                // console.log(closest.length)
                // link those vertiports
                for (let { v2 } of closest) this.simu.addCorridor(v1, v2)
            }

            const S = this.simu
            // ADD OPERATOR and drones from excel file provided
            let srcData: ImportedData = dfpdata
            for (let src of Object.values(srcData.operators).slice(0, 50)) {
                const operatorId = S.getRID()

                // CHOOSE AREA
                const area = S.Area.choose()

                // ADD PILOTS (at least 1)
                const pilotIds: RID[] = []
                const pilot = S.addPilot({ operatorId })
                pilotIds.push(pilot.id)
                const EXRA_PILOTS_COUNT = rnd(0.2) ? 3 : rnd(0.2) ? 2 : 1
                for (let i = 0; i < EXRA_PILOTS_COUNT; i++)
                    pilotIds.push(S.addPilot({ operatorId }).id)

                // ADD DRONES
                const drones: Drone[] = []
                const droneIds: RID[] = []
                for (let idd of src.drones) {
                    const brand = srcData.constructors[idd.c]
                    const model = srcData.models[idd.m]
                    const drone = S.addDrone({ id: S.getRID(), operatorId, brand, model })
                    drones.push(drone)
                    droneIds.push(drone.id)
                }

                // ADD MISSIONS
                const nbMission = randomInt(10)
                for (let i = 0; i < nbMission; i++) {
                    const drone = choose(drones)
                    const pilotId = choose(pilotIds)
                    const mission = S.addRandomMission(area, drone.id, pilotId)
                    drone.missionIds.push(mission.id)
                }

                // ADD OPERATOR
                S.addOperator({
                    id: operatorId,
                    pilotIds,
                    droneIds,
                    address: src.address,
                    siret: src.siret,
                    name: src.name,
                    tradeName: src.commercialName,
                })
            }
        }

        if (isNewSimulation) {
            this.storage.key = `simu-${nanoid()}`
            this.saveSimulationData()
        }

        this.definePages()
        console.log(`${this.simu.Action.rows.length} actions`)
        console.log(`${this.simu.Operator.rows.length} operators`)
        console.log(`${this.simu.Drone.rows.length} drones`)
        console.log(`${this.simu.Mission.rows.length} missions`)
        // console.log(this.simu.Operator)

        window.addEventListener('resize', () => (this.windowWidth = window.innerWidth))
    }
    // airLines!: GeoJSONPath
    // airCorridors!: any // GeoJSONObject
    airCorridorVersion!: string

    definePages = () => {
        for (let p of pages) {
            Object.defineProperty(this, `goTo${p.id}Page`, {
                value: (params?: any) => {
                    // console.log(`> goTo${p.id}Page(${JSON.stringify(params)})`)
                    const url = this.renderURL(p, params)
                    this.history.push(url)
                },
            })
        }
    }
    renderURL = (p: Page<any>, params: any = {}): string => {
        // console.log(`> goTo${p.id}Page(${JSON.stringify(params)})`)
        const segmentsParams = p.path
            .split('/')
            .filter((x) => x.startsWith(':'))
            .map((i) => i.slice(1))
        let url: string = p.path
        for (let seg of segmentsParams) {
            const val = params[seg]
            if (val == null) throw new Error(`invalid URL, missing param ${seg}`)
            url = url.replace(`:${seg}`, val)
        }
        if (params) {
            let used = new Set(segmentsParams)
            let queryParams: any = {}
            for (let [k, v] of Object.entries(params))
                if (!used.has(k)) queryParams[k] = v
            if (Object.keys(queryParams).length > 0)
                // url += `?${qs.stringify(queryParams, { encodeValuesOnly: true })}`
                // url += `?${encodeURI(JSON.stringify(queryParams))}`
                url += `?${urlStringify(queryParams)}`
        }
        return url
    }
    auth = new AuthManager(this)

    // currPdfIsFor: string = ''
    // pdfFor = (id: string) => {
    //     if (this.currPdfIsFor !== id) {
    //         this.currPdfIsFor = id
    //         this.pdf.build()
    //     }
    //     return this.pdf
    // }

    //
    private lastSaveKeyKey = 'last-save-key'
    storage = {
        key: localStorage.getItem(this.lastSaveKeyKey) || `simu-${nanoid()}`,
        message: '',
        notif: false,
    }

    saveSimulationData = (notify: boolean = true) => {
        const dump = JSON.stringify(this.simu.toJSON())
        const key = this.storage.key
        localStorage.setItem(key, dump)
        localStorage.setItem(this.lastSaveKeyKey, key)
        if (notify) {
            this.storage.notif = true
            this.storage.message = 'Data successfully saved'
        }
    }

    requestNewSimulation = () => {
        this.storage.key = `simu-${nanoid()}`
        localStorage.setItem(this.lastSaveKeyKey, this.storage.key)
        window.location.reload()
    }
    /** save simulation as file */
    saveSimulationDataAsFile = () => {
        const dump = JSON.stringify(this.simu.toJSON())
        const name = `${this.storage.key}-${Date.now()}.json`
        const a = document.createElement('a')
        a.download = name
        a.href = URL.createObjectURL(new Blob([dump], { type: 'application/json' }))
        a.click()
    }

    loadSimulation = (simuData = this.loadSimulationData()) => {
        if (simuData == null) return
        this.simu = new Simulation(this, simuData)
    }

    loadSimulationData = (): SimulationData | null => {
        // console.log('this.storage.key', this.storage.key)
        // console.log('GET STORAGE', data)
        const data = localStorage.getItem(this.storage.key)
        if (data == null) return null
        const json: SimulationData = JSON.parse(data)
        const ver = json.SCHEMA_VERSION
        if (ver !== SCHEMA_VERSION_CONST) {
            console.log('wrong simulation version')
            return null
        }
        return json
    }
}
