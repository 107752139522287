import { icons } from '../components/iconUI'
import type { State } from '../state/state'

export type SimulationAction = {
    icon: JSX.Element | null
    label: string
    action: (st: State) => void
}
export const simulationActions: SimulationAction[] = [
    {
        icon: icons.SaveSimulation,
        label: 'Save to LocalStorage',
        action: (st: State) => st.saveSimulationData(),
    },
    {
        icon: icons.FileDownload,
        label: 'Save to File',
        action: (st: State) => st.saveSimulationDataAsFile(),
    },
    // {
    //     icon: icons.ClipBoardCopy,
    //     label: 'Copy Data to Clipboard',
    //     action: (st: State) => {
    //         const V = st.simu.Vertiport
    //         const rows = V.rows.filter((r) => !r.deleted)
    //         const json = JSON.stringify(rows)
    //         navigator.clipboard.writeText(json)
    //     },
    // },
    {
        icon: icons.Sync,
        label: 'Clean Vertiports + Regen Missions + Update Clock',
        action: (st: State) => st.regenMissions(),
    },
    {
        icon: icons.Delete,
        label: 'Clear All Fake Missions',
        action: (st: State) => st.clearMissions(),
    },
    {
        icon: icons.Operator,
        label: 'Add Pilgrim Operator + 2Drones + 2Pilots',
        action: (st: State) => st.addPilgrim(),
    },
    {
        icon: icons.Operator,
        label: `Pretend we don't know Pilgrim yet`,
        action: (st: State) => st.pretendNoPilgrim(),
    },
    {
        icon: icons.Repair,
        label: `Start from new clean simulation`,
        action: (st: State) => st.requestNewSimulation(),
    },
]
