import type { MissionStep, MissionStepAction } from '../core/mission'
import type { Timestamp } from '../state/clock'

import { ToggleButton, ToggleButtonGroup } from '@material-ui/core'
import { css } from '@material-ui/styled-engine'
import { observer } from 'mobx-react-lite'
import { CSSProperties } from 'react'

import { actionTypeIcon, flightDistanceFor, shortActivityName } from '../core/action'
import { renderDuration, renderHour } from '../utils/renderTime'
import { defaultColor, icons } from './iconUI'
import { formatDistance } from '../fakes/chunk'
import { useSt } from '../state/context'
import { cls } from '../utils/cls'

export const TimelineDirectionUI = observer(function TimelineDirectionUI_(p: {
    className?: string
}) {
    const st = useSt()
    return (
        <div className={cls('line2', p.className)}>
            <ToggleButtonGroup
                size="small"
                value={st.chronologicalTimeline}
                exclusive
                onChange={(_, val) => (st.chronologicalTimeline = val)}
            >
                <ToggleButton value={true} aria-label="chronological order">
                    {icons.Update}
                </ToggleButton>
                <ToggleButton value={false} aria-label="reverse-chronological order">
                    {icons.Restore}
                </ToggleButton>
            </ToggleButtonGroup>
            <div className="grow"></div>
            <span tw="text-sm text-gray-400">
                {st.chronologicalTimeline ? 'Chronological' : 'Reverse-Chronological'}
            </span>
        </div>
    )
})

export const MissionTimelineUI = observer(function MissionTimelineUI_(p: {
    mission: { steps: MissionStep[] }
    className?: string
    style?: CSSProperties
}) {
    const steps = p.mission.steps
    const st = useSt()
    const now = st.simu.clock.now
    return (
        <div
            className={p.className}
            style={p.style}
            // prettier-ignore
            css={css`
                /* background-color: #f5f5f5; */
                border-radius: 0.2rem;
                & .stepTitle { margin-top: 0.5rem; }
                & .stepDetail { margin-left: 0.5rem; max-width: 400px }
            `}
        >
            <TimelineDirectionUI />
            <div className={st.chronologicalTimeline ? '' : 'reverse-chronological'}>
                {steps.map((step, idx) => {
                    const as = step.actions
                    if (as.length === 0) return 'ERROR'
                    // const a0 = as[0]
                    // const a0k = a0.kind
                    return (
                        <div key={idx}>
                            {/* {a0k.type === 'fly' && (
                                <TimelineStepActionUI action={a0} at={now} />
                            )} */}
                            <div className="stepTitle line2">
                                <div
                                    style={{ color: defaultColor.Vertiport }}
                                    className="text-underline line3"
                                    tw="text-base cursor-pointer"
                                    onClick={() =>
                                        st.goTovertiportsListPage({
                                            focusId: step.vertiport.id,
                                        })
                                    }
                                >
                                    {icons.Vertiport} {step.vertiport.name}
                                </div>
                            </div>
                            <div className="stepDetail">
                                {step.actions.map((r, idx) => (
                                    <TimelineStepActionUI key={idx} at={now} action={r} />
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

export const TimelineStepActionUI = observer(function TimelineStepActionUI_(p: {
    action: MissionStepAction
    at: Timestamp
}) {
    const r = p.action
    const now = p.at
    return (
        <div className="line2">
            <span style={{ width: '90px' }} className="line2">
                {/* {r.statusIcon} */}
                {r.at + r.durationMS < now
                    ? icons.Success
                    : r.at < now
                    ? icons.HappeningNow
                    : icons.Scheduled}
                <span className="text-small">{renderHour(r.at)}</span>
            </span>
            <span
                style={{
                    borderLeft: '2px solid gray',
                    paddingLeft: '.3rem',
                }}
            >
                {actionTypeIcon(r.kind.type)}
            </span>
            <div>
                <p style={{ fontWeight: 500 }}>
                    {shortActivityName(r.kind.type)}
                    {r.kind.type === 'fly' &&
                        ` (${formatDistance(flightDistanceFor(r.kind))})`}
                </p>
            </div>
            <div tw="ml-auto text-gray-500">{renderDuration(r.durationMS)}</div>
        </div>
    )
})
