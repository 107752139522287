import type { CSSProperties } from 'react'

import TextField from '@material-ui/core/TextField'
import { css } from '@material-ui/styled-engine'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { actionTypeIcon, shortActivityName, editableKeys } from '../../core/action'
import { renderDuration, renderHour } from '../../utils/renderTime'
import { useSt } from '../../state/context'
import { FakeStep } from '../draftState'

import { ServiceRowUI } from './draftStepServicePicker'
import { DraftHeaderUI } from './draftHeaderUI'
import { AC } from './draftAC'
import { TimelineDirectionUI } from '../../components/missionTimelineUI'

export const DraftTimelineUI = observer(function DroneActionsUI_(p: {
    mission: { steps: FakeStep[] }
    className?: string
    style?: CSSProperties
}) {
    const [details, setDetails] = useState(() => false)
    const steps = p.mission.steps
    const st = useSt()
    const simu = st.simu
    const V = simu.Vertiport
    // console.log('coucou')
    return (
        <div
            className={p.className}
            style={p.style}
            /* prettier-ignore */
            css={css`
                border-radius: 0.2rem;
                & .stepTitle { margin-top: 0.3rem; }
                & .stepDetail { margin-left: 0.3rem; }
            `}
        >
            <TimelineDirectionUI tw="mb-8" />
            {steps.length > 0 ? (
                <DraftHeaderUI
                    // icons
                    label={details ? 'Hide Details' : 'Show Details'}
                    onClick={() => setDetails(!details)}
                />
            ) : null}
            <div className={st.chronologicalTimeline ? '' : 'reverse-chronological'}>
                {steps.map((fStep, idx) => {
                    const step = fStep.draftStep
                    return (
                        <div key={idx}>
                            <div className="stepTitle line2">
                                <div className="text-underline line3">
                                    <AC
                                        getName={(a) => a.pluscodeShort}
                                        variant="standard"
                                        rows={V.rows}
                                        getVal={() => step.vertiport}
                                        setVal={(rid) => (step.vertiport = V.get(rid))}
                                    />
                                    <ServiceRowUI step={step} />
                                </div>
                            </div>
                            {details && (
                                <div className="stepDetail">
                                    {fStep.actions.map((r, idx) => {
                                        const fields = editableKeys(r.kind)
                                        return (
                                            <div key={idx} className="line2">
                                                <span
                                                    style={{ width: '60px' }}
                                                    className="line2"
                                                >
                                                    <span className="text-small">
                                                        {renderHour(r.at)}
                                                    </span>
                                                </span>
                                                <span
                                                    style={{
                                                        borderLeft: '2px solid gray',
                                                        paddingLeft: '.3rem',
                                                    }}
                                                >
                                                    {actionTypeIcon(r.kind.type)}
                                                </span>
                                                <div style={{ fontWeight: 500 }}>
                                                    {shortActivityName(r.kind.type)}
                                                </div>
                                                {fields.map((f, ix) => (
                                                    <ServiceParamUI
                                                        key={ix}
                                                        kind={r.kind}
                                                        f={f}
                                                    />
                                                ))}
                                                {fields.length === 0 && (
                                                    <div tw="ml-auto mr-3 text-gray-500">
                                                        {renderDuration(r.durationMS)}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

const ServiceParamUI = observer(function ServiceParamUI_(p: {
    //
    kind: any
    f: string
}) {
    const { f, kind } = p
    return (
        <TextField
            label={f}
            type="number"
            size="small"
            onChange={(ev) => {
                const val = ev.target.value
                const num = parseInt(val, 10)
                if (typeof num !== 'number' || isNaN(num)) return
                kind[f] = num
            }}
            InputLabelProps={{ shrink: true }}
            sx={{ width: '6rem', ml: '1rem' }}
            value={kind[f]}
        />
    )
})
