import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { observer } from 'mobx-react-lite'
import { icons } from '../components/iconUI'
import { useSt } from '../state/context'

export const MapPanelUI = observer(function MapPanelUI_(p: {
    children: React.ReactNode
    close?: () => void
}) {
    const st = useSt()
    const small = st.isSmallScreen
    const closeFn = p.close
    return (
        <div
            className="sidePanel col"
            style={{
                zIndex: 1000,
                top: 0,
                bottom: 0,
                width: small ? 'auto' : '480px',
                maxWidth: small ? '100%' : '480px',
                position: 'relative',
                // position: small ? 'inherit' : 'absolute',
            }}
        >
            {closeFn && (
                <Paper
                    className="carte-panel"
                    elevation={2}
                    style={{
                        position: 'absolute',
                        left: small ? undefined : '100%',
                        bottom: small ? `100%` : undefined,
                        zIndex: 9999,
                        borderRadius: 0,
                        boxShadow: small
                            ? '5px -5px 5px -7px black'
                            : '5px 5px 5px -7px black',
                    }}
                >
                    {/* leave that div here, otherwise, it looks weird */}
                    <div>
                        <Button
                            size="small"
                            className="sidePanel-close-btn"
                            onClick={() => closeFn()}
                        >
                            {icons.Close}
                        </Button>
                    </div>
                </Paper>
            )}
            <Paper
                className="carte-panel"
                elevation={2}
                style={{
                    borderRadius: 0,
                    position: small ? undefined : 'absolute',
                    top: small ? undefined : 0,
                    bottom: small ? undefined : 0,
                    left: small ? undefined : 0,
                    right: small ? undefined : 0,
                }}
                sx={{
                    height: '100%',
                    overflow: 'auto',
                    padding: '.8rem',
                    inset: 0,
                }}
            >
                {/*  */}
                <div tw="inset-0 relative min-height[100%] flex flex-col">
                    {p.children}
                </div>
                {/*  */}
            </Paper>
        </div>
    )
})
