import type { Vertiport } from '../core/vertiport'
import type { Timestamp } from '../state/clock'
import type { Action } from '../core/action'

import { autorun, computed, makeObservable } from 'mobx'

import { randomRange, range } from '../utils/range'
import { defaultDuration } from '../core/action'
import { beginningOfDay, sameTimeTodayV1 } from '../state/clock'
import { choose } from '../fakes/utils'

export type Booking = {
    start: Timestamp
    end: Timestamp
    bay: number
    action?: Action
    // humanIntervention?: boolean
}

export const H = 3_600_000
export const slotDuration = 600_000
export const slotHeight = 18 // px par 10min
export const timeScale = slotHeight / slotDuration

export class VertiportStatus {
    bayWidth = 18
    slots: string[]

    T0: Timestamp = beginningOfDay()

    fakeLandings: Booking[] = []
    fakeChargings: Booking[] = []
    fakeParkings: Booking[] = []
    fakeRepairs: Booking[] = []

    landings: Booking[] = []
    chargings: Booking[] = []
    parkings: Booking[] = []
    repairs: Booking[] = []

    get realBookings(): {
        landings: Booking[]
        chargings: Booking[]
        parkings: Booking[]
        repairs: Booking[]
    } {
        const landings: Booking[] = []
        const chargings: Booking[] = []
        const parkings: Booking[] = []
        const repairs: Booking[] = []
        this.vertiport.activity.forEach((action) => {
            const start = sameTimeTodayV1(action.at)
            const end = start + action.durationMS
            const bay = 0
            const av = action.activity
            if (av === 'land') landings.push({ start, end, action, bay })
            else if (av === 'takeoff') landings.push({ start, end, action, bay })
            else if (av === 'rechargeBattery') chargings.push({ start, end, action, bay })
            else if (av === 'repair') repairs.push({ start, end, action, bay })
            else if (av === 'park') parkings.push({ start, end, action, bay })
            // else if (a.activity==='park')
        })
        return { landings, chargings, parkings, repairs }
    }

    // ===============================================================

    get curLanding(): number {
        const now = this.vertiport.simulation.clock.now
        const curr = this.landings.filter((a) => a.start <= now && a.end >= now)
        if (curr.length === 0) return 0
        return Math.max(...curr.map((x) => x.bay)) + 1
    }
    get curChargings(): number {
        const now = this.vertiport.simulation.clock.now
        const curr = this.chargings.filter((a) => a.start <= now && a.end >= now)
        if (curr.length === 0) return 0
        return Math.max(...curr.map((x) => x.bay)) + 1
    }
    get curParkings(): number {
        const now = this.vertiport.simulation.clock.now
        const curr = this.parkings.filter((a) => a.start <= now && a.end >= now)
        if (curr.length === 0) return 0
        return Math.max(...curr.map((x) => x.bay)) + 1
    }
    get curRepairs(): number {
        const now = this.vertiport.simulation.clock.now
        const curr = this.repairs.filter((a) => a.start <= now && a.end >= now)
        if (curr.length === 0) return 0
        return Math.max(...curr.map((x) => x.bay)) + 1
    }

    // ===============================================================

    get maxSimultLanding(): number {
        if (this.landings.length === 0) return 0
        return Math.max(...this.landings.map((x) => x.bay)) + 1
    }
    get maxSimultChargings(): number {
        if (this.chargings.length === 0) return 0
        return Math.max(...this.chargings.map((x) => x.bay)) + 1
    }
    get maxSimultParkings(): number {
        if (this.parkings.length === 0) return 0
        return Math.max(...this.parkings.map((x) => x.bay)) + 1
    }
    get maxSimultRepairs(): number {
        if (this.repairs.length === 0) return 0
        return Math.max(...this.repairs.map((x) => x.bay)) + 1
    }

    // ===============================================================

    get landingsColWidth(): string {
        const val = this.bayWidth * this.maxSimultChargings
        return `${Math.max(100, val)}px`
    }
    get chargingsColWidth(): string {
        const val = this.bayWidth * this.maxSimultChargings
        return `${Math.max(100, val)}px`
    }
    get parkingsColWidth(): string {
        const val = this.bayWidth * this.maxSimultParkings
        return `${Math.max(100, val)}px`
    }
    get repairsColWidth(): string {
        const val = this.bayWidth * this.maxSimultRepairs
        return `${Math.max(100, val)}px`
    }
    // chargingBayOccupancy: number
    // parkingBayOccupancy: number
    // repairBayOccupancy: number

    // ===============================================================

    constructor(
        //
        public vertiport: Vertiport,
        fakeDATAAmount: number = 30,
    ) {
        console.log('new VertiportStatus()..')
        makeObservable(this, { realBookings: computed })
        this.slots = this.makeSlots()

        // A. fake DATA
        for (let i = 0; i < fakeDATAAmount; i++) {
            const recharge = Math.random() > 0.5
            const repair = Math.random() > 0.98
            const park = Math.random() > 0.9
            if (!recharge && !repair && !park) continue
            let start: Timestamp = this.T0 + (randomRange(8 * 6, 20 * 6) / 6) * H

            let end: Timestamp = start + defaultDuration('land')
            this.fakeLandings.push({ start, end, bay: 0 })
            start = end

            if (recharge) {
                let end: Timestamp = start + defaultDuration('rechargeBattery')
                this.fakeChargings.push({ start, end, bay: 0 })
                start = end
            }
            if (repair) {
                let end: Timestamp = start + defaultDuration('repair')
                this.fakeRepairs.push({ start, end, bay: 0 })
                start = end
            }
            if (park) {
                let end: Timestamp = start + defaultDuration('park')
                this.fakeParkings.push({ start, end, bay: 0 })
                start = end
            } else {
                let end: Timestamp = start + defaultDuration('land')
                this.fakeLandings.push({ start, end, bay: 0 })
                start = end
            }
        }

        // B add real data
        autorun(() => {
            const rd = this.realBookings
            // console.log('injecting real data', this.realBookings)
            this.landings = [...rd.landings, ...this.fakeLandings]
            this.optimizeBayUsage(this.landings, vertiport.nbLandingBay)

            this.chargings = [...rd.chargings, ...this.fakeChargings]
            this.optimizeBayUsage(this.chargings, vertiport.nbChargingBay)

            this.repairs = [...rd.repairs, ...this.fakeRepairs]
            this.optimizeBayUsage(this.repairs, vertiport.nbRepairBay)

            this.parkings = [...rd.parkings, ...this.fakeParkings]
            this.optimizeBayUsage(this.parkings, vertiport.nbParkingBay)
        })
    }

    makeSlots = (): string[] => {
        const out = []
        for (let h = 0; h < 24; h++)
            for (let m = 0; m < 60; m += 10) {
                const mStr = m < 10 ? `0${m}` : m
                const hStr = h < 10 ? `0${h}` : h
                out.push(`${hStr}:${mStr}`)
            }
        return out
    }

    private optimizeBayUsage = (bookings: Booking[], NB_BAY: number) => {
        bookings.forEach((bk, i, arr) => {
            const freeBay = new Array(NB_BAY).fill(true)
            for (let j = 0; j < i; j++) {
                const prev = arr[j]
                if (prev.end <= bk.start) continue
                if (prev.start >= bk.end) continue
                freeBay[prev.bay] = false
            }
            let bay: number | null = freeBay.findIndex((b) => b)
            if (bay === -1) {
                console.log('placement failure')
            } else {
                bk.bay = bay
            }
        })
    }

    randomInt = (start: number, length: number) => {
        return choose(range(start, length))
    }
}
