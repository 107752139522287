import type { ActionData, ActionKind } from './action'
import type { Simulation } from '../state/simulation'
import type { Vertiport } from './vertiport'
import type { LatLngTuple, LngLatTuple } from './point'
import type { Operator } from './operator'
import type { Asserts } from 'yup'
import type { Weight_kg } from './units'
import type { Pilot } from './pilot'

import { computed, makeObservable, toJS } from 'mobx'

import { mkTrace, GeoJSONPath } from '../carte/trace'
import { renderDuration, renderTime } from '../utils/renderTime'

import { renderFullMonth } from '../utils/fullMonth'
import { Action, defaultDuration } from './action'
import { Drone, flightDurationMs } from './drone'
import { Model, RID, s } from './schema'
import { renderDatetimeISO, Timestamp } from '../state/clock'
import { formatDistance } from '../fakes/chunk'

/** As in DragonFly Mission: stuff drone owners will request
 * dragonfly to do, in exchange for money. */
export const MissionSchema = s.object({
    id: s._ID,
    name: s.str,
    type: s.str,
    actionIds: s._IDS, // 🔄 denormalized data
    droneId: s._ID,
    pilotId: s._ID,
    startAt: s.num,
    endAt: s.num,
    fake: s.bool,
})

export type MissionData = Asserts<typeof MissionSchema>
export type MissionStatus = 'scheduled' | 'current' | 'done'
export type MissionStepAction = {
    vertiportId: number
    durationMS: number
    kind: ActionKind
    at: number
    /** force status */
    status?: MissionStatus
}
export type MissionStep = {
    vertiport: Vertiport
    actions: MissionStepAction[]
}

export interface Mission extends MissionData {}
export class Mission extends Model<typeof MissionSchema> {
    constructor(simulation: Simulation, data: MissionData) {
        super(simulation, MissionSchema, data, 'Mission')
        makeObservable(this, {
            ...this.observability,
            actions: computed,
            drone: computed,
            trace: computed,
            status: computed,
            operatorName: computed,
            pilot: computed,
            steps: computed,
        })
    }

    get pilot():Pilot{return this.simulation.Pilot.get(this.pilotId)} // prettier-ignore
    get actions(): Action[] { return this.simulation.Action.getMany(this.actionIds) } // prettier-ignore
    get drone(): Drone { return this.simulation.getDrone(this.droneId) } // prettier-ignore
    get operatorName(): string { return this.drone.operatorName } // prettier-ignore
    get startAtStr():string { return renderTime(this.startAt) } // prettier-ignore
    get endAtStr():string { return renderTime(this.endAt) } // prettier-ignore
    // get startAt(): number { return this.actions[0].at } // prettier-ignore
    // get endAt(): number { return this.actions[this.actions.length - 1].at } // prettier-ignore

    get operator(): Operator {
        return this.simulation.Operator.get(this.drone.operatorId)
    }
    get fullMonth() {
        return renderFullMonth(this.startAt)
    }
    get steps(): MissionStep[] {
        let currStep: MissionStep | null = null
        let steps: MissionStep[] = []
        const actions = this.actions
        for (let a of actions) {
            if (currStep == null || a.vertiportId !== currStep.vertiport.id) {
                currStep = { vertiport: a.vertiport, actions: [a] }
                steps.push(currStep)
            } else {
                currStep.actions.push(a)
            }
        }
        return steps
    }

    get status(): 'planned' | 'completed' | 'active' {
        if (this.startAt > this.simulation.clock.now) return 'planned'
        if (this.endAt < this.simulation.clock.now) return 'completed'
        return 'active'
    }

    // get tracev2(): { prev: GeoJSONPath; next: GeoJSONPath } {
    //     const poss: any[] = []
    //     let prev: Vertiport | undefined
    //     let vertiport: Vertiport

    //     for (let a of this.actions) {
    //         vertiport = a.vertiport
    //         if (vertiport === prev) continue
    //         if (a.at > this.simulation.clock.time) vertiport = a.vertiport
    //         poss.push(vertiport.lon, vertiport.lat] as const)
    //     }
    //     // return mkTrace(poss)
    //     return { prev, next }
    // }

    get traceSplitted(): {
        past: GeoJSONPath
        future: GeoJSONPath
    } {
        let vertiport: Vertiport
        let now = this.simulation.clock.now
        let prev: Vertiport | null
        let i = 0

        const currAction = this.drone.currentAction

        // 1. past points
        const possPast: LngLatTuple[] = []
        prev = null
        for (; i < this.actions.length; i++) {
            const a = this.actions[i]
            if (a === currAction) break
            vertiport = a.vertiport
            if (vertiport !== prev) {
                const pos: LngLatTuple = [vertiport.lon, vertiport.lat]
                possPast.push(pos)
            }
        }

        // 2. future points
        const possFuture: LngLatTuple[] = []
        prev = null
        for (; i < this.actions.length; i++) {
            const a = this.actions[i]
            vertiport = a.vertiport
            if (vertiport === prev) continue
            const pos: LngLatTuple = [vertiport.lon, vertiport.lat]
            possFuture.push(pos)
        }

        // Middle point
        const currPos = this.drone.currentLatLng
        if (currPos != null && now > this.startAt && now < this.endAt) {
            const latLng: LatLngTuple = currPos
            const lngLat: LngLatTuple = [latLng[1], latLng[0]]
            possPast.push(lngLat)
            possFuture.unshift(lngLat)
        }
        console.log('past', toJS(possPast))
        console.log('futur', toJS(possFuture))
        return {
            past: mkTrace(possPast),
            future: mkTrace(possFuture),
        }
    }

    get trace(): GeoJSONPath {
        const poss: any[] = []
        let prev: Vertiport | undefined
        let vertiport: Vertiport
        for (let a of this.actions) {
            vertiport = a.vertiport
            if (vertiport === prev) continue
            poss.push([vertiport.lon, vertiport.lat] as const)
        }
        return mkTrace(poss)
    }

    takeoff = (vertiport: Vertiport, at: number): Action => {
        const kind: ActionKind = { type: 'takeoff' }
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const vertiportId: RID = vertiport.id
        const durationMS: number = defaultDuration(kind.type)
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
    }

    land = (vertiport: Vertiport, at: number): Action => {
        const kind: ActionKind = { type: 'land' }
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const vertiportId: RID = vertiport.id
        const durationMS: number = defaultDuration(kind.type)
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
    }
    park = (vertiport: Vertiport, at: number): Action => {
        const kind: ActionKind = { type: 'park' }
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const vertiportId: RID = vertiport.id
        const durationMS: number = defaultDuration(kind.type)
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
    }
    flyBy = (vertiport: Vertiport, at: number) => {
        const kind: ActionKind = { type: 'flyBy' }
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const vertiportId: RID = vertiport.id
        const durationMS: number = defaultDuration(kind.type)
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
    }

    charge = (vertiportId: RID, at: number) => {
        const kind: ActionKind = { type: 'rechargeBattery', nbKW: 4 }
        return this._action(kind, at, vertiportId)
    }

    load = (vertiportId: RID, at: number, nbKilo: Weight_kg) => {
        const kind: ActionKind = { type: 'load', nbKilo }
        return this._action(kind, at, vertiportId)
    }

    unload = (vertiportId: RID, at: number) => {
        const kind: ActionKind = { type: 'unload', nbKilo: 0 }
        return this._action(kind, at, vertiportId)
    }

    fakeName = () => {
        const t = renderDatetimeISO(this.startAt)
        const a0 = this.actions[0]
        const an = this.actions[this.actions.length - 1]
        const pc0 = a0.vertiport.pluscodeShort
        const pcn = an.vertiport.pluscodeShort
        return `${t} ${this.operator.name} ${this.pilot.name} ${this.drone.name} ${pc0} ${pcn}`
    }

    /** total duration except for services : parking */
    get beforeParkingDuration() {
        // let realActions = []
        return this.actions
            .filter((a) => a.kind.type !== 'park')
            .reduceRight((p, c) => p + c.durationMS, 0)
    }

    /** total flight duration (time spend in the air) */
    get flightDuration() {
        return this.actions.reduce((p, a) => p + a.flightDurationMS, 0)
    }

    get durationText() { return renderDuration(this.endAt - this.startAt) } // prettier-ignore
    get beforeParkingdurationText() { return renderDuration(this.beforeParkingDuration) } // prettier-ignore
    get flightDurationText() { return renderDuration(this.flightDuration) } // prettier-ignore

    /** total flight distance in meters */
    get totalDistance() {
        return this.actions.reduce((p, c) => p + c.distance, 0)
    }

    /** total flight distance in meters, formatted for printing,
     * includes unit suffix */
    get totalDistanceText() {
        return formatDistance(this.totalDistance)
    }

    private _action = (kind: ActionKind, at: Timestamp, vertiportId: RID): Action => {
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const durationMS: number = defaultDuration(kind.type)
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
    }

    flyTo = (at: number, from: Vertiport, to: Vertiport): Action => {
        const meters = from.distanceTo(to)
        const durationMS = flightDurationMs(meters)
        const id: RID = this.simulation.getRID()
        const missionId: RID = this.id
        const vertiportId = to.id
        const kind: ActionKind = {
            type: 'fly',
            lat0: from.lat,
            lon0: from.lon,
            lat1: to.lat,
            lon1: to.lon,
        }
        const d: ActionData = { id, kind, at, missionId, vertiportId, durationMS }
        const action = new Action(this.simulation, d)
        this.actionIds.push(action.id)
        return action
        // this.simulation.actions.push(action)
    }
}

export const missionTypeHue = (str: string): number => {
    if (str === 'surveillance') return 30
    if (str === 'advanced') return 70
    return 0
}

export const missionStatusHue = (str: string): number => {
    if (str === 'planned') return 56
    if (str === 'completed') return 120
    if (str === 'active') return 207
    return 0
}
