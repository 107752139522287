// https://en.wikipedia.org/wiki/A*_search_algorithm#Pseudocode
// https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm

import { Vertiport } from '../core/vertiport'

export function dijkstra(
    vertiports: Vertiport[],
    source: Vertiport,
    target: Vertiport,
): Vertiport[] {
    const Q = new Set(vertiports)
    const dist = new Map<Vertiport, number>()
    const prev = new Map<Vertiport, Vertiport | null>()
    for (let v of vertiports) {
        dist.set(v, 999_999_999) //Infinity)
        prev.set(v, null)
    }
    dist.set(source, 0)
    // console.log('source:', source.id)
    while (Q.size > 0) {
        let u: Vertiport = Array.from(Q.values()).sort(
            (a, b) => dist.get(a)! - dist.get(b)!,
        )[0]
        // console.log(u.id)

        Q.delete(u)

        if (u === target) {
            const S: Vertiport[] = [] // result
            let u: Vertiport | null | undefined = target
            while (u) {
                S.push(u)
                u = prev.get(u)
            }
            return S.reverse()
        }

        for (let v of u.connections) {
            // console.log('>> a', v.id)
            if (!Q.has(v)) continue //of u still in Q:
            const alt = dist.get(u)! + u.distanceTo(v)
            // console.log('>> b', alt)
            if (alt < dist.get(v)!) {
                // console.log('>> c', dist.get(v))
                dist.set(v, alt)
                // console.log(`${v.id}->${u.id}`)
                prev.set(v, u)
            }
        }
    }

    return []
}
