import { observer } from 'mobx-react-lite'
//
import { MissionTimelineUI } from '../components/missionTimelineUI'
import { BatteryIndicator } from '../components/batteryIndicator'
import { icons } from '../components/iconUI'
import { useSt } from '../state/context'

import { MapPanelUI } from './panelCSS'
import { Pilot } from '../core/pilot'
import { MissionKPIUI } from '../pages/missionPage'

export const CartePanelDroneUI = observer(function CartePanelDroneUI_(p: {}) {
    const st = useSt()
    const drone = st.selectedDrone
    if (drone == null) return null
    const activeMission = drone.currentMission
    const pilot = activeMission?.pilot
    return (
        <MapPanelUI close={() => (st.selectedDrone = null)}>
            <div tw="flex items-baseline justify-between my-2">
                <h2
                    className="line2 pointer"
                    onClick={() => st.goTodronesListPage({ focusId: drone.id })}
                >
                    {icons.Drone} {drone.name}
                </h2>
                <BatteryIndicator drone={drone} />
            </div>
            <PilotInfosUI pilot={pilot} />
            {activeMission && (
                <section>
                    <h3>Current Mission</h3>
                    <MissionKPIUI mission={activeMission} />
                    <h3>Timeline</h3>
                    <MissionTimelineUI mission={activeMission} />
                </section>
            )}
        </MapPanelUI>
    )
})

const PilotInfosUI = observer(function PilotInfosUI_(p: { pilot?: Pilot | null }) {
    const pilot = p.pilot
    const st = useSt()
    if (pilot == null) return null
    const phone = pilot.phoneClean
    return (
        <div tw="mt-2">
            <span className="propertyName">Pilot</span>
            {/* eslint-disable-next-line */}
            <a
                href="#"
                onClick={(ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    st.goTopilotsListPage({ focusId: pilot.id })
                }}
            >
                {pilot.name}
            </a>{' '}
            <a tw="font-size[.8em]" href={`tel:${phone}`}>
                ({phone})
            </a>
        </div>
    )
})
