import { observer } from 'mobx-react-lite'
import LogoSource from '../assets/logo.png'
import { formatMoney } from '../fakes/chunk'
import { useSt } from '../state/context'
import { cls } from '../utils/cls'

import './invoice2.css'

// const fooOnClick = async () => {
//     // @ts-ignore
//     const jsPDF = await import('https://cdn.skypack.dev/jspdf')
//     // print button
//     var doc = new jsPDF()
//     // var specialElementHandlers = {
//     //     '#editor': function (element, renderer) {
//     //         return true
//     //     },
//     // }
//     const elem = document.getElementById('invoice-box')
//     if (elem == null) return
//     const html = elem?.innerHTML
//     // $('#cmd').click(function () {
//     doc.fromHTML(html, 15, 15, {
//         width: 170,
//         // elementHandlers: specialElementHandlers,
//     })
//     doc.save('sample-file.pdf')
//     // })
// }

export const InvoiceUI2 = observer(function InvoiceUI2_(p: {
    //
    month: string
    operatorId: number
}) {
    const st = useSt()
    const operator = st.simu.Operator.get(p.operatorId)
    const actions = st.simu.Action.rows.filter(
        (r) =>
            r.month === p.month && //
            r.operator.id === p.operatorId,
    )
    const sum = actions.reduce((prev, curr) => prev + curr.price, 0)
    const lastActionIx = actions.length - 1

    return (
        <div className="invoice-box" id="invoice-box">
            {/* <button onClick={fooOnClick}>PRINT</button> */}
            <table cellPadding={0} cellSpacing="0">
                <tbody>
                    <tr className="top">
                        <td colSpan={2}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="title">
                                            <img
                                                src={LogoSource}
                                                alt="logo"
                                                tw="cursor-pointer max-width[6rem] mx-auto"
                                            />
                                            {/* <img
                                                alt="logo"
                                                src="https://www.sparksuite.com/images/logo.png"
                                                style={{ width: '100%', maxWidth: '300px' }}
                                            /> */}
                                        </td>
                                        <td>
                                            Invoice #: {p.month}_{operator.id}
                                            <br />
                                            Created: {p.month}-01
                                            <br />
                                            Due: {p.month}-31
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                <tr className="information">
                    <td colSpan={2}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        DragonFlyPads SAS.
                                        <br />
                                        67 Square de la Couronne
                                        <br />
                                        Paris
                                        {/* Sunnyville, CA 12345 */}
                                    </td>
                                    <td>
                                        {operator.name}
                                        <br />
                                        {operator.address}
                                        {/* {operator.com}
                                        <br />
                                        John Doe
                                        <br />
                                        john@example.com */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr className="heading">
                    <td>Payment Method</td>
                    <td>Check #</td>
                </tr>
                <tr className="details">
                    <td>Check</td>
                    <td>{formatMoney(sum)}</td>
                </tr>
                <tr className="heading">
                    <td>Item</td>
                    <td>Price</td>
                </tr>
                {actions.map((a, ix) => {
                    return (
                        <tr
                            key={a.id}
                            className={cls(
                                'item',
                                ix === lastActionIx ? 'last' : undefined,
                            )}
                        >
                            <td>{a.activity}</td>
                            <td>{a.priceFormatted}</td>
                        </tr>
                    )
                })}

                <tr className="total">
                    <td></td>
                    <td>Total: {formatMoney(sum)}</td>
                </tr>
            </table>
        </div>
    )
})
