import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Input } from '@material-ui/core'
import type { Page } from '../layout/pages'

export const AdminPage = (): Page => ({
    id: 'admin' as const,
    icon: 'Db' as const,
    label: 'Admin',
    path: '/admin',
    Component: AdminPageUI,
})

export const AdminPageUI = observer(() => {
    const st = useSt()
    const [pretty, setPretty] = useState(false)
    return (
        <div tw="px-4 py-8">
            <div tw="mb-4">
                <Button variant="outlined" onClick={() => st.saveSimulationData()}>
                    Save
                </Button>
                <Button variant="outlined" tw="mx-4" onClick={() => st.loadSimulation()}>
                    Load
                </Button>
                <Input
                    defaultValue={st.storage.key}
                    type="text"
                    onChange={(e) => (st.storage.key = e.target.value)}
                />
            </div>
            <Typography variant="h6" color="initial">
                Simulation Database
            </Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={pretty}
                        onChange={(ev) => setPretty(ev.target.checked)}
                        inputProps={{ 'aria-label': '' }}
                    />
                }
                label="Indent JSON"
            />

            <code>
                <pre>
                    {JSON.stringify(
                        //
                        st.simu.toJSON(),
                        null,
                        pretty ? 4 : undefined,
                    )}
                </pre>
            </code>
        </div>
    )
})
