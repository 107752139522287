import type { Simulation } from '../state/simulation'
import type { Asserts } from 'yup'
import { Model, s } from './schema'

export const AgentSchema = s.object({
    id: s._ID,
    name: s.str,
    phone: s.str,
})

export type AgentData = Asserts<typeof AgentSchema>

export interface Agent extends AgentData {}
export class Agent extends Model<typeof AgentSchema> {
    constructor(simulation: Simulation, data: AgentData) {
        super(simulation, AgentSchema, data, 'Agent')
    }
}
