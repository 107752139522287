import type { Pilot } from '../core/pilot'
import type { State } from '../state/state'
import { observer } from 'mobx-react-lite'

import { Stack, Button, Paper, TextField } from '@material-ui/core'
import { css } from '@material-ui/styled-engine'
import { makeAutoObservable } from 'mobx'
import { useMemo } from 'react'

import { icons } from '../components/iconUI'
import { useSt } from '../state/context'

class OperatorDraft {
    constructor(public st: State) {
        makeAutoObservable(this)
    }
    pilots = [{ name: '', phone: '', certification: '' }]
    operator = { name: '', phone: '', tradeName: '', siret: '', formation: '', address: '' } // prettier-ignore
    save = () => {
        if (this.pilots.length === 0) return console.error('not enough pilot')
        const simu = this.st.simu
        const operator = simu.addOperator({ ...this.operator, fake: false })
        const pilots: Pilot[] = this.pilots.map((p_) =>
            simu.addPilot({ ...p_, fake: false, operatorId: operator.id }),
        )
        operator.pilotIds = pilots.map((p) => p.id)

        for (let d_ of this.drones) {
            const d = simu.addDrone({
                ...d_,
                // pilotId: ps.map((p) => p.id),
                operatorId: operator.id,
                fake: false,
            })
            operator.droneIds.push(d.id)
        }
        // console.log(`p:`, p)
        // console.log(`o:`, operator)
        // console.log(`o.pilots:`, operator.pilots)
        // console.log(`o.drones:`, operator.drones)
        // console.log(st.simu.Operator.get_(operator.id))
        this.st.goTooperatorPage({ operatorId: operator.id })
    }
    drones: { name: string; brand: string; weight: number }[] = [
        { name: '', brand: '', weight: 5 },
    ]
    delDrone = (atIx: number) => this.drones.splice(atIx, 1)
    addDrone = () => this.drones.push({ brand: '', name: '', weight: 10 })
    delPilot = (atIx: number) => this.pilots.splice(atIx, 1)
    addPilot = () => this.pilots.push({ name: '', certification: '', phone: '' })
    toJSON = () => ({ pilot: this.pilots, operator: this.operator, drones: this.drones }) // prettier-ignore
}

export const AddOperatorUI = observer(function AddOperatorUI_() {
    const st = useSt()
    const x = useMemo(() => new OperatorDraft(st), [st])
    return (
        <div
            className="col"
            css={css`
                & > .row > .col {
                    margin-right: 0.5rem;
                }
            `}
        >
            <div className="row wrap">
                <div className="col">
                    <TextField
                        label="quick-fill"
                        type="text"
                        defaultValue=""
                        onChange={(ev) => {
                            const v = ev.target.value
                            for (let p of x.pilots) {
                                p.certification = v
                                p.name = v
                                p.phone = v
                            }
                            for (let p of x.drones) {
                                p.name = v
                                p.brand = v
                            }
                            const o = x.operator
                            o.name = v
                            o.phone = v
                            o.tradeName = v
                            o.siret = v
                            o.formation = v
                            o.address = v
                        }}
                    />
                    <h3>Operator</h3>
                    <FieldUI owner={x.operator} name="name" label="Name" />
                    <FieldUI owner={x.operator} name="phone" label="Phone" />
                    <FieldUI owner={x.operator} name="tradeName" label="TradeName" />
                    <FieldUI owner={x.operator} name="siret" label="Siret" />
                    <FieldUI owner={x.operator} name="formation" label="Formation" />
                    <FieldUI owner={x.operator} name="address" label="Address" />
                </div>
                <div className="col">
                    <h3>
                        Pilot
                        <Button onClick={() => x.addPilot()}>{icons.Add}</Button>
                    </h3>
                    <Stack spacing={1}>
                        {x.pilots.map((p, ix) => (
                            <Paper elevation={0} key={ix}>
                                {/* <Button onClick={() => x.delDrone(ix)}>{icons.Delete}</Button>
                            <FieldUI owner={d} name="name" label="Name" />
                            <FieldUI owner={d} name="brand" label="Brand" />
                            <FieldUI owner={d} name="weight" label="Weight (kg)" /> */}
                                <FieldUI owner={p} name="name" label="Name" />
                                <FieldUI owner={p} name="phone" label="Phone" />
                                <FieldUI
                                    owner={p}
                                    name="certification"
                                    label="Certification"
                                />
                            </Paper>
                        ))}
                    </Stack>
                </div>
                <div className="col">
                    <h3>
                        Drone
                        <Button onClick={() => x.addDrone()}>{icons.Add}</Button>
                    </h3>
                    <Stack spacing={1}>
                        {x.drones.map((d, ix) => (
                            <Paper elevation={0} key={ix}>
                                <Button onClick={() => x.delDrone(ix)}>
                                    {icons.Delete}
                                </Button>
                                <FieldUI owner={d} name="name" label="Name" />
                                <FieldUI owner={d} name="brand" label="Brand" />
                                <FieldUI owner={d} name="weight" label="Weight (kg)" />
                            </Paper>
                        ))}
                    </Stack>
                </div>
            </div>
            <div>
                <Button onClick={() => x.save()} variant="contained">
                    Create
                </Button>
                <pre>{JSON.stringify(x, null, 4)}</pre>
            </div>
        </div>
    )
})

export const FieldUI = observer(function FieldUI_<T extends Object>(p: {
    owner: T
    name: keyof T
    label: string
}) {
    const val = p.owner[p.name]
    const tpe = typeof val === 'string' ? 'text' : 'number'
    return (
        <TextField
            value={val}
            onChange={(ev) => {
                let val: any = ev.target.value
                if (tpe === 'number') val = parseFloat(val)
                ;(p.owner as any)[p.name] = val
            }}
            type={tpe}
            label={p.label}
            variant="outlined"
        />
    )
})
