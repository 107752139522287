import { CSSProperties, Fragment, useMemo, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import qrcode from 'qrcode-generator-es6'

import { colorLogoL } from '../assets/logo'
import { cls } from '../utils/cls'

// const col1=4ba25bff
const mkQRCode = (encodedString: string) => {
    const qr = new qrcode(0, 'H')

    qr.addData(encodedString)
    qr.make()
    return qr.createSvgTag({
        margin: 0,
        // obstruction: {
        //     height: 0.2,
        //     width: 0.2,
        //     path: logo.svg,
        // },

        // ~~~ rainbow ~~~
        cellColor: (c) => colorLogoL,

        // ~~~ rainbow ~~~
        // cellColor: (c, r) =>
        //     'hsl(' +
        //     (((c % 15) * 360) / 15).toFixed(3) +
        //     ', 100%, ' +
        //     (((r % 15) * 100) / 15).toFixed(3) +
        //     '%)',

        // ~~~ unicolor ~~~
        // cellColor: () => 'red',
    })
    // my_element.innerHTML = qr.createSvgTag({})
}
export const QRCodeUI = observer(function QRCodeUI_(p: {
    style?: CSSProperties
    className?: string
    encodedString: string
}) {
    const [open, setOpen] = useState(() => false)
    const qrcode = useMemo(() => mkQRCode(p.encodedString), [p.encodedString])
    return (
        <Fragment>
            <Dialog
                maxWidth={false}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <DialogContent>
                    <div
                        onClick={() => setOpen(true)}
                        className={p.className}
                        style={{
                            width: '800px',
                            height: '800px',
                        }}
                        dangerouslySetInnerHTML={{ __html: qrcode }}
                    ></div>
                </DialogContent>
            </Dialog>
            <div
                onClick={() => setOpen(true)}
                className={cls('pointer', p.className)}
                style={p.style}
                dangerouslySetInnerHTML={{ __html: qrcode }}
            ></div>
        </Fragment>
    )
})

// https://www.npmjs.com/package/qrcode-generator-es6#qrcode
