export const formatMoney = (amount: number): string => {
    const sumStr = amount.toFixed(2)
    const [int, dec] = sumStr.split('.')
    return `${spaceBy3(int)}.${dec}€`
}

export const formatDistance = (meters: number): string => {
    if (meters > 1000) return `${(meters / 1000).toFixed(2)}km`
    return spaceBy3(Math.round(meters).toString()) + 'm'
}

const spaceBy3 = (str: string) => {
    let out = ''
    let offset = str.length % 3
    for (let i = 0; i < str.length; i++) {
        if (offset === 0) {
            out += ' '
            offset = 3
        }
        offset--
        out += str[i]
    }
    return out
}
