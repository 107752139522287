import TextField from '@material-ui/core/TextField'
import TimePicker from '@material-ui/lab/TimePicker'
import { observer } from 'mobx-react-lite'
import { useDraft } from '../draftContext'

export const TP = observer(function TP_() {
    const draftState = useDraft()
    console.log('draftState', draftState.startAt)
    return (
        <TimePicker
            className="dfp-time-picker"
            ampm={false}
            value={draftState.startAt}
            onChange={(newStartAt: any) => {
                if (newStartAt == null) return
                console.log('newStartAt', newStartAt)
                const nextTz =
                    newStartAt instanceof Date //
                        ? newStartAt.getTime()
                        : newStartAt
                draftState.setStartAt(nextTz)
            }}
            renderInput={(params) => (
                <TextField
                    className="RVTimeTextField"
                    size="small"
                    variant="standard"
                    style={{ width: '6rem' }}
                    {...params}
                />
            )}
        />
    )
})
