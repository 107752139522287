import { autorun, makeAutoObservable } from 'mobx'
import type { State } from './state'

export class AuthManager {
    username: string = ''
    password: string = ''

    loginError: string = ''
    isAuthenticated: boolean = false

    login = () => {
        if (!this.areCredentialsValid()) this.loginError = 'wrong !'
        else this.isAuthenticated = true
    }
    logout = () => {
        this.password = ''
        this.isAuthenticated = false
    }
    constructor(public state: State) {
        makeAutoObservable(this)
        this.load('username')
        this.load('password')
        if (this.areCredentialsValid()) this.login()
        this.autosave('username')
        this.autosave('password')
    }
    private areCredentialsValid = () =>
        this.username === 'dragonflypads' && //
        this.password === '8xVPc48u'

    private load = (key: 'username' | 'password') => {
        const val = localStorage.getItem(key)
        if (typeof val === 'string') this[key] = val
    }
    private autosave = (key: 'username' | 'password') => {
        autorun(() => localStorage.setItem(key, this[key]))
    }
}
