import { Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite'

import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Popover from '@material-ui/core/Popover'

import { useSt } from '../state/context'
import { icons } from '../components/iconUI'

export const CartePanelTimeUI = observer(function CartePanelTimeUI_(p: {}) {
    const st = useSt()
    const [dropdownEl, setDropdownEl] = useState<HTMLElement | null>(null)
    const simu = st.simu
    const ck = simu.clock

    return (
        <Fragment>
            <IconButton
                color="inherit"
                onClick={(event) => setDropdownEl(event.currentTarget)}
            >
                {icons.Clock}
            </IconButton>
            <Popover
                open={Boolean(dropdownEl)}
                anchorEl={dropdownEl}
                onClose={() => setDropdownEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div tw="p-4 right[1rem] z-index[999] shadow-xl">
                    <TextField
                        variant="standard"
                        style={{ display: 'block', marginBottom: '1rem' }}
                        size="small"
                        value={ck.isoDate}
                        type="date"
                        onChange={(e) => ck.setIsoDate(e.target.value)}
                    />
                    <TextField
                        variant="standard"
                        style={{ marginBottom: '0.5rem' }}
                        size="small"
                        value={ck.isoTime}
                        type="time"
                        fullWidth
                        onChange={(e) => ck.setIsoTime(e.target.value)}
                    />
                    <div className="line2">
                        <IconButton onClick={() => simu.simulationToogle()}>
                            {simu.simulationStarted ? icons.Pause : icons.Play}
                        </IconButton>
                        <div className="grow"></div>
                        <div className="text-small italic">Ctrl + Shift + U</div>
                    </div>
                    {/* <Slider
                        value={ck.now}
                        min={ck.timeMin}
                        max={ck.timeMax}
                        onChange={(_, newVal) => {
                            // console.log(newVal)
                            ck.now =
                                typeof newVal === 'number' //
                                    ? newVal
                                    : newVal[0]
                            // console.log(
                            //     `flying drones: ${st.simu.flyingDrones.length}/${st.simu.Drone.rows.length}`,
                            // )
                        }}
                        aria-labelledby="continuous-slider"
                    /> */}
                    {/* <input
                    type="number"
                    value={ck.time}
                    step={ck.dHour}
                    min={ck.timeMin}
                    max={ck.timeMax}
                    onChange={(e) => {
                        const i = parseInt(e.target.value, 10)
                        if (!i) return
                        ck.time = i
                    }}
                /> */}
                    {/* <div>
                        <b>{st.simu.flyingDrones.length}</b> flying drones
                    </div> */}
                </div>
            </Popover>
        </Fragment>
    )
})
