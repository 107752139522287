import * as RL from 'react-leaflet'
import { Icon } from 'leaflet'
import { assets } from '../assets/assets'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'
import { Drone } from '../core/drone'
import { BatteryIndicator } from '../components/batteryIndicator'
import { icons } from '../components/iconUI'
import { runInAction } from 'mobx'

export const DronePinUI = observer(function DronePinUI_(p: { drone: Drone }) {
    const st = useSt()
    const drone = p.drone
    const pilot = drone.currentMission?.pilot
    const currLatLng = drone.currentLatLng
    if (currLatLng == null) return null
    return (
        <RL.Marker
            position={currLatLng}
            icon={st.selectedDrone?.id === p.drone.id ? pinDroneActive : pinDrone}
            eventHandlers={{
                click: () =>
                    runInAction(() => {
                        st.selectedDrone = drone
                        st.selectedVertiport = null
                        st.selectedCorridor = null
                    }),
            }}
        >
            <RL.Tooltip>
                <div tw="flex items-baseline justify-between">
                    <button className="text-underline">
                        <h3 className="line2">
                            {icons.Drone} {drone.name}
                        </h3>
                    </button>
                    <BatteryIndicator drone={drone} />
                </div>
                {pilot && (
                    <div tw="mt-2">
                        <span className="propertyName">Pilot</span>
                        <div>{pilot.name}</div>
                        <div>({pilot.phoneClean})</div>
                    </div>
                )}
            </RL.Tooltip>
            {/* <L.Popup>Drone {d.name}</L.Popup> */}
        </RL.Marker>
    )
})

export const pinDrone = new Icon({
    iconUrl: assets.pinDrone,
    popupAnchor: [-0, -0],
    iconSize: [32, 45],
    className: 'marker',
})

export const pinDroneActive = new Icon({
    iconUrl: assets.pinDroneActive,
    popupAnchor: [-0, -0],
    iconSize: [32, 45],
    className: 'marker',
})

export const pinFrom = new Icon({
    iconUrl: assets.pinGreen,
    popupAnchor: [-0, -0],
    iconSize: [32, 45],
    className: 'marker',
})
export const pinTo = new Icon({
    iconUrl: assets.pinRed,
    popupAnchor: [-0, -0],
    iconSize: [32, 45],
    className: 'marker',
})
