import { observer } from 'mobx-react-lite'

import { VertiportsPageUI } from '../pages/vertiportPage'
import { useSt } from '../state/context'

import { MapPanelUI } from './panelCSS'

export const CartePanelVertiportUI = observer(function CartePanelVertiportUI_() {
    const st = useSt()
    const v = st.selectedVertiport
    if (v == null) return null
    return (
        <MapPanelUI close={() => (st.selectedVertiport = null)}>
            <VertiportsPageUI skipCarte vertiportId={v.id} />
        </MapPanelUI>
    )
})
