import type { Page } from '../layout/pages'
import type { LatLngTuple } from 'leaflet'
import type { RID } from '../core/schema'
import type { CarteZone } from '../carte/carteUI'

import { Observer, observer } from 'mobx-react-lite'
import * as RL from 'react-leaflet'

import { CarteUI, DroneTraceSplittedUI } from '../carte/carteUI'
import { CartePanelVertiportUI } from '../carte/panelVertiport'
import { CartePanelDroneUI } from '../carte/panelDrone'
import { useSt } from '../state/context'
import { useCallback, useMemo } from 'react'
import { CartePanelCorridorUI } from '../carte/panelCorridor'
import { FancyLineUI } from '../carte/fancyLines'

export const HomePage = (): Page<HomePageProps> => ({
    id: 'home' as const,
    icon: 'Carte' as const,
    path: '/',
    label: 'Map',
    Component: HomePageUI,
})

export type HomePageProps = {
    lat?: number
    lon?: number
    zoom?: number
    zone?: string // key
    droneId?: RID
    vertiportId?: RID
}

export const HomePageUI = observer((pp: HomePageProps) => {
    const st = useSt()
    const p = useMemo(
        () => ({ ...st.loc, ...pp }),
        // eslint-disable-next-line
        [],
    )
    const center: LatLngTuple = useMemo(
        () => (p.lat && p.lon ? [p.lat, p.lon] : st.simu.center),
        // eslint-disable-next-line
        [p.zone],
    )
    const onZoneChange = useCallback(
        (zone: CarteZone) => {
            const loc: HomePageProps = { ...p, ...zone }
            st.loc = loc
            st.goTohomePage(loc)
        },
        // eslint-disable-next-line
        [],
    )
    return (
        <div className="HomePage grow row relative" key={p.zone}>
            <CartePanelDroneUI />
            <CartePanelVertiportUI />
            <CartePanelCorridorUI />
            <CarteUI
                onZoom={onZoneChange}
                onPan={onZoneChange}
                zoom={p.zoom}
                editBtn
                drones
                vertiportTooltip
                corridorClick={(c) => st.selectCorridor(c)}
                center={center}
                vertiportIcon={(v) => (st.selectedVertiport === v ? 'yellow' : 'gray')}
                className="grow"
            >
                <RL.Pane name="selected drone path" style={{ zIndex: 301 }}>
                    <DroneTraceSplittedUI
                        now={st.simu.clock.now}
                        selected={st.selectedDrone}
                    />
                </RL.Pane>
                {st.selectedVertiport && (
                    <RL.CircleMarker
                        key={st.selectedVertiport.id}
                        center={st.selectedVertiport.pos}
                        pathOptions={{ color: 'blue' }}
                        radius={100}
                        stroke={false}
                    />
                )}
                <Observer>
                    {() => {
                        const c = st.selectedCorridor
                        if (c == null) return null
                        const positions = c.positions
                        return <FancyLineUI positions={positions} />
                    }}
                </Observer>
            </CarteUI>
        </div>
    )
})
