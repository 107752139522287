import { observer } from 'mobx-react-lite'
import { CSSProperties } from 'react'
import { css } from 'twin.macro'

import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/core/Alert'

import { renderDuration } from '../../utils/renderTime'
import { icons } from '../../components/iconUI'
import { useDraft } from '../draftContext'

export const DraftValidationUI = observer(function DraftValidationUI_(p: {
    className?: string
    style?: CSSProperties
}) {
    const draft = useDraft()
    return (
        <div
            tw="mt-5"
            className={p.className}
            css={css`
                display: flex;
                flex-direction: column;
                & .check-label {
                    margin-left: 1rem;
                }
            `}
        >
            {/*  */}
            <div className="line2">
                <Button
                    disabled={
                        draft.pilot == null ||
                        draft.drone == null ||
                        draft.operator == null ||
                        draft.isValid || //
                        draft.isValidating ||
                        draft.steps.length === 0
                    }
                    onClick={() => draft.check()}
                    size="large"
                    variant="contained"
                >
                    {draft.checkLabel}
                </Button>

                <Button
                    sx={{ ml: 2 }}
                    disabled={
                        draft.pilot == null ||
                        draft.drone == null ||
                        draft.operator == null ||
                        !draft.isValid || //
                        draft.isValidating ||
                        draft.steps.length === 0
                    }
                    onClick={() => draft.create()}
                    size="large"
                    variant="contained"
                >
                    CREATE
                </Button>
            </div>
            {draft.nbChecks > 0 && (
                <div className="col checks">
                    {/* <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row"> */}
                    <div className="line2 check">
                        {draft.isValidating
                            ? spinnerAvail
                            : draft.isValid
                            ? icons.Success
                            : icons.EventDenied}
                        <div className="check-label"> Availability</div>
                    </div>
                    {draft.errors.length > 0 && //
                        !draft.isValid &&
                        !draft.isValidating && (
                            <Alert severity="warning">
                                {draft.errors.length} service
                                {draft.errors.length > 1 ? 's' : undefined} delayed.
                                <div>
                                    {draft.errors.map((e, ix) => (
                                        <div key={`${ix}-${e.kind.type}`}>
                                            - {renderDuration(e.queueTime)} wait before
                                            drone can {e.kind.type}.
                                        </div>
                                    ))}
                                </div>
                                <Button
                                    disabled={draft.isValidating}
                                    onClick={() => draft.acceptWait()}
                                    size="large"
                                    variant="outlined"
                                >
                                    Accept Wait time
                                </Button>
                            </Alert>
                        )}
                    <div className="line2 check">
                        {draft.isValidating ? spinnerMeteo : icons.Success}
                        <div className="check-label">Météo</div>
                    </div>
                    <div className="line2 check">
                        {draft.isValidating ? spinnerAirCo : icons.Success}
                        <div className="check-label">Air Corridors</div>
                    </div>
                    {/* </Stack> */}
                </div>
            )}
        </div>
    )
})
const spinnerAvail = <CircularProgress color="inherit" />
const spinnerMeteo = <CircularProgress color="secondary" />
const spinnerAirCo = <CircularProgress color="success" />
