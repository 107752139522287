import type { Vertiport } from '../core/vertiport'
import type { Operator } from '../core/operator'
import type { Action } from '../core/action'
import type { Drone } from '../core/drone'
import type { State } from '../state/state'
import type { Page } from '../layout/pages'

import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import { parseFullMonth, renderFullMonth } from '../utils/fullMonth'
import { ProfitsByMonth } from '../charts/profitsByKind'
import { ReactNode, useMemo, useState } from 'react'
import { formatMoney } from '../fakes/chunk'
import { icons } from '../components/iconUI'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'

export const DashboardPage = (): Page<DashboardPageProps> => ({
    id: 'Dashboard' as const,
    icon: 'Dashboard' as const,
    path: '/dashboard',
    label: 'Dashboard',
    Component: DashboardPageUI,
})

export type DashboardPageProps = {
    lat?: number
    lon?: number
    focusOnId?: number
    focusOn?: 'drone' | 'vertiport'
}

export const DashboardPageUI = observer((p: DashboardPageProps) => {
    const st = useSt()
    const [month, setMonth] = useState(() => renderFullMonth(st.simu.clock.now))
    const [tabValue, setTabValue] = useState(0)
    const helper = useMemo(() => new MonthHelper(st, month), [st, month])

    return (
        <div className="DashboardPage grow" tw="relative padding[0.5rem 2rem 0]">
            <h1>Dashboard</h1>
            {/* A */}
            <h2>Profit per month</h2>
            <ProfitsByMonth />

            {/* DATE */}
            <FormControl
                variant="standard"
                sx={{ width: '10rem', marginBottom: '1.5rem' }}
            >
                <InputLabel>Month</InputLabel>
                <Select value={month} onChange={(e) => setMonth(e.target.value)}>
                    {st.simu.clock.possibleMonths.map((i) => (
                        <MenuItem key={i} value={i}>
                            {i}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <div className="grow col basis-0" tw="lg:(mr-16 mb-0) mb-8">
                <h2>Key numbers</h2>
                <ul tw="p-0">
                    <li className="line2" tw="mb-2">
                        {icons.Mission}
                        <p tw="ml-2 mb-0">
                            Missions performed: <b>{helper.missions.length}</b>
                        </p>
                    </li>
                    <li className="line2" tw="mb-2">
                        {icons.Euro}
                        <p tw="ml-2 mb-0">
                            Revenues: <b>{formatMoney(helper.profits)}</b>
                        </p>
                    </li>
                    <li className="line2" tw="mb-2">
                        {icons.Actions}
                        <p tw="ml-2 mb-0">
                            Actions performed: <b>{helper.actions.length}</b>
                        </p>
                    </li>
                </ul>
                <h2>Top 3 Revenues</h2>
                <Box>
                    <Tabs
                        variant="scrollable"
                        value={tabValue}
                        onChange={(e, newValue) => setTabValue(newValue)}
                        aria-label="tabs top 3"
                        sx={{ marginBottom: '1rem' }}
                    >
                        <Tab label="Drone" />
                        <Tab label="Opérateur" />
                        <Tab label="Vertiport" />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <ul>
                            {helper.CAPerDrone.map((k, ix) => (
                                <li
                                    className="line2"
                                    key={ix}
                                    tw="mb-2"
                                    onClick={() =>
                                        st.goTodronesListPage({
                                            focusId: k.drone.id,
                                        })
                                    }
                                >
                                    {icons.Drone}
                                    <p tw="ml-2">
                                        {k.drone.name}: <b>{formatMoney(k.profits)}</b>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <ul>
                            {helper.CAPerOperator.map((k, ix) => (
                                <li key={ix} className="line2" tw="mb-2">
                                    {icons.User}
                                    <p tw="ml-2">
                                        {k.operator.name}: <b>{formatMoney(k.profits)}</b>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <ul>
                            {helper.CAPerVertiport.map((k, ix) => (
                                <li key={ix} className="line2" tw="mb-2">
                                    {icons.Vertiport}
                                    <p tw="ml-2">
                                        {k.vertiport.name}:{' '}
                                        <b>{formatMoney(k.profits)}</b>
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </TabPanel>
                </Box>
            </div>
        </div>
    )
})

type TabPanelType = {
    value: number
    children?: ReactNode
    index: number
}

const TabPanel = (p: TabPanelType) => {
    const { value, index, children } = p
    return <div>{value === index && children}</div>
}

export class MonthHelper {
    monthStartTz: number
    monthEndTz: number
    constructor(
        //
        public st: State,
        public fullMonth: string,
    ) {
        const start = parseFullMonth(fullMonth)
        const end =
            start.month === 12 //
                ? { year: start.year + 1, month: 1 }
                : { year: start.year, month: start.month + 1 }

        this.monthStartTz = new Date(`${start.year}-${start.month}`).getTime()
        this.monthEndTz = new Date(`${end.year}-${end.month}`).getTime()
        console.log({
            fullMonth,
            monthStartTz: this.monthStartTz,
            monthEndTz: this.monthEndTz,
            x: this.st.simu.Mission.rows.map((m) => [m.startAt, m.endAt]),
            y: this.missions.length,
        })
    }
    get missions() {
        return this.st.simu.Mission.rows.filter(
            (m) =>
                m.startAt > this.monthStartTz && //
                m.startAt < this.monthEndTz,
        )
    }
    get actions(): Action[] {
        return this.missions.flatMap((m) => m.actions)
    }

    get profits(): number {
        return this.actions.reduce((p, c) => p + c.price, 0)
    }

    get CAPerOperator(): { operator: Operator; profits: number }[] {
        let x: { [key: string]: number } = {}
        for (let a of this.actions) x[a.operatorId] = (x[a.operatorId] || 0) + a.price
        return Object.entries(x)
            .sort(([_k1, v1], [_k2, v2]) => v2 - v1)
            .slice(0, 3)
            .map(([k, v]) => {
                const id = parseInt(k, 10)
                const operator = this.st.simu.Operator.get(id)
                return { operator, profits: v }
            })
    }

    get CAPerVertiport(): { vertiport: Vertiport; profits: number }[] {
        let x: { [key: string]: number } = {}
        for (let a of this.actions) x[a.vertiportId] = (x[a.vertiportId] || 0) + a.price
        return Object.entries(x)
            .sort(([_k1, v1], [_k2, v2]) => v2 - v1)
            .slice(0, 3)
            .map(([k, v]) => {
                const id = parseInt(k, 10)
                const vertiport = this.st.simu.Vertiport.get(id)
                return { vertiport, profits: v }
            })
    }

    get CAPerDrone(): { drone: Drone; profits: number }[] {
        let x: { [key: string]: number } = {}
        for (let a of this.actions) x[a.droneId] = (x[a.droneId] || 0) + a.price
        return Object.entries(x)
            .sort(([_k1, v1], [_k2, v2]) => v2 - v1)
            .slice(0, 3)
            .map(([k, v]) => {
                const id = parseInt(k, 10)
                const drone = this.st.simu.Drone.get(id)
                return { drone, profits: v }
            })
    }
}
