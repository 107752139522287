import type { LngLatTuple } from '../core/point'
import type { State } from '../state/state'
import type { ActionKind } from '../core/action'

import { action, makeAutoObservable, makeObservable, observable } from 'mobx'

import { getFakeFrenchName } from '../fakes/fakeFrenchName'
import { randomInt, randomPhone } from '../fakes/fakePhoneNumber'
import { fakeNameV1 } from '../fakes/fakeNames'
import { choose } from '../fakes/utils'
import { Clock, Timestamp } from './clock'

import { mkDefaultPricingData, Pricing, PricingData } from '../core/pricing'
import { Area, AreaData, mkDefaultAreaData } from '../core/area'
import { Vertiport, VertiportData } from '../core/vertiport'
import { Operator, OperatorData } from '../core/operator'
import { Mission, MissionData, MissionStepAction } from '../core/mission'
import { Action, ActionData } from '../core/action'
import { Agent, AgentData } from '../core/agent'
import { Drone, DroneData, SPEED_AS_KM_PER_HOUR } from '../core/drone'
import { Pilot, PilotData } from '../core/pilot'
import { Corridor, CorridorData } from '../core/corridor'
import { AreaInfos, LatLngTuple } from '../core/point'

import { RID } from '../core/schema'
import { nanoid } from 'nanoid'
import { encode, shorten } from '../plus-codes'
import { DraftState } from '../add-mission/draftState'
import { randomRange, rnd } from '../utils/range'

export const SCHEMA_VERSION_CONST = 45 as const
export type SCHEMA_VERSION = typeof SCHEMA_VERSION_CONST

export type SimulationData = {
    SCHEMA_VERSION: SCHEMA_VERSION
    id: string
    nextRID: RID
    now: Timestamp
    activePricingId: RID
    activeAreaId: RID
    actions: ActionData[]
    drones: DroneData[]
    operators: OperatorData[]
    missions: MissionData[]
    vertiports: VertiportData[]
    agents: AgentData[]
    pilots: PilotData[]
    corridors: CorridorData[]
    areas: AreaData[]
    pricings: PricingData[]
}

export class Table<I extends { id: RID }> {
    clear = () => {
        this.byId.clear()
        this.rows.splice(0, this.rows.length)
    }
    removeWhere = (predicate: (i: I) => boolean): I[] => {
        let nextRows: I[] = []
        let nextById = new Map<RID, I>()
        const deleted: I[] = []
        for (let r of this.rows) {
            if (predicate(r)) {
                deleted.push(r)
            } else {
                nextRows.push(r)
                nextById.set(r.id, r)
            }
        }
        this.byId = nextById
        this.rows = nextRows
        return deleted
    }
    constructor(public tableName: string) {
        makeObservable(this, {
            rows: observable.shallow,
            byId: observable.shallow,
            //
            clear: action,
            removeWhere: action,
        })
    }

    rows: I[] = []
    byId = new Map<RID, I>()
    chooseKey = (): RID => this.choose().id

    choose = (): I => {
        const len = this.rows.length
        if (len === 0) throw new Error(`no ${this.tableName} elem`)
        const ix = Math.floor(len * Math.random())
        return this.rows[ix]
    }
    chooseSafe = (): I | null => {
        const len = this.rows.length
        if (len === 0) return null
        const ix = Math.floor(len * Math.random())
        return this.rows[ix]
    }
    getMany = (ids: RID[]): I[] => ids.map(this.get)
    get_ = (id: RID): I | undefined => {
        return this.byId.get(id)
    }
    get = (id: RID): I => {
        const v = this.byId.get(id)
        if (v == null) throw new Error(`missing ${this.tableName}#${id}`)
        return v
    }
}

export class Simulation {
    id: string
    activePricing: Pricing
    activeArea: Area

    Vertiport = new Table<Vertiport>('Vertiport')
    Agent = new Table<Agent>('Agent')
    Operator = new Table<Operator>('Operator')
    Drone = new Table<Drone>('Drone')
    Mission = new Table<Mission>('Mission')
    Action = new Table<Action>('Action')
    Pilot = new Table<Pilot>('Pilot')
    Corridor = new Table<Corridor>('Corridor')
    Area = new Table<Area>('Area')
    Pricing = new Table<Pricing>('Pricing')

    // TODO: remove
    getAgent = (rid: RID): Agent => this.Agent.get(rid)
    getDrone = (rid: RID): Drone => this.Drone.get(rid)
    getPilot = (rid: RID): Pilot => this.Pilot.get(rid)
    getOperator = (rid: RID): Operator => this.Operator.get(rid)
    getMission = (rid: RID): Mission => this.Mission.get(rid)

    // =============== TIME ===============
    clock: Clock

    get flyingDrones(): Drone[] {
        return this.Drone.rows.filter((d) => d.currentAction)
        // return this.Drone.rows.filter((d) => d.isFlying)
    }

    nextRID: number
    getRID = () => this.nextRID++

    _LOADED = false
    constructor(
        //
        public state: State,
        data: SimulationData,
    ) {
        console.log({ data })
        this.id = data.id
        this.clock = new Clock(data.now)
        this.nextRID = data.nextRID
        data.actions.forEach((d) => new Action(this, d))
        data.drones.forEach((d) => new Drone(this, d))
        data.operators.forEach((d) => new Operator(this, d))
        data.missions.forEach((d) => new Mission(this, d))
        data.vertiports.forEach((d) => new Vertiport(this, d))
        data.agents.forEach((d) => new Agent(this, d))
        data.pilots.forEach((d) => new Pilot(this, d))
        data.corridors.forEach((d) => new Corridor(this, d))
        data.areas.forEach((d) => new Area(this, d))
        data.pricings.forEach((d) => new Pricing(this, d))
        this.activePricing = this.Pricing.get(data.activePricingId)
        this.activeArea = this.Area.get(data.activeAreaId)
        makeAutoObservable(this)
        // this.startSimulation()
        this._LOADED = true
    }

    toJSON = (): SimulationData => {
        return {
            SCHEMA_VERSION: SCHEMA_VERSION_CONST,
            nextRID: this.nextRID,
            id: this.id,
            activePricingId: this.activePricing.id,
            activeAreaId: this.activeArea.id,
            now: this.clock.now,
            //
            actions: this.Action.rows, //((x) => x.toJSON()),
            drones: this.Drone.rows, //((x) => x.toJSON()),
            corridors: this.Corridor.rows,
            operators: this.Operator.rows, //((x) => x.toJSON()),
            missions: this.Mission.rows, //((x) => x.toJSON()),
            vertiports: this.Vertiport.rows, //((x) => x.toJSON()),
            agents: this.Agent.rows, //((x) => x.toJSON()),
            pilots: this.Pilot.rows, //((x) => x.toJSON()),
            areas: this.Area.rows, //((x) => x.toJSON()),
            pricings: this.Pricing.rows, //((x) => x.toJSON()),
        }
    }
    // ================== BUILD ==================
    addAgent = (): Agent =>
        new Agent(this, {
            id: this.getRID(),
            name: getFakeFrenchName(),
            phone: randomPhone(),
        })

    addCorridor = (v1: Vertiport, v2: Vertiport): Corridor => {
        const c = new Corridor(this, {
            id: this.getRID(),
            vertiportId1: v1.id,
            vertiportId2: v2.id,
            maxSpeed: SPEED_AS_KM_PER_HOUR,
            waypointsLats: [],
            waypointsLons: [],
            waypointsSpeeds: [],
            // name: getFakeFrenchName(),
            // phone: randomPhone(),
        })
        v1.corridorIds.push(c.id)
        v2.corridorIds.push(c.id)
        return c
    }

    addVertiportAt = (lat: number, lon: number): Vertiport => {
        const lonLat: LngLatTuple = [lon, lat]
        const area = this.Area.rows
            .slice()
            .sort((a, b) => a.distanceTo(lonLat) - b.distanceTo(lonLat))[0]
        return this.addVertiport(area, { lat, lon, fake: false })
    }

    addVertiportNear = (area: Area, infos: AreaInfos): Vertiport => {
        const lat = this.randomLat(area.lat, infos.radius)
        const lon = this.randomLon(area.lon, infos.radius)
        return this.addVertiport(area, { lat, lon, fake: true })
    }

    private addVertiport = (
        area: Area,
        hints: Partial<VertiportData> & { lat: number; lon: number; fake: boolean },
    ): Vertiport => {
        const latitude = hints.lat
        const longitude = hints.lon
        const pluscodeFull = encode({ latitude, longitude })!
        const center = { latitude: area.lat, longitude: area.lon }
        const pluscodeShort = shorten(pluscodeFull, center)
        const v = new Vertiport(this, {
            id: this.getRID(),
            lat: hints.lat,
            lon: hints.lon,
            name: `${area.name} ${pluscodeShort}`,
            agentIds: [this.addAgent().id],
            areaId: area.id,
            corridorIds: [],
            deleted: false,
            nbLandingBay: hints.nbLandingBay ?? 2,
            nbChargingBay: hints.nbChargingBay ?? randomRange(4, 8), // 6
            nbParkingBay: hints.nbParkingBay ?? randomRange(5, 11), // 9
            nbRepairBay: hints.nbRepairBay ?? randomRange(0, 2), // 3
            fake: hints.fake,
        })
        area.vertiportIds.push(v.id)
        return v
    }

    addArea = (name: string, pos: LatLngTuple, zoom?: number): Area =>
        new Area(this, {
            id: this.getRID(),
            name,
            lat: pos[0],
            lon: pos[1],
            zoom: zoom || 13,
            vertiportIds: [],
        })

    addOperator = (hints: Partial<OperatorData> = {}): Operator =>
        // prettier-ignore
        new Operator(this, {
            id:        hints.id        || this.getRID(),
            name:      hints.name      || fakeNameV1(),
            phone:     hints.phone     || randomPhone(),
            tradeName: hints.tradeName || fakeNameV1(),
            siret:     hints.siret     || this.randomSiret(),
            formation: hints.formation || 'basic',
            address:   hints.address   || '16 rue du test, Paris',
            droneIds:  hints.droneIds  || [],
            pilotIds:  hints.pilotIds  || [],
            fake:      hints.fake ?? true,
            uasRegistration:  hints.uasRegistration ?? `FRA${nanoid()}`
        })

    addMission2 = (draft: DraftState) => {
        // 1. basic checks
        const drone = draft.drone
        if (drone == null) return console.log('missing drone')

        const pilot = draft.pilot
        if (pilot == null) return console.log('missing pilot')

        const operator = draft.operator
        if (operator == null) return console.log('missing operator')

        const fm = draft.fakeMission
        if (fm.steps.length === 0) return console.log('not enough actions')

        // 2. create missions
        const actionData: MissionStepAction[] = fm.steps.flatMap((s) => s.actions)
        const lastAction = actionData[actionData.length - 1]
        const missionEndAt = lastAction.at + lastAction.at
        const missionId = this.getRID()
        const mission = new Mission(this, {
            id: missionId,
            name: draft.name || `Mission ${missionId}`,
            pilotId: pilot.id,
            type: draft.type,
            actionIds: [],
            droneId: drone.id,
            startAt: draft.startAt,
            endAt: missionEndAt,
            fake: false,
        })

        // const drone = this.Drone.get(droneId)
        drone.missionIds.push(mission.id)

        actionData.forEach((msa: MissionStepAction) => {
            const kind: ActionKind = msa.kind
            const a = new Action(this, {
                at: msa.at,
                id: this.getRID(),
                kind: kind,
                missionId: mission.id,
                vertiportId: msa.vertiportId,
                durationMS: msa.durationMS,
            })
            mission.actionIds.push(a.id)
        })
        this.state.goTomissionListPage({
            focusId: mission.id,
            filters: { operatorName: mission.operatorName },
        })
        // console.log(draft)
    }

    __GENCACHE = new Set<RID>()
    addRandomMission = (
        //
        area: Area,
        droneId: RID,
        pilotId: RID,
    ): Mission => {
        const nbSteps = 2 + randomInt(5)
        // this.clock.timeMin + randomInt(this.clock.timeMax - this.clock.timeMin)
        // 1. create mission
        let startAt: Timestamp
        if (this.__GENCACHE.has(droneId)) {
            startAt = this.clock.chooseMissionTime()
        } else {
            this.__GENCACHE.add(droneId)
            const offset = (10 + Math.floor(Math.random() * 30)) * this.clock.dMin
            startAt = this.clock.now - offset
        }
        const mission = new Mission(this, {
            id: this.getRID(),
            name: this.randomName('mission'),
            type: this.randomMissionType(),
            actionIds: [],
            pilotId,
            droneId,
            startAt,
            endAt: startAt, // will be overwiten at the end of this function
            fake: true,
        })

        // 2 add actions
        let time = startAt
        let v = area.chooseVertiport()
        let hasLanded: boolean = true

        let isLoaded: boolean = false
        const load = () => {
            const nbKilo: number = randomRange(2, 10)
            time += mission.load(v.id, time, nbKilo).durationMS
            isLoaded = true
        }
        const unload = () => {
            time += mission.unload(v.id, time).durationMS
            isLoaded = false
        }

        // mission.takeoff(v, time - 2 * this.clock.dMin)
        for (let i = 1; i < nbSteps; i++) {
            let prev = v
            v = choose(v.connections)
            if (v == null) throw new Error('IMPOSSIBLE')
            if (hasLanded) {
                time += mission.takeoff(prev, time).durationMS
            }
            time += mission.flyTo(time, prev, v).durationMS

            if (Math.random() > 0.5) {
                time += mission.land(v, time).durationMS
                hasLanded = true
                time += mission.charge(v.id, time).durationMS
                if (isLoaded) {
                    if (Math.random() > 0.5) unload()
                } else if (Math.random() > 0.5) load()
            }
        }
        if (isLoaded) unload()
        time += mission.park(v, time).durationMS
        mission.endAt = time

        return mission
    }

    addDrone = (hints: Partial<DroneData> = {}) => {
        const id = hints.id || this.getRID()
        return new Drone(this, {
            id: id,
            operatorId: hints.operatorId ?? this.Operator.chooseKey(),
            name: hints.name ?? `ED${10000 + id}`,
            brand: hints.brand ?? 'DJI',
            missionIds: hints.missionIds ?? [],
            weight:
                hints.weight ?? Math.random() > 0.5
                    ? Math.floor(2_000 + Math.random() * 2_000)
                    : Math.floor(5_000 + Math.random() * 20_000),
            class: hints.class ?? 'C0',
            model: hints.model || 'H2KSD',
            scenario: hints.scenario ?? 'S3',
            battery: hints.battery ?? randomInt(100),
            minutesRemaining: hints.minutesRemaining ?? randomInt(100 * 4),
            batteryHealth: hints.batteryHealth ?? randomInt(100),
            minutesTotal: hints.minutesTotal ?? randomInt(100),
            hoursBeforeRevision: hints.hoursBeforeRevision ?? randomInt(100),
            fake: hints.fake ?? true,
        })
    }

    addPilot = (hints: Partial<PilotData> & { operatorId: RID }): Pilot =>
        new Pilot(this, {
            id: hints.id ?? this.getRID(),
            operatorId: hints.operatorId,
            name: hints.name ?? fakeNameV1(),
            phone: hints.phone ?? randomPhone(),
            certification:
                hints.certification ??
                ['basic']
                    .concat(
                        ['advanced', 'a1', 'a2', 'a3'].filter((_, ix) =>
                            rnd(1 - ix / 3.5),
                        ),
                    )
                    .join(','),
            fake: hints.fake ?? true,
        })

    // ================== RUN ==================
    private simulationInterval: any
    simulationStarted = false
    simulationToogle = () => {
        if (this.simulationStarted) this.stopSimulation()
        else this.startSimulation()
    }
    startSimulation = () => {
        if (this.simulationStarted) return
        this.simulationInterval = setInterval(this.stepSimulation, 1500)
        this.simulationStarted = true
    }
    stopSimulation = () => {
        if (!this.simulationStarted) return
        this.simulationStarted = false
        clearInterval(this.simulationInterval)
    }
    stepSimulation = () => {
        this.clock.now += 1500
        // for (let d of this.drones) {
        //     d.lat = d.lat + (0.5 - Math.random()) * 0.001
        //     d.lon = d.lon + (0.5 - Math.random()) * 0.001
        // }
    }

    // ================== HELPERS ==================
    rand = (scale = 1) => (Math.random() - 0.5) * scale

    randomName = (prefix: string): string =>
        prefix + ['tango', 'charlie', 'bravo'][Math.floor(Math.random() * 3)]
    private randomLatShift = 0.1
    private randomLonShift = 0.1

    get center(): LatLngTuple { return this.activeArea.center } // prettier-ignore
    randomLatLng = (): LatLngTuple => [
        this.center[0] + this.rand(this.randomLatShift),
        this.center[1] + this.rand(this.randomLonShift),
    ]

    randomLat = (center: number, scale = this.randomLatShift): number =>
        center + this.rand(scale)

    randomLon = (center: number, scale = this.randomLonShift): number =>
        center + this.rand(scale)

    randomMissionType = (): string =>
        ['surveillance', 'logistic'][Math.floor(Math.random() * 2)]
    // randomMissionStatus = (): string =>
    //     ['expected', 'in progress', 'completed'][Math.floor(Math.random() * 3)]
    randomSiret = (): string => Math.floor(Math.random() * 100000000000000).toString()
}

export const newEmptySimulationData = (): SimulationData => {
    console.log('generating a new simulation')
    // HARD-CODED RIDs
    const p = mkDefaultPricingData(1)
    const areas: AreaData[] = mkDefaultAreaData(2, 3, 4)
    return {
        SCHEMA_VERSION: SCHEMA_VERSION_CONST,
        nextRID: 10, // below 10 reserved from hard-coded stuff used above
        id: nanoid(),
        activePricingId: p.id,
        activeAreaId: areas[0].id,
        now: Date.now(),
        //
        actions: [],
        drones: [],
        operators: [],
        missions: [],
        vertiports: [],
        agents: [],
        pilots: [],
        corridors: [],
        areas,
        pricings: [p],
    }
}
