import { observer } from 'mobx-react-lite'
import { ObjectSchema } from 'yup'
import { useMemo } from 'react'

import Autocomplete from '@material-ui/core/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Model, RID } from '../core/schema'
import { EditableField } from './formType'
import { FormState, useField } from './formEngine'

export const ModelFormUI = observer(function FormUI_(p: {
    model?: Model<any>
    schema?: ObjectSchema<any>
    onClose?: () => void
    onSubmit?: (values: any) => void
    submitLabel?: string
    debug?: boolean
}) {
    console.log('NOT NORMAL')
    const schema = p.schema || p.model?.schema
    if (schema == null) throw new Error('')
    const form = useMemo(
        () => new FormState(schema, p.model?.toJSON() || {}),
        [schema, p.model],
    )
    const fields = form.fields

    return (
        <form.Form>
            {fields.map((field, i) => (
                <label key={i}>
                    <InputUI field={field} />
                </label>
            ))}
            <div style={{ textAlign: 'center' }}>
                <Button
                    onClick={() => {
                        const values = form.values
                        if (p.onSubmit) {
                            p.onSubmit(values)
                        } else {
                            // onSubmit (and everything else in React Form)
                            // has async support out-of-the-box
                            // await sendToFakeServer(values)
                            p.model?.$updateFields(values)
                        }
                        if (p.onClose) p.onClose()
                    }}
                    variant="contained"
                    type="button"
                    size="large"
                >
                    {p.submitLabel || 'Save'}
                </Button>
            </div>
            {p.debug && <pre>{form.debug}</pre>}
        </form.Form>
    )
})

export const FieldUI = observer(function FieldUI_(p: { field: EditableField }) {
    const field = p.field
    // const { meta } = useField<number>(field.key, {})
    // console.log(meta.error)
    return (
        <Grid item xs={6}>
            <label>
                <InputUI field={field} />
                <div></div>
            </label>
        </Grid>
    )
})

export const InputUI = observer(function InputUI_(p: { field: EditableField }) {
    const field = p.field
    const type = field.type
    const fp = useField<any>(field.key)
    const value = fp.getValue()

    if (type.type === 'number')
        return (
            <div>
                <TextField
                    variant="standard"
                    label={field.label || field.key}
                    {...fp.getFloatInputProps()}
                    fullWidth
                />
            </div>
        )
    if (type.type === 'string')
        return (
            <div>
                <TextField
                    variant="standard"
                    label={field.label || field.key}
                    {...fp.getTextInputProps()}
                    fullWidth
                />
            </div>
        )
    if (type.type === 'multilineString')
        return (
            <div>
                <TextField
                    variant="standard"
                    label={field.label || field.key}
                    {...fp.getTextInputProps()}
                    onChange={(ev) => {}}
                    fullWidth
                />
            </div>
        )
    if (type.type === 'id')
        return (
            <TextField
                //
                variant="standard"
                label={field.label || field.key}
                fullWidth
            />
        )
    if (type.type === 'ids')
        return (
            <TextField
                //
                variant="standard"
                label={field.label || field.key}
                fullWidth
            />
        )
    if (type.type === 'select')
        return (
            <FormControl variant="standard" fullWidth>
                <InputLabel>{field.label || field.key}</InputLabel>
                <Select
                    defaultValue={''}
                    value={value}
                    onChange={(e) => fp.setValue(e.target.value)}
                >
                    {type.of.map((value, ix) => (
                        <MenuItem key={ix} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    if (type.type === 'autocomplete') {
        return <InputAutocompleteUI field={field} />
    }
    if (type.type === 'date')
        return (
            <TextField
                label={field.label || field.key}
                type="datetime-local"
                {...fp.getTextInputProps()}
                fullWidth
            />
        )
    return exhaust(type)
})

export const InputAutocompleteUI = observer(function InputAutocompleteUI_(p: {
    field: EditableField
}) {
    const field = p.field
    const type = field.type
    if (type.type !== 'autocomplete') throw new Error('impossible')
    const { getValue, setValue, draft } = useField<number>(field.key)
    const value = getValue()
    let rows = type.of.rows
    const filter = type.filter
    if (filter) rows = rows.filter((a) => filter(draft, a))
    return (
        <Autocomplete
            options={rows}
            getOptionLabel={(option) => `${option.name} (#${option.id})`}
            value={value == null ? undefined : type.of.get(value)}
            onChange={(e, item: { id: RID }) => {
                item && setValue(item.id)
            }}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    {...params}
                    label={field.label || field.key}
                    fullWidth
                />
            )}
        />
    )
})

const exhaust = (x: never): never => {
    throw new Error('')
}
