import { LngLatTuple } from '../core/point'

export type Lat = number
export type Lon = number

export type GeoJSONLineString<P extends object = {}> = {
    type: 'Feature'
    properties: P
    geometry: {
        type: 'LineString'
        coordinates: [Lon, Lat][]
    }
}

export type GeoJSONPath<P extends object = {}> = {
    type: 'FeatureCollection'
    features: GeoJSONLineString[]
    properties: P
}

export const mkTrace = (coordinates: [Lon, Lat][]): GeoJSONPath => ({
    type: 'FeatureCollection',
    properties: {},
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates,
            },
        },
    ],
})

export const mkBunchOfLines = <P extends Object>(
    //
    coordinates: [Lon, Lat][][],
    properties: P,
): GeoJSONPath<P> => ({
    type: 'FeatureCollection',
    properties,
    features: coordinates.map(
        (segment): GeoJSONLineString => ({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: segment,
            },
        }),
    ),
})

export const lineStringFeature = <P extends Object>(
    from: LngLatTuple,
    to: LngLatTuple,
    properties: P,
): GeoJSONLineString<P> => {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'LineString',
            coordinates: [from, to],
        },
    }
}

// // TODO: remove all anys
// export const featureCollection = (features: any): any => {
//     return {
//         type: 'FeatureCollection',
//         features,
//     }
// }
