import type { AllowedIcons } from '../components/iconUI'
import type { FC } from 'react'

import { MissionListPage } from '../pages/missionListPage'
import { MissionPage } from '../pages/missionPage'
import { HomePage } from '../pages/homePage'
import { AdminPage } from '../pages/adminPage'
import { LogsPage } from '../pages/logsPage'
import { DronesListPage } from '../pages/dronesListPage'
import { DronesPage } from '../pages/dronesPage'
import { VertiportsListPage } from '../pages/vertiportsListPage'
import { VertiportsPage } from '../pages/vertiportPage'
import { OperatorPage } from '../pages/operatorPage'
import { OperatorsListPage } from '../pages/operatorsListPage'
import { PilotPage } from '../pages/pilotPage'
import { PilotsListPage } from '../pages/pilotsListPage'
import { AgentsListPage } from '../pages/agentsListPage'
import { AgentPage } from '../pages/agentPage'
import { ActionsListPage } from '../pages/actionsListPage'
import { InvoicesPage } from '../pages/invoiceListPage'
import { ProfitsPage } from '../pages/profitsPage'
import { AddMissionPage } from '../pages/addMissionPage'
import { EditMapPage } from '../pages/editMapPage'
import { PricingListPage } from '../pages/pricingListPage'
import { DashboardPage } from '../pages/dashboardPage'
import { IconsPage } from '../pages/iconPage'

export type Page<P = 'none'> = {
    id: string
    path: string
    label: string
    Component: FC<P>
    icon: AllowedIcons
    schema?: any
    nav?: boolean
}

export const pageIndex = {
    dashboard: DashboardPage(),
    home: HomePage(),
    vertiportsList: VertiportsListPage(),
    vertiport: VertiportsPage(),
    agentsList: AgentsListPage(),
    agent: AgentPage(),
    dronesList: DronesListPage(),
    drone: DronesPage(),
    missionList: MissionListPage(),
    mission: MissionPage(),
    addMission: AddMissionPage(),
    editMap: EditMapPage(),
    operatorsList: OperatorsListPage(),
    operator: OperatorPage(),
    pilotsList: PilotsListPage(),
    pilot: PilotPage(),
    logs: LogsPage(),
    admin: AdminPage(),
    actionsList: ActionsListPage(),
    invoices: InvoicesPage(),
    profits: ProfitsPage(),
    pricingList: PricingListPage(),
    icons: IconsPage(),
}

type SiteMap = typeof pageIndex

type ToNavigationFn<T> = T extends Page<infer P> //
    ? P extends 'none'
        ? () => void
        : (p: P) => void
    : never

export type NavigationUtils = {
    [key in keyof SiteMap as `goTo${key}Page`]: ToNavigationFn<SiteMap[key]>
}

const values = <T extends { [key: string]: Page<any> }>(pageIndex: T): Page[] =>
    Object.values(pageIndex)

export const pages: Page<any>[] = values(pageIndex)
