import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { configure } from 'mobx'
// import { GlobalStyles } from 'twin.macro'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

import { State } from './state/state'
import { stContext } from './state/context'

// TODO : remove if needed (we don't really need that for a poc)
configure({ enforceActions: 'never' })

const st = new State()

ReactDOM.render(
    <React.StrictMode>
        {/* <GlobalStyles /> */}
        <stContext.Provider value={st}>
            <App />
        </stContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
