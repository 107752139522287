import { observer } from 'mobx-react-lite'
import { Fragment, useState } from 'react'
import {
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core'

import { useSt } from '../state/context'

import { icons } from '../components/iconUI'
import { Popover } from '@material-ui/core'
import { renderTime } from '../utils/renderTime'

export const NotificationsUI = observer(function NotificationsUI_(p: {}) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(() => null)
    const st = useSt()
    const notifActions = st.simu.Action.rows //
        .filter((a) => a.needAgentIntervention())
    return (
        <Fragment>
            <IconButton
                color="inherit"
                aria-describedby="simple-popover"
                onClick={(event) => setAnchor(event.currentTarget)}
            >
                {icons.Notifications}
            </IconButton>
            <Popover
                id="simple-popover"
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => setAnchor(null)}
            >
                {notifActions.length === 0 ? (
                    <div>All good {icons.Success}</div>
                ) : (
                    <List>
                        {notifActions.map((action) => (
                            <ListItem alignItems="flex-start" key={action.id}>
                                <ListItemAvatar
                                    style={{ minWidth: 'unset', marginRight: '1rem' }}
                                >
                                    {icons.Actions}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${action.kind.type} - ${action.droneName}`}
                                    secondary={renderTime(action.at)}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Popover>
        </Fragment>
    )
})
