import type { Page } from '../layout/pages'
import type { Agent, AgentData } from '../core/agent'
import type { GridCol, GridView } from '../grid/gridTypes'

import { AgentSchema } from '../core/agent'
import { observer } from 'mobx-react-lite'
import { AgentPageParams, AgentPageUI } from './agentPage'
import { State } from '../state/state'
import { GridUI } from '../grid/gridUI'

export type AgentsListPageParams = {
    className?: string
} & GridView<AgentData>

export const AgentsListPage = (): Page<AgentsListPageParams> => ({
    id: 'agentsList' as const,
    icon: 'Agent' as const,
    path: '/agents',
    label: 'Agents',
    Component: AgentsListPageUI,
})
const syncPane = (view: GridView<AgentData>): AgentPageParams | null =>
    view.focusId ? { embedded: true, agentId: view.focusId } : null

export const AgentsListPageUI = observer((p: {}) => {
    return (
        <GridUI<Agent, AgentPageParams>
            schema={AgentSchema}
            rows={(st: State) => st.simu.Agent.rows}
            cols={agentsListCols}
            view={p}
            syncURL={(view, st) => st.goToagentsListPage({ ...p, ...view })}
            syncPane={syncPane}
            PaneUI={AgentPageUI}
            title="Agents"
        />
    )
})

const agentsListCols = (): GridCol<Agent>[] => [
    //
    { key: 'id', name: 'id', width: 50 },
    { key: 'name', name: 'name' },
    { key: 'phone', name: 'Phone' },
]
