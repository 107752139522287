// theme: https://github.com/adazzle/react-data-grid/issues/2170
// grouping: https://adazzle.github.io/react-data-grid/canary/?path=/story/demos--grouping
import type { GridProps } from './gridTypes'
import type { RID } from '../core/schema'
import type { FC } from 'react'

import DataGrid from 'react-data-grid'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'

import { ModelFormUI } from '../forms/formUI'
import { icons } from '../components/iconUI'
import { useSt } from '../state/context'
import { GridState } from './gridState'
import { themeMgr } from '../theme'
import { cls } from '../utils/cls'
import { InputLabel } from '@material-ui/core'

export const GridUI = observer(function GridV2UI<
    Model extends { id: RID },
    DetailsProps extends {},
>(p: GridProps<Model, DetailsProps>) {
    const st = useSt()
    // eslint-disable-next-line
    const grid = useMemo(() => new GridState(st, p), [st])
    // const view = grid
    const small = st.windowWidth < 768
    const AddComp: FC<{ close: () => void }> =
        typeof p.add === 'function' //
            ? p.add
            : () => <ModelFormUI schema={p.schema} />
    return (
        <div
            //
            style={{ position: 'relative' }}
            className="row splitContainer grow"
        >
            <div className="grow col basis-0">
                {p.title && (
                    <h2 tw="mb-2">
                        {p.title}
                        {p.add2 && (
                            <IconButton
                                style={{ display: 'inline-flex', marginLeft: '0.5rem' }}
                                color="primary"
                                onClick={() => p.add2!(st)}
                            >
                                {icons.Add}
                            </IconButton>
                        )}
                        {p.add && (
                            <IconButton
                                style={{ display: 'inline-flex', marginLeft: '0.5rem' }}
                                color="primary"
                                onClick={() => st.addPopup('Add', AddComp)}
                            >
                                {icons.Add}
                            </IconButton>
                        )}
                    </h2>
                )}
                {/* {JSON.stringify(grid.selectedRowsIds)} */}
                <div
                    className="row inline"
                    style={{ padding: '0.2rem', alignItems: 'center' }}
                >
                    <DataGridGrouppingUI grid={grid} />
                    {p.extract && (
                        <Button onClick={grid.download}>{icons.DownloadParcel}</Button>
                    )}
                    {p.children}
                </div>
                <DataGrid
                    key={grid.groupBy.join(',')}
                    defaultColumnOptions={{ sortable: true, resizable: true }}
                    rowKeyGetter={(i) => i.id}
                    // sortColumns={[{ columnKey: 'price', direction: 'DESC' }]}
                    selectedRows={grid.selectedRowsIds}
                    onRowClick={(r) => grid.selectRow(r.id)}
                    className={cls('grow', themeMgr.isDark() ? 'rdg-dark' : 'rdg-light')}
                    rows={grid.sortedRows}
                    columns={grid.cols}
                    groupBy={grid.groupBy}
                    rowGrouper={grid.rowGrouper}
                    headerRowHeight={80}
                    onSelectedRowsChange={(e) => console.log(e, 'EEE')}
                    rowClass={(row) =>
                        grid.focusId === row.id ? 'row-selected' : undefined
                    }
                    expandedGroupIds={grid.expandedGroupIds}
                    onExpandedGroupIdsChange={(e) => {
                        grid.expandedGroupIds = e as Set<string>
                        grid.groups = Array.from(e) as any
                        grid.syncView()
                    }}
                />
            </div>
            {grid.paneProps && (
                <div className="col grow basis-0 relative">
                    <Paper
                        elevation={10}
                        style={{
                            position: 'absolute',
                            right: small ? undefined : '100%',
                            bottom: small ? `100%` : undefined,
                            zIndex: 999,
                            borderRadius: 0,
                            boxShadow: small
                                ? '5px -5px 5px -7px black'
                                : '-5px 5px 5px -7px black',
                        }}
                    >
                        {/* leave that div here, otherwise, it looks weird */}
                        <div>
                            <Button
                                size="small"
                                variant="text"
                                className="sidePanel-close-btn"
                                onClick={() => {
                                    grid.focusId = null
                                    grid.syncView()
                                }}
                            >
                                {icons.Close}
                            </Button>
                        </div>
                    </Paper>
                    <Paper
                        elevation={10}
                        className="col grow basis-0"
                        style={{
                            position: 'relative',
                            borderRadius: 0,
                            overflow: 'auto',
                        }}
                    >
                        <div className="absolute inset-0" tw="p-4">
                            <p.PaneUI {...grid.paneProps} />
                        </div>
                    </Paper>
                </div>
            )}
        </div>
    )
})

export const DataGridGrouppingUI = observer(function DataGridGrouppingUI_(p: {
    grid: GridState<any, any>
}) {
    const grid = p.grid
    const view = grid
    let groupBy = view.groupBy

    return (
        <FormControl style={{ minWidth: '7.5rem' }}>
            <InputLabel id="grid-group-by-label">Group by</InputLabel>
            <Select
                // key={groupBy.join(',')}
                multiple
                labelId="grid-group-by-label"
                value={groupBy}
                input={<Input />}
            >
                {/* <MenuItem value="">
                    <em>No Groupping</em>
                </MenuItem> */}
                {p.grid.cols.map((col, ix) => (
                    <MenuItem
                        key={ix}
                        value={col.key}
                        onClick={() => grid.toggleGroupFor(col.key)}
                    >
                        {col.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
})
