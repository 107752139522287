import { ArraySchema, AnySchema, ObjectSchema } from "yup"
import { EditableField } from "./formType"

export const getFieldset = (schema: ObjectSchema<any>): EditableField[] => {
    let out: EditableField[] = []
    const entries: [key: string, value: AnySchema][] = Object.entries(
        schema.fields,
    )

    for (let [k, v] of entries) {
        if (k === 'id') continue
        if (v.type === 'number') {
            if (k.endsWith('Id')) {
                out.push({
                    key: k,
                    type: { type: 'id', of: k.substring(0, k.length - 2) },
                })
            } else {
                out.push({ key: k, type: { type: 'number' } })
            }
        }
        if (v.type === 'string') {
            if (['address'].includes(k))
                out.push({ key: k, type: { type: 'multilineString' } })
            else out.push({ key: k, type: { type: 'string' } })
        }
        if (
            k.endsWith('Ids') &&
            v instanceof ArraySchema &&
            v.innerType.type === 'number'
        ) {
            out.push({
                key: k,
                type: { type: 'ids', of: k.substring(0, k.length - 3) },
            })
        }
    }

    return out
}