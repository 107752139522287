import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { addableServices } from '../serviceList'
import { DraftMissionStep } from '../draftState'
import { useDraft } from '../draftContext'

export const ServiceRowUI = observer(function ServiceRowUI_(p: {
    step: DraftMissionStep | null
}) {
    const draftState = useDraft()
    const step0 = p.step
    if (step0 == null) return null
    return (
        <Fragment>
            {addableServices.slice(2).map((serv) => {
                const service = step0.actions.find((a) => a.kind.type === serv.activity)
                if (service)
                    return (
                        <div
                            key={serv.activity}
                            className="serv-small"
                            tw="cursor-pointer"
                            onClick={() => {
                                step0.actions = step0.actions.filter(
                                    (a) => a.kind.type !== serv.activity,
                                )
                                draftState.updateTimes()
                            }}
                        >
                            {serv.icon}
                        </div>
                    )
                if (serv.readonly)
                    return <div key={serv.activity} className="serv-small"></div>
                return (
                    <div
                        key={serv.activity}
                        className="serv-small"
                        onClick={(ev) => {
                            const queueTime: number =
                                ev.ctrlKey || ev.shiftKey
                                    ? Math.round(25 * Math.random()) * 60_000
                                    : 0
                            draftState.addService(step0, serv.activity, queueTime)
                        }}
                    >
                        <serv.iconComp className="add-serv-btn" />
                    </div>
                )
            })}
        </Fragment>
    )
})
