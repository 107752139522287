import type { EditMapState } from '../pages/editMapPage'
import type { Corridor } from '../core/corridor'

import TextField from '@material-ui/core/TextField'
import { css } from '@material-ui/styled-engine'
import Button from '@material-ui/core/Button'

import { SPEED_AS_KM_PER_HOUR, SPEED_AS_METER_PER_SECOND } from '../core/drone'
import { renderDuration } from '../utils/renderTime'
import { Vertiport } from '../core/vertiport'
import { icons } from '../components/iconUI'
import { observer } from 'mobx-react-lite'
import { MapPanelUI } from './panelCSS'
import React from 'react'
import { PropertiesUI } from '../components/propertiesUI'

export const CorridorInfoUI = observer(function CorridorInfoUI_(p: {
    corridor: Corridor
}) {
    const c = p.corridor
    const v0 = c.vertiport1
    const v1 = c.vertiport2
    const dist = c.distance
    const durationMs = 1000 * (dist / SPEED_AS_METER_PER_SECOND)
    return (
        <section className="CorridorInfoUI">
            <h3 className="line3">{icons.AirCorridor} Corridor</h3>
            <PropertiesUI
                items={[
                    ['Vertiport A', v0.name],
                    ['Vertiport B', v1.name],
                    ['Distance', `${dist.toFixed(2)} meters`],
                    ['Speed (m/s)', `${SPEED_AS_METER_PER_SECOND} m/s`],
                    ['Speed (km/h)', `${SPEED_AS_KM_PER_HOUR} km/h`],
                    ['flight time at max speed', renderDuration(durationMs)],
                ]}
            />
            <h3>
                Waypoints{' '}
                <Button
                    onClick={() => {
                        c.addWaypoint()
                        //
                    }}
                >
                    {icons.Add} Add
                </Button>
            </h3>
            {/* {JSON.stringify(c.waypointsLats)} */}
            <div className="line">
                <label className="segment-name">#</label>
                <label className="segment-lat">Lat</label>
                <label className="segment-lon">Lon</label>
                <label>Max Spd. (km/h)</label>
            </div>
            <div className="line">
                <div className="segment-name">A</div>
                <div className="segment-lat">{v0.lat.toFixed(7)}</div>
                <div className="segment-lon">{v0.lon.toFixed(7)}</div>
                <input
                    className="segment-speed"
                    type="number"
                    value={SPEED_AS_KM_PER_HOUR}
                />
            </div>
            {c.waypointsLats.map((w, ix) => (
                <div className="line" key={ix}>
                    <div className="segment-name">W{ix}</div>
                    <input
                        className="segment-lat"
                        type="number"
                        value={c.waypointsLats[ix].toFixed(7)}
                        onChange={(ev) =>
                            (c.waypointsLats[ix] = parseFloat(ev.target.value))
                        }
                    />
                    <input
                        className="segment-lon"
                        type="number"
                        value={c.waypointsLons[ix].toFixed(7)}
                        onChange={(ev) =>
                            (c.waypointsLons[ix] = parseFloat(ev.target.value))
                        }
                    />
                    <input
                        className="segment-speed"
                        type="number"
                        value={c.waypointsSpeeds[ix]}
                        onChange={(ev) =>
                            (c.waypointsSpeeds[ix] = parseFloat(ev.target.value))
                        }
                    />
                </div>
            ))}
            <div className="line">
                <div className="segment-name">B</div>
                <div className="segment-lat">{v0.lat.toFixed(7)}</div>
                <div className="segment-lon">{v1.lon.toFixed(7)}</div>
            </div>
        </section>
    )
})

export const PanelEditGraphUI = observer(function PanelEditGraphUI_(p: {
    ems: EditMapState
}) {
    const ems = p.ems
    const v = ems.vertiport
    const c = ems.corridor
    return (
        <MapPanelUI>
            <h3>Vertiport Map Editor</h3>
            {v && <VertiportStatusEditorUI vertiport={v} />}
            {c && <CorridorInfoUI corridor={c} />}
            <div className="grow"></div>
            <div>
                <Button
                    tw="mb-3"
                    variant="contained"
                    onClick={() => ems.st.regenMissions()}
                >
                    Apply !
                </Button>
            </div>
            {Doc}
        </MapPanelUI>
    )
})

export const VertiportStatusEditorUI = observer(function VertiportStatusEditorUI_(p: {
    vertiport: Vertiport
}) {
    const v = p.vertiport
    // const status = v.status
    return (
        <section>
            <h3 className="line3">
                {icons.Vertiport}
                {v.name}
            </h3>
            <TextField
                variant="filled"
                type="number"
                label="Lat"
                sx={{ width: '100%' }}
                inputProps={{ min: -89.999, max: 89.999 }}
                value={v.lat}
                onChange={(ev) => {
                    const nextValStr = ev.target.value
                    const nextVal = parseFloat(nextValStr)
                    v.lat = nextVal
                }}
            />
            <TextField
                variant="filled"
                type="number"
                label="Lon"
                sx={{ width: '100%' }}
                inputProps={{ min: 0.001, max: 189.999 }}
                value={v.lon}
                onChange={(ev) => {
                    const nextValStr = ev.target.value
                    const nextVal = parseFloat(nextValStr)
                    v.lon = nextVal
                }}
            />
            <TextField
                variant="filled"
                key={`${v.lat}-${v.lon}`}
                type="text"
                label="Plus Code"
                sx={{ width: '100%' }}
                inputProps={{ min: -89.999, max: 89.999 }}
                defaultValue={v.pluscode}
                onChange={(ev) => v.updatePlusCode(ev.target.value)}
            />
            <div className="row">
                <TextField
                    variant="filled"
                    type="number"
                    label="Charging Bays"
                    inputProps={{ min: 0 }}
                    value={v.nbChargingBay}
                    onChange={(ev) => {
                        const nextValStr = ev.target.value
                        const nextVal = parseInt(nextValStr, 10)
                        v.nbChargingBay = nextVal
                    }}
                />
                <TextField
                    variant="filled"
                    type="number"
                    label="Parking Bays"
                    inputProps={{ min: 0 }}
                    value={v.nbParkingBay}
                    onChange={(ev) => (v.nbParkingBay = parseInt(ev.target.value, 10))}
                />
                <TextField
                    variant="filled"
                    type="number"
                    label="Repair Bays"
                    inputProps={{ min: 0 }}
                    value={v.nbRepairBay}
                    onChange={(ev) => (v.nbRepairBay = parseInt(ev.target.value, 10))}
                />
            </div>
        </section>
    )
})

const Shortcut = (p: { children?: React.ReactNode }) => (
    <td style={{ textAlign: 'right' }}>
        <span className="sh-action">{p.children}</span>
    </td>
)
const ResultCell = (x: { children: React.ReactNode }) => (
    <td>
        <div className="result">{x.children}</div>
    </td>
)

const Doc = (
    <section style={{ marginTop: 'auto' }}>
        {/*  */}
        <h3>Shortcuts</h3>
        <table
            cellSpacing={0}
            css={css`
                & td {
                    border-bottom: 1px solid lightgray;
                    padding: 0.1rem 0.3rem;
                }
                & .result {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg {
                        margin: 0 0.2rem;
                    }
                }
            `}
        >
            <tbody>
                <tr>
                    <Shortcut>[Click]</Shortcut>
                    <td>Select</td>
                </tr>
                <tr>
                    <Shortcut>[Click & Drag]</Shortcut>
                    <td>Move</td>
                </tr>
                <tr>
                    <Shortcut>[Ctrl+Click]</Shortcut>
                    <ResultCell>
                        link {icons.Vertiport}
                        {'<->'}
                        {icons.Vertiport}
                    </ResultCell>
                </tr>
                <tr>
                    <Shortcut>[W]</Shortcut>
                    <td>Add waypoint</td>
                </tr>
                <tr>
                    <Shortcut>[Ctrl+Click] or [V]</Shortcut>
                    <ResultCell>Add Vertiport</ResultCell>
                </tr>
                <tr>
                    <Shortcut>[Ctrl+Shift+Click] or [X]</Shortcut>
                    <ResultCell>Add Vertiport {'&'} Corridor</ResultCell>
                </tr>
                <tr>
                    <Shortcut>[Delete] or [Backspace]</Shortcut>
                    <td>Delete selected</td>
                </tr>
                <tr>
                    <Shortcut>[Shift+Select-Area]</Shortcut>
                    <td>Zoom</td>
                </tr>
                <tr>
                    <Shortcut>[Double-Click]</Shortcut>
                    <td>Zoom once</td>
                </tr>
            </tbody>
        </table>
    </section>
)
