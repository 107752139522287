import { computed, makeObservable } from 'mobx'
import type { Asserts } from 'yup'
import type { Simulation } from '../state/simulation'
import { Model, s } from './schema'

export const PilotSchema = s.object({
    id: s._ID,
    name: s.str,
    phone: s.str,
    certification: s.str,
    fake: s.bool,
    operatorId: s._ID,
})
export type PilotData = Asserts<typeof PilotSchema>

export interface Pilot extends PilotData {}
export class Pilot extends Model<typeof PilotSchema> {
    constructor(simulation: Simulation, data: PilotData) {
        super(simulation, PilotSchema, data, 'Pilot')
        makeObservable(this, {
            ...this.observability,
            phoneClean: computed,
            operator: computed,
            operatorName: computed,
        })

        this.photo =
            photoIx >= photos.length //
                ? null
                : photos[photoIx++]
    }
    photo: string | null
    get phoneClean() { return this.phone.replace(/\s/g, '') } // prettier-ignore
    get operator() { return this.simulation.Operator.get(this.operatorId) } // prettier-ignore
    get operatorName() { return this.operator.name } // prettier-ignore
}

let photoIx = 0
const photos =
    '1.jpg,10.jpg,104.jpeg,11.jpg,110.jpg,111.jpg,117.jpg,12.jpg,13.jpg,133.jpg,134.jpg,139.jpeg,14.jpeg,141.jpeg,142.jpeg,143.jpeg,145.jpeg,149.jpg,15.jpeg,150.jpg,151.jpg,152.jpg,153.jpg,155.jpg,16.jpg,17.jpg,18.jpg,2.jpg,20.jpg,21.jpg,22.jpg,23.jpg,24.jpg,26.jpg,27.jpg,28.jpg,29.jpg,3.jpg,30.jpg,31.jpg,32.jpg,33.jpg,34.jpg,35.jpg,36.jpeg,38.jpg,39.jpg,41.jpg,43.jpg,45.jpeg,46.jpeg,48.jpg,49.jpg,5.jpg,50.jpg,51.jpg,52.jpg,53.jpg,54.jpg,55.jpg,56.jpg,57.jpg,58.jpg,59.jpg,6.jpg,60.jpg,61.jpg,62.jpg,63.jpg,64.jpg,65.jpg,66.jpg,67.jpg,68.jpg,69.jpg,7.jpg,71.jpg,72.jpg,73.jpg,74.jpeg,75.jpg,76.jpg,77.jpg,78.jpg,79.jpg,8.jpg,80.jpg,81.jpg,82.jpg,83.jpg,84.jpg,85.jpg,86.jpg,87.jpg,88.jpg,89.jpg,9.jpg,90.jpg,91.jpg,92.jpg,97.jpeg'
        .split(',')
        .map((l) => '/faces/image' + l)
