import type { Page } from '../layout/pages'
import type { RID } from '../core/schema'

import { observer } from 'mobx-react-lite'

import { Pricing, PricingSchema, PricingData } from '../core/pricing'
import { GridCol, GridView } from '../grid/gridTypes'
import { GridUI } from '../grid/gridUI'
import { State } from '../state/state'
import { ModelFormUI } from '../forms/formUI'
import { useSt } from '../state/context'
import { Fragment } from 'react'

export type PricingListParams = {
    className?: string
} & GridView<PricingData>

export const PricingListPage = (): Page<PricingListParams> => ({
    id: 'pricingList' as const,
    icon: 'Euro' as const,
    path: '/pricing',
    label: 'Pricing',
    Component: PricingListPageUI,
})

export const PricingListPageUI = observer((p: PricingListParams) => {
    return (
        <GridUI<Pricing, PricingPanelParams>
            schema={PricingSchema}
            rows={(st: State) => st.simu.Pricing.rows}
            cols={PricingListCols}
            view={p}
            syncURL={(view, st) => st.goTopricingListPage({ ...p, ...view })}
            syncPane={syncPane}
            PaneUI={PriceInfo}
            title="Pricing Versions"
        />
    )
})

const PricingListCols = (): GridCol<Pricing>[] => [
    { key: 'id', name: 'ID', width: 80 },
    { key: 'name', name: 'Name' },
    {
        key: 'active',
        name: 'Active',
        formatter: (x) => (x.row.active ? <Fragment>YES</Fragment> : null),
    },
]

type PricingPanelParams = { pricingId: RID }
const syncPane = (view: GridView<Pricing>, st: State): PricingPanelParams | null =>
    view.focusId ? { pricingId: view.focusId } : { pricingId: st.simu.activePricing.id }

const PriceInfo = observer((p: PricingPanelParams) => {
    const st = useSt()
    const pricing = st.simu.Pricing.get(p.pricingId)
    return (
        <div>
            <h1>{pricing.name}</h1>
            <ModelFormUI
                model={pricing}
                submitLabel="Update"
                // debug
                onSubmit={(d) => {
                    const next = { ...d, id: st.simu.getRID() }
                    console.log({ next })
                    const p = new Pricing(st.simu, next)
                    st.simu.activePricing = p
                }}
            />
        </div>
    )
})
