import type { Asserts } from 'yup'
import type { Simulation } from '../state/simulation'
import type { Mission } from './mission'
import type { Vertiport } from './vertiport'
import type { Operator } from './operator'
import type { Drone } from './drone'

import { Model, s } from './schema'
import { computed, makeObservable, observable } from 'mobx'
import { renderDuration, renderTime } from '../utils/renderTime'
import { icons } from '../components/iconUI'
import { turf } from '../state/turf'
import { formatMoney } from '../fakes/chunk'
import { renderFullMonth } from '../utils/fullMonth'
import { Energy_kWh, Weight_kg } from './units'
import { randomRange } from '../utils/range'

// https://github.com/jquense/yup/issues/605 Typescript string literal support
// https://github.com/jquense/yup/issues/593 Typescript union types
//  => switch to zod later

export const ActionSchema = s.object({
    id: s._ID,
    kind: s.any, // TODO : what should we do here ?
    at: s.num, // number of hours (% 24)
    missionId: s._ID,
    vertiportId: s._ID,
    durationMS: s.num,
})
export type ActionData = Asserts<typeof ActionSchema>
export interface Action extends ActionData {
    kind: ActionKind
}

const HOUR = 3_600_000 //ms
const MIN = 60_000 // ms

export class Action extends Model<typeof ActionSchema> {
    reportedDoneByAgent: boolean = false
    needAgentIntervention = () => {
        return (
            this.kind.type === 'cleanSensor' || //
            this.kind.type === 'repair' ||
            this.kind.type === 'load' ||
            this.kind.type === 'unload'
        )
    }

    constructor(simulation: Simulation, data: ActionData) {
        super(simulation, ActionSchema, data, 'Action')
        makeObservable(this, {
            ...this.observability,
            month: computed,
            mission: computed,
            vertiport: computed,
            vertiportName: computed,
            operator: computed,
            operatorName: computed,
            drone: computed,
            droneId: computed,
            droneName: computed,
            price: computed,
            reportedDoneByAgent: observable,
        })
    }

    get infos(): string {
        return Object.entries(this.kind)
            .map(([k, v]) => {
                if (k === 'type') return ''
                return `${k}=${v}`
            })
            .filter(Boolean)
            .join(', ')
    }
    get month(): string { return renderFullMonth(this.at) } // prettier-ignore
    get atTime() { return renderTime(this.at) } // prettier-ignore
    get activity(){ return this.kind.type } // prettier-ignore

    get activityShort(): string {
        return shortActivityName(this.kind.type)
        // const k = this.kind.type
        // if (k === 'cleanSensor') return 'clean'
        // if (k === 'rechargeBattery') return 'recharge'
        // if (k === 'storeGoods') return 'store'
        // if (k === 'fly') return 'approach'
        // return k
    }

    get mission(): Mission { return this.simulation.Mission.get(this.missionId) } // prettier-ignore
    get vertiport(): Vertiport { return this.simulation.Vertiport.get(this.vertiportId) } // prettier-ignore
    get vertiportName(): string { return this.vertiport.name } // prettier-ignore
    get operator(): Operator { return this.mission.drone.operator } // prettier-ignore
    get operatorName(): string { return this.mission.drone.operator.name } // prettier-ignore
    get operatorId(): number { return this.mission.drone.operatorId } // prettier-ignore
    get drone():Drone{return this.mission.drone} // prettier-ignore
    get droneName():string{return this.mission.drone.name} // prettier-ignore
    get droneId():number{return this.mission.droneId} // prettier-ignore

    /** return flight time (time spend in the air) */
    get flightDurationMS(): number {
        const k = this.kind
        if (k.type === 'fly') return this.durationMS
        if (k.type === 'land') return this.durationMS
        if (k.type === 'takeoff') return this.durationMS
        return 0
    }

    /** return flight distance in meters */
    get distance(): number {
        const k = this.kind
        return flightDistanceFor(k)
    }

    get statusIcon(): React.ReactNode {
        const finished = this.at < this.simulation.clock.now
        if (finished) return icons.Success
        return icons.Scheduled
    }

    get icon(): React.ReactNode {
        return actionTypeIcon(this.kind.type)
    }

    get durationFormatted(): string {
        return renderDuration(this.durationMS)
    }
    get priceFormatted(): string {
        return formatMoney(this.price)
    }
    get price(): number {
        const k = this.kind
        if (k.type === 'fly') return 0
        const unitPrice = this.simulation.activePricing[k.type]
        if (k.type === 'flyBy') return unitPrice
        if (k.type === 'takeoff') return unitPrice
        if (k.type === 'land') return unitPrice
        if (k.type === 'park') return unitPrice * (this.durationMS / HOUR)
        if (k.type === 'load') return unitPrice * k.nbKilo
        if (k.type === 'unload') return unitPrice * k.nbKilo
        if (k.type === 'storeGoods')
            return unitPrice * (this.durationMS / HOUR) * k.nbKilo
        if (k.type === 'rechargeBattery') return unitPrice * k.nbKW
        if (k.type === 'repair') return unitPrice
        if (k.type === 'cleanSensor') return unitPrice
        if (k.type === 'queue') return unitPrice * (this.durationMS / HOUR)
        return exhaust(k)
    }

    get asLog(): string {
        return [
            `[${this.atTime}]`,
            `vertiport=${this.vertiportId}`,
            `mission=${this.mission.name}`,
            `drone=${this.mission.drone.name}`,
            `event=${JSON.stringify(this.kind)}`,
        ].join(' ')
    }
}

export const exhaust = (x: never): never => {
    throw new Error('case missing')
}

export const actionTypeIcon = (k: ActionKind['type']) => {
    if (k === 'flyBy') return icons.Flight
    if (k === 'land') return icons.Landing
    if (k === 'takeoff') return icons.TakeOff

    if (k === 'load') return icons.UploadParcel
    if (k === 'unload') return icons.DownloadParcel
    if (k === 'storeGoods') return icons.StoreGoods

    if (k === 'rechargeBattery') return icons.Charging
    if (k === 'repair') return icons.Repair
    if (k === 'cleanSensor') return icons.CleanSensors

    if (k === 'park') return icons.Parking
    if (k === 'queue') return icons.Clock
    if (k === 'fly') return icons.Flight
    return exhaust(k)
}

export const possibleActionTypes: ActionKind['type'][] = [
    'flyBy',
    'land',
    'takeoff',
    //
    'load',
    'unload',
    'storeGoods',
    //
    'rechargeBattery',
    'repair',
    'cleanSensor',
    //
    'park',
    'queue',
]

// export const possibleActionKindTypes = (): ActionKind[] => {
//     const out: ActionKind[] = []
//     for (let k of actionTypes) {
//         switch (k) {
//             case 'park':
//                 out.push({ type: k, nbHours: 0 })
//                 break
//             case 'load':
//             case 'unload':
//                 out.push({ type: k, nbKilo: 0 })
//                 break
//             case 'storeGoods':
//                 out.push({ type: k, nbKilo: 0, nbHour: 0 })
//                 break
//             case 'rechargeBattery':
//                 out.push({ type: k, nbKW: 0 })
//                 break
//             case 'queue':
//                 out.push({ type: k, nbHours: 0 })
//                 break
//             default:
//                 out.push({ type: k })
//         }
//     }

//     return out
// }

// yup union
export type ActionKind =
    | { type: 'flyBy' }
    /** Fixed price (0.99 €) */
    | { type: 'land' }
    /** Fixed price (0.99 €) */
    | { type: 'takeoff' }

    /** regular fly (0 €) */
    | {
          type: 'fly'
          // from
          lat0: number
          lon0: number
          // to
          lat1: number
          lon1: number
      }

    /** Kilo of package (1.50 € / kilo) */
    | { type: 'load'; nbKilo: Weight_kg }
    /** Kilo of package (1.50 € / kilo) */
    | { type: 'unload'; nbKilo: Weight_kg }
    /** Kilo and Hour (0.75 € / kilo / hour) */
    | { type: 'storeGoods'; nbKilo: Weight_kg }

    /**  (kW consumed 0.40 € / kW) */
    | { type: 'rechargeBattery'; nbKW: Energy_kWh }
    /** - Change of propeller (Fixed price 12 €) */
    | { type: 'repair' }
    /** Fixed price (11 €) */
    | { type: 'cleanSensor' }

    /** Hour (1.20 € / hour) */
    | { type: 'park' }
    /** Queue (free) */
    | { type: 'queue' }

export const activityTemplate = (type: ActionKind['type']): ActionKind => {
    if (type === 'flyBy') return { type: 'flyBy' }
    if (type === 'land') return { type: 'land' }
    if (type === 'takeoff') return { type: 'takeoff' }

    if (type === 'load') return { type: 'load', nbKilo: 1 }
    if (type === 'unload') return { type: 'unload', nbKilo: 1 }
    if (type === 'storeGoods') return { type: 'storeGoods', nbKilo: 1 }

    if (type === 'rechargeBattery') return { type: 'rechargeBattery', nbKW: 1 }
    if (type === 'repair') return { type: 'repair' }
    if (type === 'cleanSensor') return { type: 'cleanSensor' }

    if (type === 'park') return { type: 'park' }
    if (type === 'queue') return { type: 'queue' }
    if (type === 'fly') return { type: 'fly', lat0: 0, lon0: 0, lat1: 0, lon1: 0 }
    return exhaust(type)
}

/** default action duration */
export const defaultDuration = (type: ActionKind['type']): number /* ms */ => {
    if (type === 'flyBy') return 0 // (FLY-OVER)
    if (type === 'land') return 1 * MIN
    if (type === 'takeoff') return 1 * MIN

    if (type === 'load') return 2 * MIN
    if (type === 'unload') return 2 * MIN
    if (type === 'storeGoods') return 2 * MIN

    if (type === 'rechargeBattery') return 5 * MIN // randomRange(15, 40) * MIN // TODO: define this per drone;
    if (type === 'repair') return 120 * MIN
    if (type === 'cleanSensor') return 5 * MIN

    if (type === 'park') return randomRange(5, 600) * MIN

    // --------------------------------
    if (type === 'queue') return 30 * MIN // TODO: ignore (remove this)
    if (type === 'fly') return 1 * MIN // TODO: ignore (remove this)
    return exhaust(type)
}

export const shortActivityName = (type: ActionKind['type']) => {
    if (type === 'cleanSensor') return 'clean'
    if (type === 'rechargeBattery') return 'recharge'
    if (type === 'storeGoods') return 'store'
    if (type === 'fly') return 'fly to'
    if (type === 'flyBy') return 'fly over'
    return type
}

export const editableKeys = (k: ActionKind): string[] => {
    if (k.type === 'load') return ['nbKilo']
    if (k.type === 'rechargeBattery') return ['nbKW']
    return []
}

export const flightDistanceFor = (k: ActionKind): number => {
    if (k.type !== 'fly') return 0
    return turf.distance(
        //
        turf.point([k.lon0, k.lat0]),
        turf.point([k.lon1, k.lat1]),
        { units: 'meters' },
    )
}
