import { point, featureCollection, multiLineString, lineString } from '@turf/helpers'
import distance from '@turf/distance'
import buffer from '@turf/buffer'
import center from '@turf/center'

export const turf = {
    multiLineString,
    lineString,
    distance,
    /** https://turfjs.org/docs/#point
     * [longitude, latitude]
     */
    point,
    buffer,
    center,
    featureCollection,
}

// https://stackoverflow.com/questions/45987213/polygon-around-polyline-with-radius
// http://turfjs.org/docs/#buffer
