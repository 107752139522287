// https://recharts.org/en-US/examples/SimpleBarChart
import groupBy from 'lodash/groupBy'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    // ResponsiveContainer,
} from 'recharts'
import { useSt } from '../state/context'

// const data = [
//     { name: 'Page A', uv: 4000, amt: 2400 },
//     { name: 'Page B', uv: 3000, amt: 2210 },
//     { name: 'Page C', uv: 2000, amt: 2290 },
//     { name: 'Page D', uv: 2780, amt: 2000 },
//     { name: 'Page E', uv: 1890, amt: 2181 },
//     { name: 'Page F', uv: 2390, amt: 2500 },
//     { name: 'Page G', uv: 3490, amt: 2100 },
// ]

export const ProfitsByActivity = () => {
    const st = useSt()
    const data = Object.entries(groupBy(st.simu.Action.rows, (a) => a.activity)).map(
        ([k, v]) => ({
            name: k,
            amount: v.reduce((p, c) => p + c.price, 0),
        }),
    )
    return <GraphUI data={data} />
}
export const ProfitsByOperator = () => {
    const st = useSt()
    const data = Object.entries(groupBy(st.simu.Action.rows, (a) => a.operatorName)).map(
        ([k, v]) => ({
            name: k,
            amount: v.reduce((p, c) => p + c.price, 0),
        }),
    )
    return <GraphUI data={data} />
}

export const ProfitsByMonth = () => {
    const st = useSt()
    // const currentMonth = st.simu.clock.fullMonth
    const data: {
        fullMonth: string
        amount: number
        forecast?: number
    }[] = Object.entries(groupBy(st.simu.Action.rows, (a) => a.month)) //
        .map(([k, v]) => ({
            fullMonth: k,
            amount: v.reduce((p, c) => p + c.price, 0),
        }))
        .sort((a, b) => a.fullMonth.localeCompare(b.fullMonth))

    if (data.length > 1)
        data[data.length - 1].forecast =
            (data[data.length - 2].amount - data[data.length - 1].amount) * 1.2
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={700}
                height={300}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fullMonth" />
                <YAxis
                    //
                    label="euros"
                    width={200}
                    height={150}
                    orientation="right"
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" stackId="a" fill="#82ca9d" />
                <Bar dataKey="forecast" stackId="a" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    )
}

const GraphUI = (p: {
    data: {
        name: number | string
        amount: number | string
    }[]
}) => (
    <ResponsiveContainer width="100%">
        <div style={{ background: '#FFFFFF' }}>
            <BarChart
                width={500}
                height={300}
                data={p.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                style={{ margin: '0 auto' }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                    label={{
                        value: 'euros',
                        position: 'left',
                        angle: -90,
                        dy: -20,
                        dx: -5,
                    }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="amount" fill="#82ca9d" />
            </BarChart>
        </div>
    </ResponsiveContainer>
)

// export const ProfitsByOperator = () => {
//     const st = useSt()
//     return (
//         <ResponsiveContainer width="100%" height="100%">
//             <BarChart
//                 width={500}
//                 height={300}
//                 data={data}
//                 margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//             >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Bar dataKey="pv" fill="#8884d8" />
//                 <Bar dataKey="uv" fill="#82ca9d" />
//             </BarChart>
//         </ResponsiveContainer>
//     )
// }
