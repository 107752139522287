import type { State } from '../state/state'
import type { GridCol, GridProps, GridView } from './gridTypes'
import type { RID } from '../core/schema'

import rowGrouper from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import { Column, GroupFormatterProps, SortColumn, SortDirection } from 'react-data-grid'
import { computed, makeAutoObservable } from 'mobx'
import { HeaderUI } from './headerUI'
import { createElement, PropsWithChildren } from 'react'
import { icons } from '../components/iconUI'

// const alwayTrue = () => true
// const mkAutoOpenV1 = () =>
//     new Proxy(new Set<any>(), {
//         get(self, p) {
//             console.log('p', p)
//             if (p === 'has') return alwayTrue
//             // if (p === 'delete') return self.add
//             // if (p === 'add') return self.delete
//             // @ts-ignore
//             return self[p]
//         },
//     })

export class GridState<
    //
    Model extends { id: RID },
    DetailsProps extends object,
> {
    at: string | null
    focusId: RID | null
    groupBy: string[] // keyof Model
    sortBy: string | null // keyof Model
    sortDir: SortDirection
    filters: { [name: string]: string }
    groups: string[]

    constructor(
        //
        public st: State,
        public p: GridProps<Model, DetailsProps>,
    ) {
        // console.log('re-creating GRID')
        // this.view = p.view || {}
        const view = p.view || {}
        this.at = view.at ?? null
        this.focusId = view.focusId ?? null
        this.groupBy = view.groupBy ?? []
        this.sortBy = view.sortBy ?? null
        this.sortDir = view.sortDir ?? 'DESC'
        this.filters = view.filters ?? {}
        this.groups = view.groups ?? []
        this.expandedGroupIds = new Set<string>(this.groups || [])
        // this.expandedGroupIds = mkAutoOpenV1()
        makeAutoObservable(this, { cols: computed })
        // expandedGroupIds: false,
        if (p.autoOpen) this.expandedGroupIds.has = () => true
    }

    download = async () => {
        const ExcellentExport = await (await import('excellentexport')).default
        ExcellentExport.convert(
            {
                format: 'xlsx',
                filename: `export-${Math.random()}`,
                openAsDownload: true,
            },
            [
                {
                    name: 'DFP export',
                    from: {
                        // prettier-ignore
                        array:[
                    [1,2],
                    [3,5],
                ],
                    },
                },
            ],
        )
    }
    // VIEW INFOS
    // view: GridView<Model>
    get view2(): GridView<Model> {
        // 1. gen a minimalist filter object
        const filters: { [key: string]: string } = {}
        let hasFilter = false
        for (let [k, v] of Object.entries(this.filters)) {
            if (v.length === 0) continue
            hasFilter = true
            filters[k] = v
        }

        return {
            at: this.at ? this.at : undefined,
            focusId: this.focusId ? this.focusId : undefined,
            groupBy: this.groupBy.length ? this.groupBy : undefined,
            sortBy: this.sortBy?.length ? this.sortBy : undefined,
            sortDir: this.sortDir ? this.sortDir : undefined,
            // filters: Object.keys(this.filters).length ? this.filters : undefined,
            filters: hasFilter ? filters : undefined,
            groups: this.groups.length ? this.groups : undefined,
        }
    }
    syncView = () => this.p.syncURL?.(this.view2, this.st)

    get sortCol(): SortColumn[] | undefined {
        const columnKey = this.sortBy
        const direction = this.sortDir || 'DESC'
        if (columnKey) return [{ columnKey, direction }]
        return undefined
    }
    expandedGroupIds: Set<string>
    toggleGroupFor = (fieldName: string) => {
        if (this.groupBy.includes(fieldName))
            this.groupBy.splice(this.groupBy.indexOf(fieldName), 1)
        else this.groupBy.push(fieldName)
        console.log('a', JSON.stringify(this.groupBy))
        this.syncView()
    }

    selectedRowsIds = new Set<RID>() //new Array(10_000).map((_, ix) => 2 * ix))
    selectRow = (focusId: RID) => {
        this.focusId = focusId
        this.syncView()
    }

    // FILTERS
    bench = <X extends any>(label: string, fn: () => X): X => {
        const startAt = Date.now()
        const out = fn()
        const endAt = Date.now()
        console.log(`[grid] ${label} took ${endAt - startAt}ms`)
        return out
    }

    get paneProps() { return this.p.syncPane(this.view2, this.st) } // prettier-ignore
    get allRows(): Model[] {
        return this.bench('fetching all rows', () => this.p.rows(this.st))
    }
    get rows(): Model[] {
        const filters = this.filters
        if (filters == null) return this.allRows
        // const startAt = Date.now()
        const out = this.allRows.filter((row: any, i: number) => {
            for (let columnName in filters) {
                const filter = filters[columnName]
                if (filter) {
                    // TODO: this is slow code
                    const data = row[columnName]

                    if (typeof data === 'number') {
                        const valid = data.toString() === filter
                        if (!valid) return false
                    }
                    if (typeof data === 'string') {
                        const valid = data.includes(filter)
                        if (!valid) return false
                    }
                    continue
                }
            }
            return true
        })
        // const endAt = Date.now()
        // console.log(`[grid] filtering took ${endAt - startAt}ms`)
        return out
    }

    rowGrouper = rowGrouper
    // rowGrouper: typeof rowGrouper = (col: any, it: any) =>
    //     this.bench(`groupping(${it})`, () => rowGrouper(col, it))

    get sortedRows(): Model[] {
        const s = this.sortBy
        const r = this.sortDir
        if (!(s && r)) return this.rows
        return orderBy(this.rows, [s], r === 'ASC' ? 'asc' : 'desc')
    }

    private get colsBase(): Column<Model, unknown>[] {
        // console.log('re-computing cols')
        return this.p.cols(this.st, this)
    }
    get cols(): Column<Model, unknown>[] {
        console.log('re-computing headers')
        return this.colsBase.map(
            (col: GridCol<Model>): Column<Model, unknown> => ({
                ...col,
                width: col.width ?? 150,
                headerRenderer: (p) => <HeaderUI grid={this} col={col} />,
                groupFormatter: (p) => ggg(p, col, this),
            }),
        )
    }
}

const ggg = <Model extends { id: RID }>(
    //
    p: PropsWithChildren<GroupFormatterProps<Model, unknown>>,
    col: GridCol<Model>,
    self: GridState<Model, any>,
) => {
    if (col.groupFormatter) return createElement(col.groupFormatter, p)
    const gb = self.groupBy
    if (!gb) return null
    if (gb.includes(p.column.key))
        return (
            <span style={{ display: 'flex', textOverflow: 'ellipsis' }}>
                {p.isExpanded ? icons.ArrowDown : icons.ArrowRight}
                {p.groupKey as any}
            </span>
        )
    return (
        <div
            className="s100"
            onClick={() => {
                const customGroupClick = self.p.onGroupClick
                const fstGroupRow = p.childRows[0]
                if (customGroupClick) {
                    // const gg: any = {}
                    // gb.forEach((k) => (gg[k] = fstGroupRow[k]))
                    customGroupClick(self, fstGroupRow)
                } else {
                    self.focusId = fstGroupRow.id
                }
                console.log(`coucou ${gb?.join(',')}`)
            }}
        ></div>
    )
    // return <span className="text-superlight">{p.childRows.length} values</span>
}
