import * as L from 'react-leaflet'
import { mapboxRvionToken } from '../secrets'
import { themeMgr } from '../theme'
import { useMemo } from 'react'
export const googleStreets = (
    <L.TileLayer
        url={'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'}
        maxZoom={20}
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        attribution="google"
    />
)
export const googleHybrid = (
    <L.TileLayer
        url={'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'}
        maxZoom={20}
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        attribution="google"
    />
)
export const googleSat = (
    <L.TileLayer
        url={'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'}
        maxZoom={20}
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        attribution="google"
    />
)
export const googleTerrain = (
    <L.TileLayer
        url={'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'}
        maxZoom={20}
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        attribution="google"
    />
)

// export const googleMaps = (
//     <L.TileLayer
//         maxZoom={20}
//         attribution="google"
//         subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
//         url={'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'}
//     />
// )

export const mapbox = (
    <L.TileLayer
        // rvion/ckqkngqtj0iet19lc5oy3m7oa
        attribution="mapbox"
        url={
            'https://api.mapbox.com/styles/v1/rvion/ckqkngqtj0iet19lc5oy3m7oa/tiles/{z}/{x}/{y}?access_token=' +
            mapboxRvionToken
        }
    />
)

// https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer
// prettier-ignore
export const arcGISTopo = (
    <L.TileLayer
        attribution="ArcGIS World_Topo_Map"
        url={ 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}' }
    />
)

// prettier-ignore
export const arcGISGray = (
    <L.TileLayer
    maxZoom={16}
    attribution="ArcGIS World_Dark_Gray_Base "
    url={ 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}' }
/>

)

export const CarteLayersCtrlUI = (p: { preferGGHybrid?: boolean }) => {
    // eslint-disable-next-line
    const isDark = useMemo(() => themeMgr.isDark(), [themeMgr.toggledAt])
    const hybrid = Boolean(p.preferGGHybrid)
    return (
        <L.LayersControl position="topright">
            <L.LayersControl.BaseLayer
                checked={!isDark && !hybrid}
                name="Rémi Vion custom MapBox Frank layer"
            >
                {mapbox}
            </L.LayersControl.BaseLayer>
            <L.LayersControl.BaseLayer name="googleStreets">
                {googleStreets}
            </L.LayersControl.BaseLayer>
            <L.LayersControl.BaseLayer
                //
                checked={hybrid}
                name="googleHybrid"
            >
                {googleHybrid}
            </L.LayersControl.BaseLayer>
            <L.LayersControl.BaseLayer name="googleSat">
                {googleSat}
            </L.LayersControl.BaseLayer>
            <L.LayersControl.BaseLayer name="googleTerrain">
                {googleTerrain}
            </L.LayersControl.BaseLayer>

            <L.LayersControl.BaseLayer name="ArcGIS World_Topo_Map">
                {arcGISTopo}
            </L.LayersControl.BaseLayer>

            <L.LayersControl.BaseLayer
                checked={isDark && !hybrid}
                name="ArcGIS World_Dark_Gray_Base "
            >
                {arcGISGray}
            </L.LayersControl.BaseLayer>

            <L.LayersControl.Overlay name="plus code grid">
                <L.TileLayer
                    tms
                    attribution='grid by <a href="https://plus.codes">plus codes</a>'
                    url={'https://grid.plus.codes/grid/tms/{z}/{x}/{y}.png'}
                />
            </L.LayersControl.Overlay>
            <L.LayersControl.Overlay name="restrictions-uas-categorie-ouverte-et-aeromodelisme">
                <L.TileLayer
                    tms
                    maxZoom={13}
                    attribution="Geoportail.gouv.fr"
                    url={
                        'https://services.esrifrance.fr/arcgis' +
                        '/tokenaccess/wXhYMF3L04R4rktS4htNUAkJd6ErmFnV71QDhIqhpuX0w6Wi5chtpI2OpXmpuSpsmBunOpxxjQBtVBVBGkmbVRSvy1QYZIdLwYMnrEnTkeFSkTeikDF4ag--' +
                        '/rest/services/Geoportail/TRANSPORTS.DRONES.RESTRICTIONS/MapServer/tile/{z}/{y}/{x}?token=wXhYMF3L04RBWjYmWiALyZgjCz7W3vIq0P*sjy9M71LiecFhPHuNGzJTd35ZPb7u..i2R8oiXJMTSoAF6d231z9odog45scToZJ2pRoTKUQMGqDISdYZ0sofPs6DdFRiRpRlhSHPiyjrD8wJofVeRFcz0Ly*W0x85UzvBWXpbOeYtdZpTU7Vu8p4XYyPNKwNLO3Wq2P5x8uLpupvgDaNdug90av.9aNAeysqUEfMFPrBZqYdidzaqrTWBkhe9VVOx7lPP9lB4ZqkBrOhiPAll12jH1BM2Lkm1BHoKN5qrZaMXYr1iSAf0y4kYQN0ItyEt*cFrcv30u7cafagFbMpB6m5Xu3IEzgNUoMDqPbnsMk2j0MGCBPDhGOoFLbEIGrYPiGD7jm2uCU-'
                    }
                />
            </L.LayersControl.Overlay>
            {/* <L.LayersControl.Overlay name="restrictions-uas-categorie-ouverte-et-aeromodelisme">
        <L.TileLayer
            tms
            maxZoom={13}
            attribution="Geoportail.gouv.fr"
            url={
                // 'https://services.esrifrance.fr/arcgis' +
                'https://services.esrifrance.fr/arcgis/rest/services/Geoportail/TRANSPORTS.DRONES.RESTRICTIONS/MapServer/tile/{z}/{y}/{x}' +
                // '?token=wXhYMF3L04RBWjYmWiALyZgjCz7W3vIq0P*sjy9M71LiecFhPHuNGzJTd35ZPb7u..i2R8oiXJMTSoAF6d231z9odog45scToZJ2pRoTKUQMGqDISdYZ0sofPs6DdFRiRpRlhSHPiyjrD8wJofVeRFcz0Ly*W0x85UzvBWXpbOeYtdZpTU7Vu8p4XYyPNKwNLO3Wq2P5x8uLpupvgDaNdug90av.9aNAeysqUEfMFPrBZqYdidzaqrTWBkhe9VVOx7lPP9lB4ZqkBrOhiPAll12jH1BM2Lkm1BHoKN5qrZaMXYr1iSAf0y4kYQN0ItyEt*cFrcv30u7cafagFbMpB6m5Xu3IEzgNUoMDqPbnsMk2j0MGCBPDhGOoFLbEIGrYPiGD7jm2uCU-'
            }
        />
    </L.LayersControl.Overlay> */}
            {/* https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=TRANSPORTS.DRONES.RESTRICTIONS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=11&TileCol=1054&TileRow=703 */}
        </L.LayersControl>
    )
}
