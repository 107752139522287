import type { State } from '../state/state'
import type { Page } from '../layout/pages'
import type { Drone, DroneData } from '../core/drone'
import type { GridCol, GridView } from '../grid/gridTypes'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { OperatorPageUI } from './operatorPage'
import { DroneSchema } from '../core/drone'
import { DronesPageUI } from './dronesPage'
import { useSt } from '../state/context'
import { GridUI } from '../grid/gridUI'
import { RID } from '../core/schema'

export type DronesListParams = {
    className?: string
    onlyFlying?: boolean
} & GridView<DroneData>

export const DronesListPage = (): Page<DronesListParams> => ({
    id: 'dronesList' as const,
    icon: 'Drone' as const,
    path: '/drones',
    label: 'Drones',
    Component: DronesListPageUI,
})

type PaneProps = { id: RID }
const syncPane = (view: GridView<DroneData>, st: State): PaneProps | null => {
    const fid = view.focusId
    if (fid == null) return null
    return { id: fid }
}

export const DronesListPageUI = observer((p: DronesListParams) => {
    const st = useSt()

    return (
        <div className="grow col">
            <GridUI<Drone, PaneProps>
                rows={(st: State) =>
                    p.onlyFlying //
                        ? st.simu.flyingDrones
                        : st.simu.Drone.rows
                }
                cols={dronesListCols}
                schema={DroneSchema}
                autoOpen
                view={p}
                key={p.at}
                syncURL={(view, st) => st.goTodronesListPage({ ...p, ...view })}
                syncPane={syncPane}
                onGroupClick={(st, g) => {
                    console.log(g)
                    if (g.operatorId) st.focusId = g.operatorId
                }}
                PaneUI={(p: PaneProps) =>
                    st.simu.Drone.byId.has(p.id) ? ( //
                        <DronesPageUI droneId={p.id} />
                    ) : (
                        <OperatorPageUI operatorId={p.id} />
                    )
                }
                title="Drone"
            >
                <ButtonGroup variant="outlined" aria-label="drone group button">
                    <Button onClick={() => st.goTodronesListPage({ at: 'A' })}>
                        All
                    </Button>
                    <Button
                        onClick={() =>
                            st.goTodronesListPage({ at: 'B', groupBy: ['operatorName'] })
                        }
                    >
                        By operator
                    </Button>
                    <Button
                        onClick={() =>
                            st.goTodronesListPage({ at: 'C', onlyFlying: true })
                        }
                    >
                        Only flying
                    </Button>
                </ButtonGroup>
            </GridUI>
        </div>
    )
})
const sum = (arr: number[]): number => arr.reduce((prev, curr) => prev + curr, 0)
const dronesListCols = (st: State): GridCol<Drone>[] => [
    { key: 'operatorName', name: 'Operator' },
    { key: 'name', name: 'Name' },
    { key: 'brand', name: 'Brand' },
    {
        key: 'weight',
        name: 'Weight',
        groupFormatter: (a) => (
            <Fragment>{sum(a.childRows.map((item) => item.weight))}</Fragment>
        ),
    },
    // seeInMap(st),
    // { key: 'altitude', headerName: 'Altitude', width: 200 },
]

export const seeInMap = (st: State): GridCol<Drone> => ({
    key: 'pos',
    name: 'Position',
    width: 200,
    formatter: (v) => (
        <Button
            variant="text"
            color="primary"
            onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                // console.log({ lon: v.row.lon })
                // console.log({ lat: v.row.lat })
                // const x = v.row.currentState
                st.goTohomePage({
                    // lat: x.toPos[0],
                    // lon: x.toPos[1],
                })
            }}
        >
            See Map
        </Button>
    ),
})
