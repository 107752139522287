import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'

import { Pilot, PilotData, PilotSchema } from '../core/pilot'
import { GridCol, GridView } from '../grid/gridTypes'
import { GridUI } from '../grid/gridUI'
import { State } from '../state/state'

import { PilotPageParams, PilotPageUI } from './pilotPage'
import { TagUI } from '../components/tagUI'

export type PilotsListParams = {
    className?: string
} & GridView<PilotData>

export const PilotsListPage = (): Page<PilotsListParams> => ({
    id: 'pilotsList' as const,
    icon: 'Pilot' as const,
    path: '/pilots',
    label: 'Pilots',
    Component: PilotsListPageUI,
})

const syncPane = (view: GridView<Pilot>): PilotPageParams | null =>
    view.focusId ? { embedded: true, pilotId: view.focusId } : null

export const PilotsListPageUI = observer((p: PilotsListParams) => {
    return (
        <GridUI<Pilot, PilotPageParams>
            schema={PilotSchema}
            rows={(st: State) => st.simu.Pilot.rows}
            cols={pilotsListCols}
            view={p}
            syncURL={(view, st) => st.goTopilotsListPage({ ...p, ...view })}
            syncPane={syncPane}
            PaneUI={PilotPageUI}
            title="Pilots"
        />
    )
})

const pilotsListCols = (): GridCol<Pilot>[] => [
    { key: 'id', name: 'ID', width: 30 },
    { key: 'name', name: 'Name' },
    { key: 'operatorName', name: 'Operator', width: 200 },
    {
        key: 'phone',
        name: 'Phone',
        width: 120,
        formatter: (r) => {
            return <a href={`tel:${r.row.phoneClean}`}>{r.row.phoneClean}</a>
        },
    },
    {
        key: 'certification',
        name: 'Certifications',
        width: 250,
        formatter: (r) => {
            return <TagUI tags={r.row.certification} hue={quickHashCode} />
        },
    },
]

const quickHashCode = (str: string): number => {
    if (str === 'basic') return 72 * 0
    if (str === 'advanced') return 72 * 1
    if (str === 'a1') return 72 * 2
    if (str === 'a2') return 72 * 3
    if (str === 'a3') return 72 * 4
    return 72 * 4
}
