import { observer } from 'mobx-react-lite'

export const TagUI = observer(function TagUI_(p: {
    tags: string
    hue: (str: string) => number
}) {
    return (
        <div>
            {p.tags.split(',').map((c) => {
                const hue = p.hue(c)
                const col = `hsl(${hue}deg 60% 83%)`
                // console.log(col)
                return (
                    <span className="TagUI" key={c} style={{ background: col }}>
                        {c}
                    </span>
                )
            })}
        </div>
    )
})
