import type { Page } from '../layout/pages'

import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react-lite'
import * as RL from 'react-leaflet'
import { useSt } from '../state/context'
import { RID } from '../core/schema'
import { MissionTimelineUI } from '../components/missionTimelineUI'
import { OperatorSummaryUI } from './operatorPage'
import { DroneSummaryUI } from './dronesPage'
import { icons } from '../components/iconUI'
import { InvoiceUI2 } from '../components/invoice2'
import { Mission, missionStatusHue, missionTypeHue } from '../core/mission'
import { TagUI } from '../components/tagUI'
import { CarteUI } from '../carte/carteUI'
import { turf } from '../state/turf'
import { PropertiesUI } from '../components/propertiesUI'

export type MissionPageParams = {
    missionId: RID | string
    color?: 'red' | 'blue' | 'green'
    embedded?: boolean
}

export const MissionPage = (): Page<MissionPageParams> => ({
    id: 'mission' as const,
    icon: 'Mission' as const,
    nav: false,
    path: '/missions/:missionId',
    label: 'Missions',
    Component: MissionPageFullUI,
})
export const MissionPageFullUI = observer(function MissionPageFullUI_(
    p: MissionPageParams,
) {
    return (
        <div style={{ padding: '1rem', overflow: 'auto' }} className="grow">
            <MissionPageUI {...p} />
        </div>
    )
})

export const MissionKPIUI = observer(function MissionKPIUI_(p: { mission: Mission }) {
    const m = p.mission

    return (
        <PropertiesUI
            items={[
                ['Total Flight Distance', m.totalDistanceText],
                ['Total Flight Duration', m.flightDurationText],
                ['Total Mission Duration', m.beforeParkingdurationText],
                ['Total Duration', m.durationText],
            ]}
        />
    )
})

export const MissionPageUI = observer((p: MissionPageParams) => {
    const st = useSt()
    const missionId: number =
        typeof p.missionId === 'string' //
            ? parseInt(p.missionId, 10)
            : p.missionId
    const mission = st.simu.getMission(missionId)
    const operator = mission.operator
    const fullMonth = mission.fullMonth

    const center = turf.center(mission.trace)
    const [lon, lat] = center.geometry.coordinates
    return (
        <div className="grow info-page">
            <div tw="mb-6">
                <h2 className="line2">
                    {icons.Mission} {mission.fakeName()}
                </h2>
                <div className="line">
                    <TagUI hue={missionTypeHue} tags={mission.type} />
                    <TagUI hue={missionStatusHue} tags={mission.status} />
                </div>
                {/* <Chip size="small" label={mission.type} tw="mr-2" color="primary" /> */}
                {/* <Chip size="small" label={mission.status} color="secondary" /> */}
            </div>
            <section>
                <OperatorSummaryUI operator={operator} />
            </section>
            <section>
                <DroneSummaryUI droneId={mission.droneId} />
            </section>
            <section>
                <Button
                    variant="contained"
                    onClick={() =>
                        st.addPopup(`Invoice for ${operator.name}-${fullMonth}`, () => (
                            <InvoiceUI2 month={fullMonth} operatorId={operator.id} />
                        ))
                    }
                >
                    See Invoice
                </Button>
                <Button
                    tw="ml-2"
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                        st.goToactionsListPage({
                            filters: { missionId: mission.id.toString() },
                        })
                    }
                >
                    See Log
                </Button>
            </section>
            <MissionKPIUI mission={mission} />
            <CarteUI
                key={`${lat}-${lon}`}
                center={[lat, lon]}
                tw="width[100%] height[400px]"
            >
                <RL.GeoJSON
                    //
                    key={mission.id}
                    data={mission.trace}
                    style={{ weight: 10, color: 'red' }}
                />
            </CarteUI>
            <section>
                <h3>Timeline</h3>
                <MissionTimelineUI mission={mission} />
            </section>
            {/* <Stack spacing={2} tw="mt-2">
                {currentMission.actions.map((action) => (
                    <div key={action.id} className="stack-line">
                        <Typography variant="h6">{action.kind.type}</Typography>
                        <Typography tw="ml-4">{action.at}</Typography>
                    </div>
                ))}
            </Stack> */}
        </div>
    )
})

// {
//     /* <div tw="flex items-center">
//                     <Button
//                         onClick={() => st.goTomissionListPage({})}
//                         variant="contained"
//                         color="primary"
//                     >
//                         Back{' '}
//                     </Button>
//                     <OrientationToggle />
//                 </div> */
// }
// <Container
// // tw="flex-basis[50%]"
// // css={[
// //     tw`shadow-none`,
// //     st.spaceOnScreen && tw`box-shadow[-1px 1px 6px rgba(0, 0, 0, 0.1)]`,
// // ]}
// >
// </Container>
