import type { Simulation } from '../state/simulation'
import type { Asserts } from 'yup'
import { Model, RID, s } from './schema'
import { computed, makeObservable } from 'mobx'

export const PricingSchema = s.object({
    id: s._ID,
    name: s.str,
    //
    flyBy: s.num,
    takeoff: s.num,
    land: s.num,
    park: s.num,
    load: s.num,
    unload: s.num,
    storeGoods: s.num,
    rechargeBattery: s.num,
    repair: s.num,
    cleanSensor: s.num,
    queue: s.num,
})

export type PricingData = Asserts<typeof PricingSchema>

export const mkDefaultPricingData = (rid: RID): PricingData => ({
    id: rid,
    name: 'default',

    flyBy: 0.25,
    takeoff: 0.99,
    land: 0.99,
    park: 1.2,
    load: 1.5,
    unload: 1.5,
    storeGoods: 0.75,
    rechargeBattery: 0.4,
    repair: 12,
    cleanSensor: 11,
    queue: 0,
})

export interface Pricing extends PricingData {}
export class Pricing extends Model<typeof PricingSchema> {
    constructor(simulation: Simulation, data: PricingData) {
        super(simulation, PricingSchema, data, 'Pricing')
        makeObservable(this, { ...this.observability, active: computed })
    }
    get active(): boolean {
        return this.simulation.activePricing === this
    }
}
