import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'

import { CorridorInfoUI } from './panelEditGraph'
import { MapPanelUI } from './panelCSS'

export const CartePanelCorridorUI = observer(function CartePanelCorridorUI_(p: {}) {
    const st = useSt()
    const cori = st.selectedCorridor
    if (cori == null) return null
    return (
        <MapPanelUI close={() => (st.selectedCorridor = null)}>
            <CorridorInfoUI corridor={cori} />
        </MapPanelUI>
    )
})
