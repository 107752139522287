import * as L from 'leaflet'

import pinVertiBase from './vertiport1.png'
import pinVertiGray from './vertiport2.png'
import pinVertiGreen from './vertiport3.png'
import pinVertiRed from './vertiport4.png'
import pinVertiBlue from './vertiport5.png'
import pinVertiBlack from './vertiport6.png'
import pinVertiDeleted from './vertiport7.png'
import pinVertiYellow from './vertiport8.png'
import pinWaypoint from './vertiportWaypoint.png'

const D = [69, 144]

export const vertiImg = {
    base: pinVertiBase,
    gray: pinVertiGray,
    green: pinVertiGreen,
    red: pinVertiRed,
    blue: pinVertiBlue,
    black: pinVertiBlack,
    deleted: pinVertiDeleted,
    yellow: pinVertiYellow,
    waypoint: pinWaypoint,
}
export type VertiPin = keyof typeof vertiImg

const _vertiPins: any = {}

Object.keys(vertiImg).forEach((k: any) => {
    _vertiPins[k] = new L.Icon({
        iconUrl: (vertiImg as any)[k],
        // popupAnchor: [D[0] / 2, 0],
        iconAnchor: [D[0] / 4, D[1] / 2],
        iconSize: [D[0] / 2, D[1] / 2],
        className: 'marker',
    })
})

export const vertiPins: { [k in VertiPin]: L.Icon } = _vertiPins
