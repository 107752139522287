import * as RL from 'react-leaflet'
import { Icon } from 'leaflet'
import { assets } from '../assets/assets'
import { observer } from 'mobx-react-lite'
import { Area } from '../core/area'
// import { useSt } from '../state/context'
// import { BatteryIndicator } from '../components/batteryIndicator'
// import { icons } from '../components/iconUI'

export const AreaPinUI = observer(function AreaPinUI_(p: { area: Area }) {
    // const st = useSt()

    const area = p.area
    // const pilot = area.pilot
    // const phone = pilot.phone.replace(/\s/g, '')
    const currLatLng = area.center
    if (currLatLng == null) return null
    // const position: LatLngTuple = [cs.lat, cs.lon]
    return (
        <RL.Marker position={currLatLng} icon={pinArea}>
            {/* <RL.Tooltip>
                <div tw="flex items-baseline justify-between">
                    <button className="text-underline">
                        <h3 className="line2">
                            {icons.Drone} {area.name}
                        </h3>
                    </button>
                    <BatteryIndicator drone={area} />
                </div>
                <div tw="mt-2">
                    <span className="propertyName">Pilot</span>
                    <div>{pilot.name}</div>
                    <div>({phone})</div>
                </div>
            </RL.Tooltip> */}

            {/* <L.Popup>Drone {d.name}</L.Popup> */}
        </RL.Marker>
    )
})

export const pinArea = new Icon({
    iconUrl: assets.pinVertiport,
    iconAnchor: [91 / 2, 112],
    iconSize: [91, 112],
    className: 'marker',
})
