import type { Vertiport } from '../core/vertiport'
import type { CSSProperties } from 'react'
import type { Booking, VertiportStatus } from './vertiportStatus'

import Tooltip from '@material-ui/core/Tooltip'
import { observer } from 'mobx-react-lite'
import { css } from 'twin.macro'

import { renderDuration, renderHour } from '../utils/renderTime'
import { slotHeight } from './vertiportStatus'
import { defaultColor, icons } from './iconUI'
import { timeScale } from './vertiportStatus'
import { useSt } from '../state/context'

export const VertiportStatusUI = observer(function VertiportStatusUI_(p: {
    vertiport: Vertiport
}) {
    const st = useSt()
    const status = p.vertiport.status
    const slots = status.slots
    //
    return (
        <div css={vertiportStatusCSS}>
            <div className="foobar" tw="inline-flex flex-col">
                <div className="row status-grid_header">
                    <div tw="width[3rem]" />
                    <div style={{ width: status.landingsColWidth }}>
                        <div className="col-label">Takeoff Landing</div>
                        {/* <IndicatorUI val={0.5} /> */}
                        <div>
                            {icons.Landing}
                            {icons.TakeOff}
                        </div>
                    </div>
                    <div style={{ width: status.chargingsColWidth }}>
                        <div className="col-label">Recharge Battery</div>
                        {/* <IndicatorUI
                            val={status.maxSimultChargings}
                            max={status.nbChargingBay}
                        /> */}
                        <div>{icons.Charging}</div>
                    </div>
                    <div style={{ width: status.parkingsColWidth }}>
                        <div className="col-label">Parking</div>
                        {/* <IndicatorUI
                            val={status.maxSimultParkings}
                            max={status.nbParkingBay}
                        /> */}
                        <div>{icons.Parking}</div>
                    </div>
                    <div style={{ width: status.repairsColWidth }}>
                        <div className="col-label">Repair</div>
                        {/* <IndicatorUI val={status.maxSimultRepairs} max={status.nbRepairBay} /> */}
                        <div>{icons.Repair}</div>
                    </div>
                </div>
                <div
                    ref={(el) => {
                        if (el == null) return
                        el.scrollTop =
                            st.simu.clock.msSinceBeginningOfDay * timeScale - 40
                    }}
                    className="vertiport-timeline row"
                    tw="relative overflow-auto max-height[500px] inline-flex"
                >
                    <div
                        style={{
                            height: '2px',
                            backgroundColor: 'red',
                            position: 'absolute',
                            width: '100%',
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            zIndex: 1024,
                            top: st.simu.clock.msSinceBeginningOfDay * timeScale,
                        }}
                    >
                        <div style={{ transform: 'translateY(-50%)' }}>NOW</div>
                    </div>
                    {/* TIME LABEL */}
                    <div
                        className="col-time col"
                        tw="z-10"
                        style={{
                            width: '3rem',
                            height: '100%',
                            marginTop: `-${slotHeight / 2}px`,
                        }}
                    >
                        {slots.map((time, ix) => (
                            <p
                                key={time}
                                className="slot-time grow"
                                style={{ height: `${slotHeight}px` }}
                            >
                                {ix % 2 ? time : null}
                            </p>
                        ))}
                    </div>
                    {/* TIME LINES */}
                    <div className="time-lines" tw="absolute inset-0 z-0">
                        {slots.map((e) => (
                            <div key={e} style={{ height: `${slotHeight}px` }}></div>
                        ))}
                    </div>
                    {/* LANDING SLOTS */}
                    {/* <div className="time-label" tw="width[100px]">
                        {slots.map((e) => (
                            <div
                                key={e}
                                style={{
                                    height: `${status.slotHeight}px`,
                                    backgroundColor:
                                        Math.random() > 0.5
                                            ? defaultColor.Landing
                                            : undefined,
                                    width: `100px`,
                                }}
                                // className={Math.random() > 0.5 ? '' : 'slot-close'}
                            ></div>
                        ))}
                    </div> */}
                    {/* LANDING */}
                    <SlotsUI
                        vs={status}
                        bookings={status.landings}
                        style={{ width: status.landingsColWidth }}
                        color={defaultColor.Landing}
                    />
                    {/* LOADING */}
                    <SlotsUI
                        vs={status}
                        bookings={status.chargings}
                        style={{ width: status.chargingsColWidth }}
                        color={defaultColor.Charging}
                    />
                    {/* PARKING */}
                    <SlotsUI
                        vs={status}
                        bookings={status.parkings}
                        style={{ width: status.parkingsColWidth }}
                        color={defaultColor.Parking}
                    />
                    {/* REPAIR */}
                    <SlotsUI
                        vs={status}
                        bookings={status.repairs}
                        style={{ width: status.repairsColWidth }}
                        color={defaultColor.Repair}
                    />
                </div>
            </div>
        </div>
    )
})

// prettier-ignore
const vertiportStatusCSS = css`
    & .IndicatorUI { margin: auto; }
    & .vertiport-timeline * { box-sizing: border-box; }
    .status-grid_header {
        /* font-size: 12px; */
        font-weight: 600;
        text-align: center;
        display: inline-flex;
        align-items: flex-end;
    }

    .col-time { position: relative; }
    .time-lines > div {
        width: 100%;
        border-bottom: 1px solid gray;
    }
    .slot-time {
        margin: 0;
        text-align: center;
        font-weight: 500;
        font-size: 0.75rem;
        position: relative;
    }
    .col-label {width: 70px; margin:auto; }
    & .booking { cursor: pointer; }
    & .booking:hover { filter: brightness(0.8)}
    & .human-intervention svg {color: gray !important; font-size: 1rem}
    & .human-intervention { height: 20px; background: #00000042; }
    .col-cargo {
        padding: 0.5rem 0.5rem 0;
        align-self: flex-start;
        border: 1px solid black;
        border-top: none;
        font-size: 0.875rem;
        background: #eeeaea;
        p {
            margin: 0;
            &:first-child {
                font-weight: 500;
            }
        }
    }
`

// () => {
// }
export const BookingTooltip = observer((p: { booking: Booking }) => {
    const st = useSt()
    const droneName = st.simu.Drone.choose().name
    return (
        <div>
            {droneName}
            <div>{renderHour(p.booking.start)}</div>
            <div>{renderDuration(p.booking.end - p.booking.start)}</div>
        </div>
    )
})

const SlotsUI = observer(function SlotsUI_(p: {
    vs: VertiportStatus
    bookings: Booking[]
    color?: string
    className?: string
    style?: CSSProperties
}) {
    const { vs, bookings } = p
    const st = useSt()
    let extraStyles: CSSProperties = { borderLeft: `1px solid black` }
    let style: CSSProperties = p.style ? { ...p.style, ...extraStyles } : extraStyles
    return (
        <div className={'time col ' + p.className} style={style} tw="relative">
            {bookings.map((bk, idx) => {
                const margins = 5 * 60_000
                const bookingVerticalOffset = bk.start - vs.T0 + 2 - margins
                const bookingDuration = bk.end - bk.start + 2 * margins
                const action = bk.action
                return (
                    <Tooltip
                        key={idx}
                        title={<BookingTooltip booking={bk} />}
                        placement="left"
                    >
                        <div
                            onClick={
                                action
                                    ? () =>
                                          st.goTomissionPage({
                                              missionId: action.missionId,
                                          })
                                    : undefined
                            }
                            style={{
                                position: 'absolute',
                                left: `${bk.bay * vs.bayWidth}px`,
                                top: `${bookingVerticalOffset * timeScale}px`,
                                height: `${bookingDuration * timeScale}px`,
                                width: `${vs.bayWidth}px`,
                                background: p.color,
                                border: '1px solid #4f1418',
                                boxSizing: 'border-box',
                            }}
                            className="col booking"
                        >
                            {action ? '*' : null}
                            {/* {booking.humanIntervention && (
                                <div className="human-intervention">
                                    {icons.HumanIntervention}
                                </div>
                            )} */}
                            {/* <div className="grow"></div> */}
                            {/* <div className="grow">{booking.bay}</div> */}
                            {/* {booking.humanIntervention && (
                                <div className="human-intervention">
                                    {icons.HumanIntervention}
                                </div>
                            )} */}
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    )
})
