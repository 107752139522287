import type { RID } from '../core/schema'
import Button from '@material-ui/core/Button'
import { useSt } from '../state/context'

export const AddMissionBtnUI = (p: {
    label?: string
    droneId?: RID
    operatorId?: RID
    vertiportId?: RID
    size?: 'small'
}) => {
    const st = useSt()
    return (
        <Button
            size={p.size}
            variant="contained"
            onClick={(ev) => {
                ev.preventDefault()
                ev.stopPropagation()
                st.goToaddMissionPage({
                    droneId: p.droneId,
                    operatorId: p.operatorId,
                    vertiportId: p.vertiportId,
                })
                // st.addPopup('Add Mission', () => (
                //     <AddMissionFormUI
                //         droneId={p.droneId}
                //         operatorId={p.operatorId}
                //         vertiportId={p.vertiportId}
                //     />
                // ))
            }}
        >
            {p.label ?? 'Add'}
        </Button>
    )
}
