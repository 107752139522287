import type { Simulation } from '../state/simulation'
import type { Asserts } from 'yup'
import type { Mission } from './mission'
import type { Action } from './action'
import type { Operator } from './operator'

import { Model, s } from './schema'
import { computed, makeObservable } from 'mobx'
import { LatLngTuple } from 'leaflet'

// export const SPEED_AS_METER_PER_SECOND = 3.61111 // 5 //12.5
// export const SPEED_AS_KM_PER_HOUR = 13 // 18 // 45
export const SPEED_AS_METER_PER_SECOND = 12.5
export const SPEED_AS_KM_PER_HOUR = 45

export const flightDurationMs = (meters: number): number => {
    const seconds = meters / SPEED_AS_METER_PER_SECOND
    return seconds * 1000
}
export const DroneSchema = s.object({
    id: s._ID,
    name: s.str,
    brand: s.str,
    weight: s.num,
    operatorId: s._ID,
    missionIds: s._IDS, // 🔄 denormalized data
    model: s.str,
    class: s.str,
    scenario: s.str,
    battery: s.num,
    minutesRemaining: s.num,
    batteryHealth: s.num,
    minutesTotal: s.num,
    hoursBeforeRevision: s.num,
    fake: s.bool,
})

export type DroneData = Asserts<typeof DroneSchema>
export type CurrentActions = { curr: Action | null; prev: Action | null }
export interface Drone extends DroneData {}
export class Drone extends Model<typeof DroneSchema> {
    constructor(simulation: Simulation, data: DroneData) {
        super(simulation, DroneSchema, data, 'Drone')
        makeObservable(this, {
            ...this.observability,
            actions: computed,
            missions: computed,
            operator: computed,
            currentLatLng: computed,
            currentMission: computed,
            currentAction: computed,
            isFlying: computed,
        })
    }

    // get pilot():Pilot{return this.simulation.Pilot.get(this.pilotId)} // prettier-ignore
    get missions(): Mission[] { return this.simulation.Mission.getMany(this.missionIds).sort((a,b) => a.startAt-b.startAt) } // prettier-ignore
    get operator(): Operator { return this.simulation.Operator.get(this.operatorId) } // prettier-ignore
    get actions(): Action[] { return this.missions.flatMap(m => m.actions) } // prettier-ignore
    get operatorName():string { return this.operator.name } // prettier-ignore
    get pastMissions(): Mission[] { return this.missions.filter(m => m.endAt< this.simulation.clock.now) } // prettier-ignore
    get futureMissions(): Mission[] { return this.missions.filter(m => m.startAt > this.simulation.clock.now) } // prettier-ignore
    get missionByTimeWindow(): {
        past: Mission[]
        current: Mission[]
        future: Mission[]
    } {
        const past: Mission[] = []
        const current: Mission[] = []
        const future: Mission[] = []
        let at = this.simulation.clock.now
        for (let m of this.missions) {
            if (m.endAt < at) past.push(m)
            else if (m.startAt > at) future.push(m)
            else current.push(m)
        }
        return { past, current, future }
    }

    get currentLatLng(): LatLngTuple | null {
        const action = this.currentAction
        if (action == null) return null
        const now = this.simulation.clock.now
        const t0 = action.at
        const percent = (now - t0) / action.durationMS
        const k = action.kind
        if (k.type === 'fly') {
            // console.log(percent, k, now - t0, action.durationMS)
            const lat = k.lat0 + (k.lat1 - k.lat0) * percent
            const lon = k.lon0 + (k.lon1 - k.lon0) * percent
            return [lat, lon]
        } else {
            return action.vertiport.pos
        }
    }

    getCurrentMissionAt = (at: number): Mission | null => {
        const m = this.missions.find((m) => m.startAt <= at && m.endAt >= at)
        if (m == null) return null
        return m
    }

    get currentMission(): Mission | null {
        const now = this.simulation.clock.now
        return this.getCurrentMissionAt(now)
    }

    get currentAction(): Action | null {
        const mission = this.currentMission
        if (mission == null) return null
        const now = this.simulation.clock.now
        for (let i = 1; i < mission.actions.length; i++)
            if (mission.actions[i].at > now) return mission.actions[i - 1]
        return null
        // return mission.actions.find((a) => a.at > now) || null
    }

    get isFlying() {
        const action = this.currentAction
        if (action == null) return false
        const activity = action.kind.type
        if (activity === 'land') return true
        if (activity === 'flyBy') return true
        if (activity === 'takeoff') return true
        if (activity === 'fly') return true
        return false
    }
}
