import { observer } from 'mobx-react-lite'

import { defaultColor } from '../../components/iconUI'
import { addableServices } from '../serviceList'
import { cls } from '../../utils/cls'
import Button from '@material-ui/core/Button'

export const DraftHeaderUI = observer(function DraftHeaderUI_(p: {
    label?: string
    icons?: boolean
    onClick?: () => void
}) {
    return (
        <div onClick={p.onClick} className="line3" tw="relative">
            {/* <div tw="height[2rem]"></div> */}
            <div className="ac-picker">
                <Button variant="outlined">{p.label}</Button>
            </div>
            {addableServices.slice(2).map((as) => {
                return (
                    <div
                        key={as.label}
                        className={cls('service-label', `service-${as.activity}`)}
                        style={as.width ? { width: as.width } : undefined}
                    >
                        {/*  */}
                        <div
                            tw="absolute"
                            style={{ zIndex: 9999, color: as.color }}
                            className="service-label-text"
                        >
                            {as.label}
                            {(defaultColor as any)[as.activity]}
                        </div>
                        {p.icons && as.icon}
                    </div>
                )
            })}
        </div>
    )
})
