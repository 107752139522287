import type { GridCol, GridView } from '../grid/gridTypes'
import type { GridState } from '../grid/gridState'
import type { State } from '../state/state'
import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'
import { Fragment, useState } from 'react'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { Action, ActionData, ActionSchema } from '../core/action'
import { InvoiceUI2 } from '../components/invoice2'
import { renderTime } from '../utils/renderTime'
import { formatMoney } from '../fakes/chunk'
import { GridUI } from '../grid/gridUI'
import { useSt } from '../state/context'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

export type InvoicesPageParams = {
    className?: string
} & GridView<ActionData>

export const InvoicesPage = (): Page<InvoicesPageParams> => ({
    id: 'invoices' as const,
    icon: 'Invoice' as const,
    label: 'Invoices',
    path: '/invoices',
    Component: (p) => {
        return (
            <div className="grow col">
                <InvoicesPageUI {...p} />
            </div>
        )
    },
})

export const InvoicesPageUI = observer((p: InvoicesPageParams) => {
    const st = useSt()
    return (
        <GridUI<Action, InvoiceParam>
            rows={(st: State) => st.simu.Action.rows}
            cols={actionListCols}
            schema={ActionSchema}
            view={p}
            syncURL={(view, st) => st.goToinvoicesPage({ ...p, ...view })}
            syncPane={getPaneParams}
            PaneUI={InvoicePaneUI}
            title="Invoices"
        >
            <ButtonGroup
                variant="outlined"
                aria-label="invoices group button"
                size="small"
            >
                <Button
                    onClick={() =>
                        st.goToinvoicesPage({ groupBy: ['month', 'operatorName'] })
                    }
                >
                    Paid
                </Button>
                <Button
                    onClick={() =>
                        st.goToinvoicesPage({ groupBy: ['month', 'operatorName'] })
                    }
                >
                    Pending
                </Button>
            </ButtonGroup>
        </GridUI>
    )
})

// PANE
type InvoiceParam = { month: string; operatorId: number }
const getPaneParams = (view: GridView<ActionData>, state: State): InvoiceParam | null => {
    const uid = view.focusId
    if (uid == null) return null
    const action = state.simu.Action.get(uid)
    const month = action.month
    const operatorId = action.operator.id
    return { month, operatorId }
}

const InvoicePaneUI = (p: InvoiceParam) => {
    const [value, setValue] = useState(0)
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }
    return (
        <div className="grow col">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Html" onClick={() => setValue(0)} />
                <Tab label="PDF" onClick={() => setValue(1)} />
            </Tabs>
            {value === 0 && <InvoicePaneUIHTML {...p} />}
            {value === 1 && <InvoicePaneUIPDF {...p} />}
        </div>
    )
}

const InvoicePaneUIHTML = observer((p: InvoiceParam) => {
    return (
        <div className="grow">
            <InvoiceUI2 {...p} />
        </div>
    )
})

const InvoicePaneUIPDF = observer((p: InvoiceParam) => {
    return <div>Temporarilly unavailable</div>
    // const st = useSt()
    // const pdf = st.pdfFor(JSON.stringify(p))
    // if (pdf.base64 == null) return null
    // return (
    //     <iframe
    //         onClick={(ev) => console.log(ev)}
    //         className="grow h100"
    //         title="frame"
    //         src={`data:application/pdf;base64,${pdf.base64}`}
    //         // width="300px"
    //         // height="700px"
    //     />
    // )
})

const actionListCols = (st: State, grid: GridState<any, any>): GridCol<Action>[] => [
    { key: 'month', name: 'Month', width: 100 },
    { key: 'operatorName', name: 'Operator', width: 150 },
    {
        key: 'price',
        name: 'Price',
        width: 120,
        groupFormatter: (a) => {
            const sum = a.childRows.reduce((sum, item) => sum + item.price, 0)
            return (
                <div onClick={() => (grid.focusId = a.childRows[0].id)}>
                    {formatMoney(sum)}
                </div>
            )
        },
    },
    // {
    //     key: 'missionId',
    //     name: 'Mission',
    //     width: 120,
    //     formatter: (m) => <Fragment>{m.row.mission.name}</Fragment>,
    // },
    {
        key: 'at',
        name: 'At',
        width: 120,
        formatter: (r) => <Fragment>{renderTime(r.row.at)}</Fragment>,
    },
    {
        key: 'activity',
        name: 'Activity',
        width: 120,
        // formatter: (m) => <Fragment>{m.row.kind.type}</Fragment>,
    },
    {
        key: 'drone',
        name: 'Drone',
        width: 120,
        formatter: (m) => <Fragment>{m.row.mission.drone.name}</Fragment>,
    },
]
