import type { GridCol, GridView } from '../grid/gridTypes'
import type { State } from '../state/state'
import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import { Action, ActionData, ActionSchema } from '../core/action'
import { formatMoney } from '../fakes/chunk'
import { GridUI } from '../grid/gridUI'
// import {
//     ProfitsByActivity,
//     ProfitsByMonth,
//     ProfitsByOperator,
// } from '../charts/profitsByKind'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { useSt } from '../state/context'

export type ProfitsPageParams = {
    className?: string
} & GridView<ActionData>

export const ProfitsPage = (): Page<ProfitsPageParams> => ({
    id: 'profits' as const,
    icon: 'Euro' as const,
    label: 'Profits',
    path: '/profits',
    Component: (p) => {
        return (
            <div className="grow col">
                <ProfitsPageUI {...p} />
            </div>
        )
    },
})

// const Pane = () => {
//     return (
//         <div className="col grow">
//             <ProfitsByActivity />
//             <ProfitsByOperator />
//             <ProfitsByMonth />
//         </div>
//     )
// }
export const ProfitsPageUI = observer((p: ProfitsPageParams) => {
    const st = useSt()

    return (
        <GridUI<Action, {}>
            key={p.at}
            rows={(st: State) => st.simu.Action.rows}
            cols={actionListCols}
            schema={ActionSchema}
            view={p}
            syncURL={(view, st) => st.goToprofitsPage({ ...p, ...view })}
            syncPane={() => null}
            PaneUI={() => null}
            // syncPane={getPaneParams}
            // PaneUI={Pane}
            title="Profits"
        >
            <Fragment>
                preset:
                <ButtonGroup
                    variant="outlined"
                    aria-label="profits group button"
                    size="small"
                >
                    <Button onClick={() => st.goToprofitsPage({ at: 'All' })}>All</Button>
                    <Button
                        onClick={() =>
                            st.goToprofitsPage({
                                at: 'by-operator',
                                groupBy: ['operatorName'],
                            })
                        }
                    >
                        By operator
                    </Button>
                    <Button
                        onClick={() =>
                            st.goToprofitsPage({
                                at: 'by-vertiport',
                                groupBy: ['vertiportName'],
                            })
                        }
                    >
                        By Vertiport
                    </Button>
                    <Button
                        onClick={() =>
                            st.goToprofitsPage({
                                at: 'by-service',
                                groupBy: ['activity'],
                            })
                        }
                    >
                        By Service
                    </Button>
                </ButtonGroup>
            </Fragment>
        </GridUI>
    )
})

// PANE
// const getPaneParams = () => ({})

const actionListCols = (): GridCol<Action>[] => [
    { key: 'month', name: 'Month', width: 120 },
    {
        key: 'price',
        name: 'Price',
        width: 120,
        editable: true,
        groupFormatter: (a) => {
            const sum = a.childRows.reduce((sum, item) => sum + item.price, 0)
            return <Fragment>{formatMoney(sum)}</Fragment>
        },
    },
    { key: 'operatorName', name: 'Operator', width: 120 },
    { key: 'atTime', name: 'At', width: 200 },
    {
        key: 'vertiportName',
        name: 'Vertiport',
        width: 120,
    },
    { key: 'activity', name: 'Service', width: 160 },
]
