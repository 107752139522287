import { getFieldset } from './formSchema'
import { EditableField } from './formType'
import { ChangeEvent, createContext, CSSProperties, ReactNode, useMemo } from 'react'
import { useContext } from 'react'
import { makeAutoObservable } from 'mobx'
import { ObjectSchema } from 'yup'

export const FormContext = createContext<FormState | null>(null)
export const useForm = () => {
    const form = useContext(FormContext)
    if (form == null) throw new Error('missing context')
    return form
}

export const useField = <T extends any>(key: string) => {
    const form = useForm()
    const field = useMemo(
        () => ({
            draft: form.values,
            getValue: (): T => form.values[key],
            setValue: (val: T) => (form.values[key] = val),
            getTextInputProps: () => ({
                value: form.values[key],
                onChange: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    (form.values[key] = ev.target.value),
            }),
            getFloatInputProps: () => ({
                value: form.values[key],
                onChange: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    (form.values[key] = parseFloat(ev.target.value)),
            }),
            getIntInputProps: () => ({
                value: form.values[key],
                onChange: (ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    (form.values[key] = parseInt(ev.target.value, 10)),
            }),
            // getErrors=() => form.errors[key]
        }),
        [form, key],
    )
    return field
}
//
export class FormState<Draft extends object = any> {
    values: Draft
    constructor(
        //
        public schema: ObjectSchema<any>,
        values: any,
    ) {
        this.values = values
        makeAutoObservable(this)
    }

    Form = (p: { style?: CSSProperties; className?: string; children: ReactNode }) => (
        <FormContext.Provider value={this}>
            <div style={p.style} className={p.className}>
                {p.children}
            </div>
        </FormContext.Provider>
    )

    get errors() {
        return this.schema.validateSync(this.values)
    }
    get fields(): EditableField[] {
        return getFieldset(this.schema)
    }
    get debug(): string {
        return JSON.stringify(this.values, null, 3)
    }
}
