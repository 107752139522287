import type { Page } from '../layout/pages'
import { observer } from 'mobx-react-lite'
import { useSt } from '../state/context'

export type AgentPageParams = {
    agentId: number
    embedded?: boolean
}

export const AgentPage = (): Page<AgentPageParams> => ({
    id: 'agent' as const,
    nav: false,
    icon: 'Agent' as const,
    path: '/agents/:agentId',
    label: 'Agents',
    Component: AgentPageUI,
})

export const AgentPageUI = observer((p: AgentPageParams) => {
    const st = useSt()
    const agent = st.simu.getAgent(p.agentId)

    return (
        <div>
            <h2>{agent?.name}</h2>
            <span className="propertyName">Phone</span> {agent?.phone}
        </div>
    )
})

// {/* {p.embedded ? (
//                 <div tw="flex items-center">
//                     <Button
//                         variant="outlined"
//                         onClick={(e) => {
//                             e.preventDefault()
//                             st.goToagentsListPage({})
//                         }}
//                     >
//                         {icons.Close}
//                     </Button>
//                     <OrientationToggle />
//                 </div>
//             ) : (
//                 <div tw="flex items-center">
//                     <Breadcrumbs aria-label="breadcrumb">
//                         <Link
//                             color="inherit"
//                             href="/drones"
//                             onClick={(e) => {
//                                 e.preventDefault()
//                                 st.goToagentsListPage({})
//                             }}
//                         >
//                             List agents
//                         </Link>
//                         <Typography color="textPrimary" tw="font-bold">
//                             {agent?.name}
//                         </Typography>
//                     </Breadcrumbs>
//                     <OrientationToggle />
//                 </div>
//             )} */}
