import type { Page } from '../layout/pages'

import { observer } from 'mobx-react-lite'

import { OperatorPageParams, OperatorPageUI } from './operatorPage'
import { Operator, OperatorSchema } from '../core/operator'
import { AddOperatorUI } from '../components/addOperatorUI'
import { GridCol, GridView } from '../grid/gridTypes'
import { GridUI } from '../grid/gridUI'
import { State } from '../state/state'

export type OperatorsListParams = {
    className?: string
} & GridView<Operator>

export const OperatorsListPage = (): Page<OperatorsListParams> => ({
    id: 'operatorsList' as const,
    icon: 'User' as const,
    path: '/operators',
    label: 'Operators',
    Component: OperatorsListPageUI,
})

const syncPane = (view: GridView<Operator>): OperatorPageParams | null =>
    view.focusId ? { operatorId: view.focusId } : null

export const OperatorsListPageUI = observer((p: OperatorsListParams) => {
    return (
        <div className="grow row">
            <GridUI<Operator, OperatorPageParams>
                schema={OperatorSchema}
                add={() => <AddOperatorUI />}
                rows={(st: State) => st.simu.Operator.rows}
                cols={operatorsListCols}
                view={p}
                syncURL={(view, st) => st.goTooperatorsListPage({ ...p, ...view })}
                syncPane={syncPane}
                PaneUI={OperatorPageUI}
                title="Operators"
            />
        </div>
    )
})

// https://www.sirene.fr/sirene/public/recherche
const operatorsListCols = (st: State): GridCol<Operator>[] => [
    { key: 'id', name: 'ID', width: 60 },
    { key: 'name', name: 'name', width: 200 },
    { key: 'tradeName', name: 'Company', width: 200 },
    { key: 'siret', name: 'Siret', width: 200 },
    { key: 'phone', name: 'Phone', width: 200 },
    { key: 'address', name: 'Address', width: 200 },
    { key: 'formation', name: 'Formation', width: 200 },
    // seeDroneCount(st),
    // {
    //     key: '',
    //     name: 'Invoices',
    //     formatter: () => (
    //         <Button
    //             onClick={(ev) => {
    //                 ev.stopPropagation()
    //                 ev.preventDefault()
    //                 st.pdf.build()
    //             }}
    //             variant="text"
    //         >
    //             see invoice
    //         </Button>
    //     ),
    // },
]

// const seeDroneCount = (st: State): GridCol<Operator> => ({
//     key: 'droneIds',
//     name: 'Drone count',
//     width: 200,
//     formatter: (v) => (
//         <Button variant="text" color="primary" onClick={() => st.goTodronesListPage({})}>
//             {v.row.droneIds.length}
//         </Button>
//     ),
// })

// {
//     key: 'fullName',
//     name: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params: GridValueGetterParams) =>
//         `${params.getValue(params.id, 'firstName') || ''} ${
//             params.getValue(params.id, 'lastName') || ''
//         }`,
// },

// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ]

// {/* <h2>
//     Fiche produit: #{p.id} - {p.name}
// </h2>
// <input type="text" value={st.pdfURL} />
// <div className="row">
//     <div className="col">
//         <input
//             type="number"
//             value={st.fontSize}
//             onChange={(ev) => {
//                 st.fontSize = parseInt(ev.target.value, 10)
//             }}
//         />

//         <div className="key">Name</div>
//         <div className="key">Photo</div>
//         <div className="key">Pros</div>
//         <div className="key">Cons</div>
//     </div>
//     <ZoneUI />
// </div> */}
