export const randomInt = (max: number = 10) => Math.floor(Math.random() * max)
export const randomDigit = () => Math.floor(Math.random() * 10)
export const randomPhone = (): string =>
    [
        `+33 ${Math.random() > 0.5 ? '6' : '7'}`,
        `${randomDigit()}${randomDigit()}`,
        `${randomDigit()}${randomDigit()}`,
        `${randomDigit()}${randomDigit()}`,
        `${randomDigit()}${randomDigit()}`,
    ].join(' ')
