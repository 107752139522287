import type { AddMissionPageParams } from '../pages/addMissionPage'

import type { MissionStep, MissionStepAction } from '../core/mission'
import type { Vertiport } from '../core/vertiport'
import type { Operator } from '../core/operator'
import type { Pilot } from '../core/pilot'

import type { Timestamp } from '../state/clock'
import type { State } from '../state/state'
import type * as L from 'leaflet'

import { makeAutoObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'

import { Drone, SPEED_AS_METER_PER_SECOND } from '../core/drone'
import { ActionKind, activityTemplate } from '../core/action'
import { defaultDuration } from '../core/action'
import { dijkstra } from '../carte/pathfinding'
import { mkTrace } from '../carte/trace'

export type DraftAction = {
    kind: ActionKind
    at: number
    durationMS: number
    queueTime: number
}

export type DraftMissionStep = {
    vertiport: Vertiport
    actions: DraftAction[]
    startAt: number
    endAt: number
    land: boolean
    takeoff: boolean
    thenFlyTime?: number
}

export type MissionType = 'surveillance' | 'logistic'
export type FirstActionType = 'landing' | 'takeoff'

// export type DroneStarts = 'parked'
// 'fly-to'
// 'take-off'
export class DraftState {
    constructor(public st: State, p: AddMissionPageParams) {
        const simu = st.simu
        makeAutoObservable(this)
        runInAction(() => {
            if (p.droneId) this.drone = simu.Drone.get(p.droneId)
            if (p.operatorId) this.operator = simu.Operator.get(p.operatorId)
            if (p.vertiportId) this.addStep(simu.Vertiport.get(p.vertiportId))
        })
    }

    name: string = `Mission ${nanoid()}`
    type: 'surveillance' | 'logistic' = 'logistic'

    drone: Drone | null = null
    operator: Operator | null = null
    pilot: Pilot | null = null

    startAt: Timestamp = Date.now()
    steps: DraftMissionStep[] = []
    firstAction: FirstActionType | null = 'landing'
    onFinish: 'park' | 'leave' = 'park'

    checkLabel = 'Check'
    nbChecks: number = 0
    isValid: boolean = false
    isValidating: boolean = false
    errors: DraftAction[] = []

    acceptWait = () => {
        for (let s of this.steps) {
            s.actions = s.actions.flatMap((a: DraftAction): DraftAction[] =>
                a.queueTime
                    ? [
                          {
                              kind: activityTemplate('queue'),
                              at: a.at,
                              durationMS: a.queueTime,
                              queueTime: 0,
                          },
                          { ...a, queueTime: 0 },
                      ]
                    : [a],
            )
        }
        this.updateTimes()
        this.check()
    }
    check = () => {
        this.isValid = false
        if (this.isValidating) return
        this.nbChecks++
        this.isValidating = true
        setTimeout(() => {
            const errs: DraftAction[] = []
            for (let s of this.steps) {
                for (let a of s.actions) {
                    if (a.queueTime) {
                        errs.push(a)
                    }
                }
            }
            this.checkLabel = 'Re-Check'
            this.isValidating = false
            this.isValid = errs.length === 0
            this.errors = errs
        }, 1000)
    }

    create = () => this.st.simu.addMission2(this)

    addService = (
        //
        step: DraftMissionStep,
        activity: ActionKind['type'],
        queueTime: number,
    ) => {
        const draftAction: DraftAction = {
            kind: activityTemplate(activity),
            at: 0,
            durationMS: defaultDuration(activity),
            queueTime,
        }
        step.actions.push(observable(draftAction))
        this.isValid = false
        this.updateTimes()
    }

    setFirstActionTo = (fa: FirstActionType) => {
        this.firstAction = fa
        this.isValid = false
        this.updateTimes()
    }

    setStartAt = (newStartAt: number) => {
        this.startAt = newStartAt
        this.isValid = false
        this.updateTimes()
    }

    private _mkDraftService = (
        //
        activity: ActionKind['type'],
        durationMS?: number,
    ): DraftAction => ({
        kind: activityTemplate(activity),
        at: 0,
        durationMS: durationMS ?? defaultDuration(activity),
        queueTime: 0,
    })
    addStep = (vertiport: Vertiport) => {
        const addSubStep = (v: Vertiport) => {
            const shouldAddBattery = this.steps.length % 2
            this.steps.push({
                vertiport: v,
                actions: shouldAddBattery
                    ? [this._mkDraftService('rechargeBattery')]
                    : [],
                // 4 properties below will be recomputed right after
                endAt: 0,
                startAt: 0,
                land: false,
                takeoff: false,
            })
        }

        this.isValid = false
        const prevVertiport =
            this.steps.length > 0 //
                ? this.steps[this.steps.length - 1].vertiport
                : null
        if (prevVertiport) {
            for (let v of dijkstra(
                this.st.simu.Vertiport.rows,
                prevVertiport,
                vertiport,
            )) {
                if (v === prevVertiport) continue
                addSubStep(v)
            }
        } else {
            addSubStep(vertiport)
        }
        this.updateTimes()
    }

    get positions(): L.LatLngExpression[] {
        if (this.steps.length < 2) return []
        const positions: L.LatLngExpression[] = this.steps.map((s) => [
            s.vertiport.lat,
            s.vertiport.lon,
        ])
        return positions
    }

    get temporaryTrace() {
        if (this.steps.length < 2) return null
        const positions: any[] = this.steps.map(
            (s) => [s.vertiport.lon, s.vertiport.lat] as const,
        )
        const trace = mkTrace(positions)
        console.log('trace:', trace)
        return trace
    }

    updateTimes = () => {
        let x = this.startAt

        // initial offset if we count from first takeoff
        const step0 = this.steps[0]
        if (step0 && this.firstAction === 'takeoff') {
            const stepDuration = step0.actions.reduce((p, c) => p + c.durationMS, 0)
            x = x - stepDuration
        }
        // const last = this.steps[this.steps.length - 1]

        // let isFirst = true
        const len = this.steps.length
        for (let i = 0; i < len; i++) {
            const s = this.steps[i]

            // 0. FLIGHT
            const ns = i >= 1 ? this.steps[i - 1] : undefined
            if (ns) {
                const meters = s.vertiport.distanceTo(ns.vertiport)
                const seconds = meters / SPEED_AS_METER_PER_SECOND
                x += seconds * 1000
                s.thenFlyTime = seconds * 1000
                // x += 30 * 60_000
            }

            s.startAt = x

            // A. LAND
            // if (s === step0) {
            //     s.land = this.firstAction === 'landing'
            // } else
            if (i === 0) {
                // TODO
                s.land = false
            } else if (s.actions.length === 0) {
                s.land = false
            } else {
                s.land = true
            }
            if (s.land) x += defaultDuration('land')

            // B. SERVICES
            for (let a of s.actions) {
                a.at = x //renderHour(x)
                x += a.durationMS
            }

            // C. TAKEOFF
            // if (s === step0 && this.firstAction === 'takeoff') {
            if (i === 0) {
                s.takeoff = true
            } else if (s.land === false) {
                // case 1: did not land => no need to takeoff
                s.takeoff = false
            } else if (i === len - 1) {
                // case 2: last mission
                if (this.onFinish === 'park') {
                    s.takeoff = false
                } else {
                    s.takeoff = true
                }
            } else {
                s.takeoff = true
            }
            if (s.takeoff) x += defaultDuration('takeoff')
            s.endAt = x
        }
    }

    /** to properly add landing and takeoff actions */
    get fakeMission(): { steps: FakeStep[] } {
        const status: 'scheduled' = 'scheduled'
        const steps: FakeStep[] = []
        const len = this.steps.length
        for (let i = 0; i < len; i++) {
            const s = this.steps[i]
            const vertiportId = s.vertiport.id
            let actions: MissionStepAction[] = s.actions.map((da: DraftAction) => ({
                kind: da.kind,
                at: da.at,
                durationMS: da.durationMS,
                vertiportId,
                status,
            }))
            let step: FakeStep = {
                vertiport: s.vertiport,
                actions,
                draftStep: s,
            }
            if (s.land)
                actions.unshift({
                    kind: activityTemplate('land'),
                    at: s.startAt,
                    status,
                    durationMS: defaultDuration('land'),
                    vertiportId,
                })
            if (s.takeoff) {
                actions.push({
                    kind: activityTemplate('takeoff'),
                    at: s.endAt - defaultDuration('takeoff'), // TODO: fix that
                    status,
                    durationMS: defaultDuration('takeoff'),
                    vertiportId,
                })
            }
            if (s.thenFlyTime) {
                actions.unshift({
                    kind: activityTemplate('fly'),
                    at: s.startAt - s.thenFlyTime,
                    durationMS: s.thenFlyTime,
                    vertiportId,
                    status,
                })
            }
            if (!s.land && !s.takeoff)
                actions.push({
                    kind: activityTemplate('flyBy'),
                    at: s.startAt,
                    status,
                    durationMS: defaultDuration('flyBy'),
                    vertiportId,
                })
            steps.push(step)
        }
        return { steps }
    }
}

export type FakeStep = MissionStep & { draftStep: DraftMissionStep }
