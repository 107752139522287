import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import { Paper } from '@material-ui/core'

import { dropParams } from './dropZone'
import { drawerWidth } from './utils'
import { AppNavUI } from './menuUI'
import { TopBar } from './appBar'

import LogoSource from '../assets/logo.png'
import { useSt } from '../state/context'
import { themeMgr } from '../theme'

export const AppShell = observer((p: { children: ReactNode }) => {
    const st = useSt()
    const isDark = themeMgr.isDark()
    const themeCLASSNAME = isDark ? 'THEME-DARK' : 'THEME-LIGHT'
    const background = isDark ? '#1f1f1f' : '#F9F9F9'
    const className = `col grow ${themeCLASSNAME}`
    return (
        <div
            ref={(elem) => {
                if (elem == null) return
                elem.focus()
            }}
            tabIndex={-1}
            className={className}
            {...dropParams(st)}
            onKeyDown={(ev) => {
                const key = ev.key
                const ctrl = ev.ctrlKey
                const shift = ev.shiftKey
                const cmd = ev.metaKey
                const mod = cmd || ctrl
                let stop = false
                if (key === 'U' && mod && shift) {
                    st.simu.simulationToogle()
                    stop = true
                } else if (key === 'S' && mod && shift) {
                    console.log('save to file')
                    st.saveSimulationDataAsFile()
                    stop = true
                } else if (key === 's' && mod) {
                    console.log('save to localstorage')
                    st.saveSimulationData()
                    stop = true
                }
                if (stop) {
                    ev.stopPropagation()
                    ev.preventDefault()
                }
            }}
        >
            <Paper className="col grow" elevation={0}>
                <TopBar />
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            background,
                            // boxShadow: '1px 0px 0px #EFEFEF',
                            borderRight: 'none',
                        },
                        '& .MuiButtonBase-root': {
                            paddingTop: '4px', // TODO @dorian: no px => rem only
                            paddingBottom: '4px',
                        },
                        '& .MuiListItemIcon-root': {
                            minWidth: '40px',
                        },
                        '& .MuiTypography-root': {
                            fontSize: '14px',
                        },
                    }}
                    open={st.menuOpen}
                    onClose={() => (st.menuOpen = false)}
                    variant={st.windowWidth <= 768 ? 'temporary' : 'permanent'}
                    anchor="left"
                >
                    <Toolbar sx={{ background }}>
                        <img
                            src={LogoSource}
                            alt="logo"
                            onClick={() => st.goTohomePage({})}
                            tw="cursor-pointer max-width[6rem] mx-auto"
                        />
                    </Toolbar>
                    {/* <Divider light /> */}
                    <AppNavUI />
                </Drawer>
                <div
                    className="col grow"
                    style={{
                        marginLeft: st.windowWidth <= 768 ? '0' : `${drawerWidth}px`,
                    }}
                >
                    <Toolbar />
                    {p.children}
                </div>
            </Paper>
        </div>
    )
})
