import logoWithText from './logo.png'
import logoOnly from './logo-only.png'
import logoOnlyBlack from './logo-only-black.png'
import logoSVG2 from './logo-2.svg'

export const logo = {
    withText: logoWithText,
    colored: logoOnly,
    black: logoOnlyBlack,
    svg: logoSVG2,
}

export const colorLogoL_RGB = [58, 134, 189]
export const colorLogoL = '#3a86bd'

export const colorLogoR_RGB = [85, 168, 97]
export const colorLogoR = '#55a861'
