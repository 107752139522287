import type { CSSProperties } from 'react'

import { observer } from 'mobx-react-lite'

import Autocomplete from '@material-ui/core/Autocomplete'
import TextField from '@material-ui/core/TextField'

import type { RID } from '../../core/schema'
import { cls } from '../../utils/cls'

export const AC = observer(function AC_<A extends { id: RID }>(p: {
    //
    getName: (a: A) => string
    label?: string
    rows: A[]
    getVal: () => A | null
    setVal: (a: RID) => void
    remove?: () => void
    className?: string
    style?: CSSProperties
    variant?: 'filled' | 'outlined' | 'standard' | undefined
}) {
    const val = p.getVal()
    return (
        <Autocomplete
            className={cls(p.className, 'ac-picker')}
            style={p.style}
            options={p.rows}
            getOptionLabel={(option) => `${p.getName(option)}`}
            value={val}
            size="small"
            onChange={(e, item: A | null) => {
                if (item == null) {
                    if (p.remove) p.remove()
                } else {
                    p.setVal(item.id)
                }
            }}
            renderInput={(params) => (
                <TextField
                    variant={p.variant ?? 'standard'}
                    {...params}
                    label={p.label}
                    // fullWidth
                />
            )}
        />
    )
})

/* <ToggleButtonGroup
    value={x.firstAction}
    exclusive
    onChange={(
        _: React.MouseEvent<HTMLElement>,
        newAlignment: FA,
    ) => {
        x.firstAction = newAlignment
    }}
    aria-label="text alignment"
>
    <ToggleButton value="left" aria-label="landing">
        {icons.Landing}
    </ToggleButton>
    <ToggleButton value="center" aria-label="take-off">
        {icons.TakeOff}
    </ToggleButton>
</ToggleButtonGroup> */

/* {x.firstAction && <input type="time" value="13:37" />} */
