import type { Simulation } from '../state/simulation'
import type { Asserts } from 'yup'
import type { LatLngTuple } from 'leaflet'
import type { Action } from './action'
import type { Area } from './area'
import type { Corridor } from './corridor'
import type { LngLatTuple } from './point'
import type { RID } from './schema'

import { action, computed, makeObservable } from 'mobx'

import { VertiportStatus } from '../components/vertiportStatus'
import { decode, encode, shorten } from '../plus-codes'
import { turf } from '../state/turf'
import { Model, s } from './schema'

/** contraption from 'vertical' & 'aeroport'
 * main base for drones to return to to sleep or get some love.
 */
export const VertiportSchema = s.object({
    id: s._ID,
    name: s.str,
    lat: s.num,
    lon: s.num,
    areaId: s._ID,
    agentIds: s._IDS,
    corridorIds: s._IDS,
    deleted: s.bool,
    nbLandingBay: s.num,
    nbChargingBay: s.num,
    nbParkingBay: s.num,
    nbRepairBay: s.num,
    fake: s.bool,
})

export type VertiportData = Asserts<typeof VertiportSchema>

export interface Vertiport extends VertiportData {}
export class Vertiport extends Model<typeof VertiportSchema> {
    constructor(simulation: Simulation, data: VertiportData) {
        super(simulation, VertiportSchema, data, 'Vertiport')
        makeObservable(this, {
            ...this.observability,
            // main computeds
            activity: computed,
            area: computed,
            corridors: computed,
            city: computed,
            pos: computed,
            lonLatTuple: computed,
            connectionIds: computed,
            connections: computed,
            // action
            updatePos: action,
            delete: action,
            connectTo: action,
            // disconnect: action,
            updatePlusCode: action,
        })
    }

    updatePlusCode = (newPlusCode: string) => {
        const pos = decode(newPlusCode)
        if (pos == null) return
        this.lat = pos.latitude
        this.lon = pos.longitude
    }

    connectTo = (to: Vertiport) => {
        // TODO: abort if pre-existing corridor already exists
        this.simulation.addCorridor(this, to)
    }

    delete = () => {
        if (this.deleted) {
            // undelete
            this.deleted = false
            return
        } else {
            // mark deletet + delete cascade
            this.deleted = true
            for (let c of this.corridors) c.Delete()
            if (this.corridorIds.length > 0) throw new Error('IMPOSSIBLE e3801')
        }
    }

    /** return distance in meters to other given vertiport */
    distanceTo = (to: Vertiport): number => {
        // TODO: this must not be used anymore
        // => use corridor.distance isntaed
        console.warn(`use corridor.distance instead`)
        return turf.distance(
            turf.point([this.lon, this.lat]),
            turf.point([to.lon, to.lat]),
            { units: 'meters' },
        )
    }
    private _status: VertiportStatus | undefined
    get status() {
        if (this._status) return this._status
        this._status = new VertiportStatus(this, this.fake ? 30 : 0)
        return this._status
    }
    //
    // https://runkit.com/janne/5bcba8213b24aa0012bc615b
    get pluscode(): string {
        const code = encode({
            latitude: this.lat,
            longitude: this.lon,
        })
        if (code == null) throw new Error('missing plus code')
        return code
    }
    get pluscodeShort() {
        return (
            shorten(this.pluscode, {
                latitude: this.area.lat,
                longitude: this.area.lon,
            }) || this.pluscode
        )
    }

    get pos(): LatLngTuple {
        return [this.lat, this.lon]
    }
    get dailyActivity(): Action[] {
        const dayStart = this.simulation.clock.dayStart
        const dayEnd = this.simulation.clock.dayEnd
        return this.simulation.Action.rows.filter(
            (a) =>
                a.at > dayStart && //
                a.at < dayEnd &&
                a.vertiportId === this.id,
        )
    }

    updatePos = (longitude: number, latitude: number) => {
        // update pos
        this.lon = longitude
        this.lat = latitude
        // update name
        const pluscodeFull = encode({ latitude, longitude })!
        const area = this.area
        const pluscodeShort = shorten(pluscodeFull, { latitude, longitude })
        this.name = `${area.name} ${pluscodeShort}`
    }

    get lonLatTuple(): LngLatTuple { return [this.lon, this.lat] } // prettier-ignore
    get city(): string { return this.area.name } // prettier-ignore
    get area(): Area { return this.simulation.Area.get(this.areaId) } // prettier-ignore
    get corridors(): Corridor[] { return this.simulation.Corridor.getMany(this.corridorIds) } // prettier-ignore
    get activity(): Action[] {
        return this.simulation.Action.rows.filter((a) => a.vertiportId === this.id)
    }

    get connectionIds(): RID[] {
        return this.corridors.map((c) =>
            this.id === c.vertiportId1 //
                ? c.vertiportId2
                : c.vertiportId1,
        )
    }
    get connections(): Vertiport[] {
        return this.simulation.Vertiport.getMany(this.connectionIds)
    }
    // get plusCodeDebug() {
    //     return [
    //         `Paris ref: ${shorten(this.pluscode, {
    //             latitude: Paris.lat,
    //             longitude: Paris.lon,
    //         })}`,
    //         `Pontoise Ref: ${shorten(this.pluscode, {
    //             latitude: Pontoise.lat,
    //             longitude: Pontoise.lon,
    //         })}`,
    //         `Rungis Ref: ${shorten(this.pluscode, {
    //             latitude: Rungis.lat,
    //             longitude: Rungis.lon,
    //         })}`,
    //     ]
    // }
}
