import { observer } from 'mobx-react-lite'
import DataGrid from 'react-data-grid'
import type { Page } from '../layout/pages'
import { cls } from '../utils/cls'
import { themeMgr } from '../theme'
import { useSt } from '../state/context'

export const LogsPage = (): Page => ({
    id: 'logs' as const,
    icon: 'Logs' as const,
    label: 'Logs',
    path: '/logs',
    Component: LogsPageUI,
})

export const LogsPageUI = observer(() => {
    const st = useSt()
    const rows = st.simu.Action.rows.filter((r) => r.at < st.simu.clock.now)
    return (
        <DataGrid
            className={cls('grow', themeMgr.isDark() ? 'rdg-dark' : 'rdg-light')}
            rows={rows}
            columns={[{ key: 'asLog', name: 'Log' }]}
        />
    )
})
