import { observer } from 'mobx-react-lite'
import { Fragment, useMemo, useState } from 'react'
import { runInAction } from 'mobx'

import * as RL from 'react-leaflet'
import * as L from 'leaflet'

import { vertiPins } from '../assets/pin-vertiport'
import { Corridor, CorridorGeoProps } from '../core/corridor'
import { CarteProps } from './carteUI'
import { useSt } from '../state/context'

export const CorridorsUI = observer(function CorridorsUI_(p: {
    corridorColor: CarteProps['corridorColor']
    corridorClick: CarteProps['corridorClick']
}) {
    console.log('[CorridorsUI]')
    const [zoom, setZoom] = useState(() => 12)
    RL.useMapEvent('zoom', (q) => setZoom(q.target._zoom))
    const st = useSt()
    const simu = st.simu
    const asLine = zoom <= 16
    const eventHandlers: L.LeafletEventHandlerFnMap = useMemo(
        () => ({
            click: (ev: L.LeafletMouseEvent) => {
                if (p.corridorClick == null) return
                L.DomEvent.stop(ev)
                const props: CorridorGeoProps = ev.sourceTarget.feature.properties
                const corridor = simu.Corridor.get(props.id)
                p.corridorClick(corridor, ev)
            },
        }),
        // eslint-disable-next-line
        [],
    )

    return (
        <RL.Pane
            name="air corridors"
            style={{ zIndex: 300 }}
            key={asLine ? 'as-line' : 'as-poly'}
        >
            {simu.Corridor.rows.map((c) => (
                <CorridorUI
                    asLine={asLine}
                    handlers={eventHandlers}
                    corridor={c}
                    corridorColor={p.corridorColor}
                />
            ))}
        </RL.Pane>
    )
})

export const CorridorUI = observer(function CorridorUI_(p: {
    corridor: Corridor
    handlers: L.LeafletEventHandlerFnMap
    corridorColor: CarteProps['corridorColor']
    asLine?: boolean
}) {
    // console.log('[CorridorUI]')
    const c = p.corridor
    const geo = p.asLine //
        ? c.asGeoLine
        : c.asGeoPoly
    const color = p.corridorColor?.(c) ?? '#6db6fd'
    const style = p.asLine //
        ? { weight: 15, color, opacity: 0.6 }
        : { dashArray: '9', color }
    const pp = geo.properties
    return (
        <Fragment
            key={`${pp.id}-${pp.updatedAt}`}
            // key={`${p.corridor.id}`}
        >
            <RL.GeoJSON
                eventHandlers={p.handlers}
                interactive={true}
                style={style}
                data={geo}
            />
            <WaypointUI corridor={p.corridor} />
        </Fragment>
    )
})

export const WaypointUI = observer(function WaypointUI_(p: { corridor: Corridor }) {
    const c = p.corridor
    // console.log('[WaypointUI]')
    return (
        <Fragment>
            {c.waypointsLats.map((lat, ix) => {
                const handlers: L.LeafletEventHandlerFnMap = {
                    dragend(ev: L.DragEndEvent) {
                        const x: any = ev.target._latlng
                        const lat = x.lat
                        const lon = x.lng
                        runInAction(() => {
                            c.waypointsLons[ix] = lon
                            c.waypointsLats[ix] = lat
                            c.udpateDistanceAndDuration()
                        })
                    },
                }
                return (
                    <RL.Marker
                        key={ix}
                        draggable
                        eventHandlers={handlers}
                        icon={vertiPins.waypoint}
                        position={[lat, c.waypointsLons[ix]]}
                    />
                )
            })}
        </Fragment>
    )
})
