type AcceptableClassNames = string | { [key: string]: boolean } | undefined | null
export const cls = (...args: AcceptableClassNames[]): string => {
    const out: string[] = []
    args.forEach((a) => {
        if (a == null) return
        if (typeof a === 'string' && a.length > 0) out.push(a)
        else if (typeof a === 'object') {
            for (let [k, v] of Object.entries(a)) if (v) out.push(k)
        }
    })
    return out.join(' ')
}
