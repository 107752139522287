import { makeAutoObservable } from 'mobx'
import { choose } from '../fakes/utils'
import { renderFullMonth, timestampRangeToFullMonths } from '../utils/fullMonth'

export type Timestamp = number
export class Clock {
    get summary(): string {
        const d = new Date(this.now)
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
    }
    // DURATIONS
    dMs = 1
    dSec = 1000 // ms
    dMin = this.dSec * 60
    dHour = this.dMin * 60
    dDay = this.dHour * 24
    dMonth = this.dDay * 30

    get msSinceBeginningOfDay(): number {
        return this.now - beginningOfDay(this.now)
    }

    // TIMESTAMPS (source of truth)
    // loadTime = Date.now()
    timeMin!: Timestamp // = this.loadTime - 4 * this.dMonth
    timeMax!: Timestamp //  = this.loadTime + 10 * this.dDay
    now!: Timestamp // = this.loadTime

    centerTimeAround = (loadTime: Timestamp) => {
        this.timeMin = beginningOfMonth(loadTime - 4 * this.dMonth)
        this.timeMax = loadTime + 10 * this.dDay
        this.now = loadTime
    }

    constructor(loadTime = Date.now()) {
        this.centerTimeAround(loadTime)
        console.log('timeMin', new Date(this.timeMin).toDateString())
        console.log('timeMax', new Date(this.timeMax).toDateString())
        makeAutoObservable(this)
    }

    /** list all month where we may have some activity
     * ['2021-03', '2021-04', '2021-05', '2021-06']
     */
    get possibleMonths(): string[] {
        return timestampRangeToFullMonths(this.timeMin, this.timeMax + 2 * this.dMonth)
    }

    /** timestamp */
    get fullMonth() {
        return renderFullMonth(this.now)
    }

    // prettier-ignore
    private distribution:number[]=[
        0,
        .2, .2,
        .5, .5, .5,
        .7, .7, .7, .7, .7, .7,
        .9, .9
    ]

    chooseMissionTime = (): Timestamp => {
        const offset = choose(this.distribution)
        const timeRange = this.timeMax - this.timeMin
        const base = offset * timeRange
        const rnd = (1 - offset) * timeRange * Math.random()
        const out = Math.floor(this.timeMin + base + rnd)
        // if (out)<
        return out
    }

    get jsDate(): Date { return new Date(this.now) } // prettier-ignore
    set jsDate(date: Date) { this.now = date.getTime() } // prettier-ignore

    get dayStart(): number {
        const dStr = this.overwrite({ hour: 0, minute: 0 })
        const d = new Date(dStr)
        return d.getTime()
    }
    get dayEnd(): number {
        // TODO: use a real date libray to avoid those slightly buggy stuff
        const dStr = this.overwrite({ hour: 23, minute: 59 })
        const d = new Date(dStr)
        return d.getTime()
    }
    // get beginningOfMonth(): number {
    //     // TODO: use a real date libray to avoid those slightly buggy stuff
    //     const dStr = this.overwrite({ hour: 23, minute: 59 })
    //     const d = new Date(dStr)
    //     return d.getTime()
    // }

    overwrite = (t: DateOverwrite): string => overwriteTime(this.jsDate, t)
    setIsoTime(val: string) {
        const [hour, minute] = val.split(':').map((t) => parseInt(t, 10))
        const dStr = this.overwrite({ hour, minute })
        const d = new Date(dStr)
        this.now = d.getTime()
    }

    get isoTime(): string {
        const d = this.jsDate
        const hour = d.getHours().toString().padStart(2, '0')
        const minute = d.getMinutes().toString().padStart(2, '0')
        return `${hour}:${minute}`
    }

    setIsoDate(val: string) {
        const [year, month, day] = val.split('-').map((t) => parseInt(t, 10))
        const dStr = this.overwrite({ year, month, day })
        const d = new Date(dStr)
        this.now = d.getTime()
    }

    get isoDate(): string {
        const d = this.jsDate
        const year = d.getFullYear()
        const month = (1 + d.getMonth()).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        return year + '-' + month + '-' + day
    }
}

// export const __x = {}

type DateOverwrite = {
    year?: number
    month?: number
    day?: number
    hour?: number
    minute?: number
}

export const overwriteTime = (d: Date, t: DateOverwrite): string => {
    const year = t.year ?? d.getFullYear()
    const month = t.month ?? (1 + d.getMonth()).toString().padStart(2, '0')
    const day = t.day ?? d.getDate().toString().padStart(2, '0')
    const hour = t.hour ?? d.getHours().toString().padStart(2, '0')
    const minute = t.minute ?? d.getMinutes().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hour}:${minute}`
}

export const renderDatetimeISO = (d2: Timestamp | Date): string => {
    const d = d2 instanceof Date ? d2 : new Date(d2)
    const year = d.getFullYear()
    const month = (1 + d.getMonth()).toString().padStart(2, '0')
    const day = d.getDate().toString().padStart(2, '0')
    const hour = d.getHours().toString().padStart(2, '0')
    const minute = d.getMinutes().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hour}:${minute}`
}

export const updateTz = (d2: Timestamp | Date, t: DateOverwrite): Timestamp => {
    const d = d2 instanceof Date ? d2 : new Date(d2)
    const year = t.year ?? d.getFullYear()
    const month = t.month ?? (1 + d.getMonth()).toString().padStart(2, '0')
    const day = t.day ?? d.getDate().toString().padStart(2, '0')
    const hour = t.hour ?? d.getHours().toString().padStart(2, '0')
    const minute = t.minute ?? d.getMinutes().toString().padStart(2, '0')
    const iso = `${year}-${month}-${day} ${hour}:${minute}`
    return new Date(iso).getTime()
}

export const beginningOfMonth = (d2: Timestamp | Date): Timestamp => {
    const d = d2 instanceof Date ? d2 : new Date(d2)
    const year = d.getFullYear()
    const month = (1 + d.getMonth()).toString().padStart(2, '0')
    const dateStr = `${year}-${month}`
    // console.log({ dateStr })
    return new Date(dateStr).getTime()
}

export const beginningOfDay = (from: Timestamp | Date = Date.now()): Timestamp => {
    const date = from instanceof Date ? from : new Date(from)
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const dateStr = `${year}-${month}-${day} 00:00`
    return new Date(dateStr).getTime()
}

export const sameTimeTodayV1 = (from: Timestamp | Date): Timestamp => {
    // day part
    const now = new Date()
    const year = now.getFullYear()
    const month = (1 + now.getMonth()).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    // time part
    const date = from instanceof Date ? from : new Date(from)
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    // result
    return new Date(`${year}-${month}-${day} ${hour}:${minute}`).getTime()
}
