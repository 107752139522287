import { Router, Switch, Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'

import { LoginPageUI } from './pages/loginPage'
import { PopupUI } from './components/popups'
import { AppShell } from './layout/appShell'
import { pages } from './layout/pages'
import { useSt } from './state/context'
import { themeMgr } from './theme'

export const App = observer(() => {
    const st = useSt()
    if (!st.auth.isAuthenticated) return <LoginPageUI />
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router history={st.history}>
            <themeMgr.Provider>
                <PopupUI />
                <AppShell>
                    <Switch>
                        {pages.map((p) => (
                            <Route path={p.path} key={p.id} exact>
                                {(route) => {
                                    // 1.
                                    // const qp = qs.parse(route.location.search.slice(1))

                                    // 2.
                                    const pStr = decodeURI(route.location.search.slice(1))
                                    // console.log({ pStr },decodeURI(pStr))
                                    const qp = pStr ? JSON.parse(pStr) : {}

                                    // 3.
                                    // const qp = jsurl.
                                    return (
                                        <p.Component {...route.match?.params} {...qp} />
                                    )
                                }}
                            </Route>
                        ))}
                    </Switch>
                </AppShell>
            </themeMgr.Provider>
        </Router>
        </LocalizationProvider>

    )
})
