import { observer } from 'mobx-react-lite'

import type { Page } from '../layout/pages'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Stack from '@material-ui/core/Stack'

import { BatteryIndicator } from '../components/batteryIndicator'
import { AddMissionBtnUI } from '../components/addMissionBtn'
import { icons } from '../components/iconUI'
import { ModelFormUI } from '../forms/formUI'
import { useSt } from '../state/context'
import { RID } from '../core/schema'
import { OperatorSummaryUI } from './operatorPage'
import { TagUI } from '../components/tagUI'
import { missionStatusHue, missionTypeHue } from '../core/mission'

export type DronesPageParams = {
    droneId: string | number
}

export const DronesPage = (): Page<DronesPageParams> => ({
    id: 'drone' as const,
    icon: 'Drone' as const,
    nav: false,
    path: '/drone/:droneId',
    label: 'Drone',
    Component: DronesPageUI,
})

export const DroneSummaryUI = observer(function DroneSummaryUI_(p: {
    //
    droneId: RID
    title?: boolean
}) {
    const droneId = typeof p.droneId === 'string' ? parseInt(p.droneId, 10) : p.droneId
    const st = useSt()
    const drone = st.simu.Drone.get(droneId)
    const editBtn = (
        <IconButton
            color="inherit"
            size="small"
            onClick={() =>
                st.addPopup('edit drone', (p) => (
                    <ModelFormUI model={drone} onClose={p.close} />
                ))
            }
        >
            {icons.Edit}
        </IconButton>
    )
    return (
        <div>
            {/* TITLE */}
            {p.title ? (
                <h2 className="line2">
                    {icons.Drone} {drone.name} {editBtn}
                </h2>
            ) : (
                <h3
                    className="line2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => st.goTodronesListPage({ focusId: drone.id })}
                >
                    {icons.Drone} Drone: {drone.name}
                </h3>
            )}
            {/* PROPERTIES */}
            <div className="propertyGroup">
                <div tw="flex">
                    <Tooltip disableFocusListener title="Brand" arrow>
                        <p tw="mr-1">{drone.brand}</p>
                    </Tooltip>
                    -
                    <Tooltip disableFocusListener title="Model" arrow>
                        <p tw="mx-1">{drone.model}</p>
                    </Tooltip>
                </div>
                <div className="row">
                    <Tooltip disableFocusListener title="Class" arrow>
                        <p tw="mr-1">{drone.class}</p>
                    </Tooltip>
                    -
                    <Tooltip disableFocusListener title="Scenario" arrow>
                        <p tw="ml-1">{drone.scenario}</p>
                    </Tooltip>
                </div>
                <BatteryIndicator drone={drone} minRemaining />
                <div className="line2">
                    {icons.BatteryHealth}
                    <Typography variant="body1" tw="ml-2">
                        {drone.batteryHealth}%
                    </Typography>
                    <div tw="mx-2">-</div>
                    <Typography variant="body1">
                        <span tw="font-size[.8rem]">
                            {drone.hoursBeforeRevision}h before revision
                        </span>
                    </Typography>
                </div>
                <div className="line2">
                    <span>{icons.Flight}</span>
                    <Typography variant="body1" tw="ml-2">
                        Not flying
                    </Typography>
                </div>
            </div>
        </div>
    )
})

export const DronesPageUI = observer((p: DronesPageParams) => {
    const st = useSt()
    const droneId = typeof p.droneId === 'string' ? parseInt(p.droneId, 10) : p.droneId
    const drone = st.simu.Drone.get(droneId)
    // const pilot = st.simu.Pilot.get(drone?.pilotId)
    return (
        <div>
            <DroneSummaryUI title droneId={droneId} />
            <OperatorSummaryUI operator={drone.operator} />

            {/* MISSIONS */}
            {drone.missions.length > 0 && (
                <Paper elevation={0} tw="py-4">
                    <Typography variant="h6" tw="mb-2">
                        Missions <AddMissionBtnUI size="small" />
                    </Typography>
                    <Stack spacing={1} tw="mt-2">
                        {drone?.missions.map((mission, index) => (
                            <div
                                key={mission.id}
                                className="stack-line"
                                onClick={() =>
                                    st.goTomissionPage({
                                        missionId: mission.id,
                                    })
                                }
                            >
                                <div className="row grow">
                                    <Typography
                                        variant="subtitle1"
                                        tw="font-weight[500] line-height[normal]"
                                    >
                                        {mission.name}
                                    </Typography>
                                    <span
                                        className="line2"
                                        style={{ marginLeft: '1rem' }}
                                    >
                                        {/*  */}
                                        <TagUI tags={mission.type} hue={missionTypeHue} />
                                        <TagUI
                                            tags={mission.status}
                                            hue={missionStatusHue}
                                        />
                                    </span>
                                </div>
                            </div>
                        ))}
                    </Stack>
                </Paper>
            )}
        </div>
    )
})

// export const DronesPageUI = observer((p: DronesPageParams) => {
//     const st = useSt()
//     if (st.spaceOnScreen)
//         return (
//             <div className="row splitContainer grow">
//                 <DronesListPageUI />
//                 <DronesPageFull {...p} embedded />
//             </div>
//         )

//     return <DronesPageFull {...p} />
// })
